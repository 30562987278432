//
// type.scss
// Extended from Bootstrap
//

//
// Font sizing
//

@include media-breakpoint-down(lg) {
  [class].fs-lg {
    font-size: $font-size-lg-md !important;
  }
}
