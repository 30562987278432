// 
// user.scss
// Use this to write your custom SCSS
//

.feature-card {
    border: 3px solid transparent;
}

.feature-card:hover {
    border: 2px solid $gray-300;
    text-decoration: none;
}

.avatar2 {
    display: inline-block;
    font-size: .625rem;
    height: 8rem;
    position: relative;
    width: 8rem;
}
