//
// screenshot.scss
// Theme component
//

.screenshot {
  --#{$prefix}screenshot-border-radius: #{$screenshot-border-radius};
  --#{$prefix}screenshot-box-shadow: #{$screenshot-box-shadow};
  border-radius: var(--#{$prefix}screenshot-border-radius);
  box-shadow: var(--#{$prefix}screenshot-box-shadow);
}