//
// badge.scss
// Extended from Bootstrap
//

// Links

.badge[href] {

  &:hover {
    text-decoration: none;
  }
}


// Sizing

.badge-lg {
  --#{$prefix}badge-padding-y: #{$badge-padding-y-lg};
  --#{$prefix}badge-padding-x: #{$badge-padding-x-lg};
}


// Rounded circle

.badge-rounded-circle {
  height: calc(1em + #{var(--#{$prefix}badge-padding-y)} * 2);
  padding-left: var(--#{$prefix}badge-padding-y);
  padding-right: var(--#{$prefix}badge-padding-y);
  border-radius: var(--#{$prefix}border-radius-pill);

  > * {
    display: block;
    width: 1em;
  }
}


// Positioning

.badge-float {
  position: absolute;
  z-index: $zindex-dropdown;
}

.badge-float-inside {
  top: 1rem;
  right: 1rem;
}

.badge-float-outside {
  top: -.5rem;
  right: -.5rem;
}


// Subtle badge variants

@each $color, $value in $theme-colors-bg-subtle {
  .badge.text-bg-#{$color}-subtle {
    
    &[href]:hover,
    &[href]:focus {
      --#{$prefix}bg-opacity: .2;
    }
  }
}

@each $color, $value in $grays {
  .badge.text-bg-gray-#{$color} {
    
    &[href]:hover,
    &[href]:focus {
      --#{$prefix}bg-opacity: .2;
    }
  }
}


// Active states

.badge.text-bg-white-subtle.active {
  background-color: var(--#{$prefix}white) !important;
  color: var(--#{$prefix}primary) !important;
}

.badge.text-bg-secondary-subtle.active {
  background-color: var(--#{$prefix}primary) !important;
  color: var(--#{$prefix}white) !important;
}
