//
// popover.scss
// Extended from Bootstrap
//

.popover-header {
  padding-bottom: 0;
  margin-bottom: $headings-margin-bottom;
  font-size: var(--#{$prefix}popover-header-font-size);
  color: var(--#{$prefix}popover-header-color);
}

.popover-header:not(:empty) ~ .popover-body {
  padding-top: 0;
}