// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
@each $color, $value in $theme-colors-bg-subtle {
  .text-bg-#{$color}-subtle {
    --#{$prefix}bg-opacity: .1;
    color: RGBA(var(--#{$prefix}#{$color}-bg-subtle-rgb), 1) if($enable-important-utilities, !important, null);
    background-color: RGBA(var(--#{$prefix}#{$color}-bg-subtle-rgb), var(--#{$prefix}bg-opacity, .1)) if($enable-important-utilities, !important, null);
  }
}

@each $color, $value in $grays {
  .text-bg-gray-#{$color} {
    color: color-contrast($value) if($enable-important-utilities, !important, null);
    background-color: RGBA(var(--#{$prefix}gray-#{$color}-rgb), var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
  }
}