@charset "UTF-8";
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #161C2D;
  --bs-white: #FFFFFF;
  --bs-gray: #869AB8;
  --bs-gray-dark: #384C74;
  --bs-gray-100: #F9FBFD;
  --bs-gray-200: #F1F4F8;
  --bs-gray-300: #D9E2EF;
  --bs-gray-400: #C6D3E6;
  --bs-gray-500: #ABBCD5;
  --bs-gray-600: #869AB8;
  --bs-gray-700: #506690;
  --bs-gray-800: #384C74;
  --bs-gray-900: #1B2A4E;
  --bs-primary: #335EEA;
  --bs-primary-desat: #6C8AEC;
  --bs-secondary: #506690;
  --bs-success: #42BA96;
  --bs-info: #8b44ac;
  --bs-warning: #FAD776;
  --bs-danger: #DF4759;
  --bs-light: #F9FBFD;
  --bs-dark: #1B2A4E;
  --bs-primary-rgb: 51, 94, 234;
  --bs-primary-desat-rgb: 108, 138, 236;
  --bs-secondary-rgb: 80, 102, 144;
  --bs-success-rgb: 66, 186, 150;
  --bs-info-rgb: 139, 68, 172;
  --bs-warning-rgb: 250, 215, 118;
  --bs-danger-rgb: 223, 71, 89;
  --bs-light-rgb: 249, 251, 253;
  --bs-dark-rgb: 27, 42, 78;
  --bs-primary-text-emphasis: #14265e;
  --bs-secondary-text-emphasis: #20293a;
  --bs-success-text-emphasis: #1a4a3c;
  --bs-info-text-emphasis: #381b45;
  --bs-warning-text-emphasis: #64562f;
  --bs-danger-text-emphasis: #591c24;
  --bs-light-text-emphasis: #506690;
  --bs-dark-text-emphasis: #506690;
  --bs-primary-bg-subtle: rgba(51, 94, 234, 0.1);
  --bs-primary-desat-bg-subtle: rgba(51, 94, 234, 0.1);
  --bs-secondary-bg-subtle: rgba(80, 102, 144, 0.1);
  --bs-success-bg-subtle: rgba(66, 186, 150, 0.1);
  --bs-info-bg-subtle: rgba(139, 68, 172, 0.1);
  --bs-warning-bg-subtle: rgba(250, 215, 118, 0.1);
  --bs-danger-bg-subtle: rgba(223, 71, 89, 0.1);
  --bs-light-bg-subtle: rgba(249, 251, 253, 0.1);
  --bs-dark-bg-subtle: rgba(27, 42, 78, 0.1);
  --bs-white-bg-subtle: rgba(255, 255, 255, 0.1);
  --bs-gray-700-bg-subtle: rgba(80, 102, 144, 0.1);
  --bs-primary-border-subtle: #adbff7;
  --bs-secondary-border-subtle: #b9c2d3;
  --bs-success-border-subtle: #b3e3d5;
  --bs-info-border-subtle: #d1b4de;
  --bs-warning-border-subtle: #fdefc8;
  --bs-danger-border-subtle: #f2b5bd;
  --bs-light-border-subtle: #F1F4F8;
  --bs-dark-border-subtle: #ABBCD5;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 22, 28, 45;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Outfit", serif;
  --bs-body-font-size: 1.0625rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #161C2D;
  --bs-body-color-rgb: 22, 28, 45;
  --bs-body-bg: #FFFFFF;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #161C2D;
  --bs-emphasis-color-rgb: 22, 28, 45;
  --bs-secondary-color: #869AB8;
  --bs-secondary-color-rgb: 134, 154, 184;
  --bs-secondary-bg: #F1F4F8;
  --bs-secondary-bg-rgb: 241, 244, 248;
  --bs-tertiary-color: rgba(22, 28, 45, 0.5);
  --bs-tertiary-color-rgb: 22, 28, 45;
  --bs-tertiary-bg: #F9FBFD;
  --bs-tertiary-bg-rgb: 249, 251, 253;
  --bs-heading-color: inherit;
  --bs-link-color: #335EEA;
  --bs-link-color-rgb: 51, 94, 234;
  --bs-link-decoration: none;
  --bs-link-hover-color: #294bbb;
  --bs-link-hover-color-rgb: 41, 75, 187;
  --bs-link-hover-decoration: underline;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #F1F4F8;
  --bs-border-color-translucent: rgba(22, 28, 45, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075);
  --bs-box-shadow-lg: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(22, 28, 45, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(51, 94, 234, 0.25);
  --bs-form-valid-color: #42BA96;
  --bs-form-valid-border-color: #42BA96;
  --bs-form-invalid-color: #DF4759;
  --bs-form-invalid-border-color: #DF4759;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(22, 28, 45, 0);
}

hr {
  margin: 1rem 0;
  color: var(--bs-border-color);
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: 2.3125rem;
}

h2, .h2 {
  font-size: 1.9375rem;
}

h3, .h3 {
  font-size: 1.3125rem;
}

h4, .h4 {
  font-size: 1.1875rem;
}

h5, .h5 {
  font-size: 1.0625rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 88.2%;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.8125rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.8125rem;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.8125rem;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.3125rem;
  font-weight: 200;
}

.display-1 {
  font-size: 5.125rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-2 {
  font-size: 3.8125rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-4 {
  font-size: 2.6875rem;
  font-weight: 400;
  line-height: 1.2;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 88.2%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  color: var(--bs-gray-600);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 88.2%;
  color: #869AB8;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1040px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.5rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 6rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 6rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 8rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 8rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 10rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 10rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 12rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 12rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 16rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 16rem;
}

.g-16,
.gx-16 {
  --bs-gutter-x: 25rem;
}

.g-16,
.gy-16 {
  --bs-gutter-y: 25rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 8rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 8rem;
  }
  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 12rem;
  }
  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 12rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 16rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 16rem;
  }
  .g-sm-16,
  .gx-sm-16 {
    --bs-gutter-x: 25rem;
  }
  .g-sm-16,
  .gy-sm-16 {
    --bs-gutter-y: 25rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 3rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 3rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 5rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 5rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 6rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 6rem;
  }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 8rem;
  }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 8rem;
  }
  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 10rem;
  }
  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 10rem;
  }
  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 12rem;
  }
  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 12rem;
  }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 16rem;
  }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 16rem;
  }
  .g-md-16,
  .gx-md-16 {
    --bs-gutter-x: 25rem;
  }
  .g-md-16,
  .gy-md-16 {
    --bs-gutter-y: 25rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 8rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 8rem;
  }
  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 12rem;
  }
  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 12rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 16rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 16rem;
  }
  .g-lg-16,
  .gx-lg-16 {
    --bs-gutter-x: 25rem;
  }
  .g-lg-16,
  .gy-lg-16 {
    --bs-gutter-y: 25rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 8rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 8rem;
  }
  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 12rem;
  }
  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 12rem;
  }
  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 16rem;
  }
  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 16rem;
  }
  .g-xl-16,
  .gx-xl-16 {
    --bs-gutter-x: 25rem;
  }
  .g-xl-16,
  .gy-xl-16 {
    --bs-gutter-y: 25rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 8rem;
  }
  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 12rem;
  }
  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 12rem;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 16rem;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 16rem;
  }
  .g-xxl-16,
  .gx-xxl-16 {
    --bs-gutter-x: 25rem;
  }
  .g-xxl-16,
  .gy-xxl-16 {
    --bs-gutter-y: 25rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-white);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: var(--bs-gray-100);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: var(--bs-gray-100);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: var(--bs-gray-100);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 1.5rem 2rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #1B2A4E;
  --bs-table-bg: #ebeffd;
  --bs-table-border-color: #d6dbec;
  --bs-table-striped-bg: #e1e5f4;
  --bs-table-striped-color: #1B2A4E;
  --bs-table-active-bg: #d6dbec;
  --bs-table-active-color: #1B2A4E;
  --bs-table-hover-bg: #dbe0f0;
  --bs-table-hover-color: #1B2A4E;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #1B2A4E;
  --bs-table-bg: #eef0f4;
  --bs-table-border-color: #d9dce3;
  --bs-table-striped-bg: #e3e6ec;
  --bs-table-striped-color: #1B2A4E;
  --bs-table-active-bg: #d9dce3;
  --bs-table-active-color: #1B2A4E;
  --bs-table-hover-bg: #dee1e8;
  --bs-table-hover-color: #1B2A4E;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #1B2A4E;
  --bs-table-bg: #ecf8f5;
  --bs-table-border-color: #d7e3e4;
  --bs-table-striped-bg: #e2eeed;
  --bs-table-striped-color: #1B2A4E;
  --bs-table-active-bg: #d7e3e4;
  --bs-table-active-color: #1B2A4E;
  --bs-table-hover-bg: #dce9e8;
  --bs-table-hover-color: #1B2A4E;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #1B2A4E;
  --bs-table-bg: #f3ecf7;
  --bs-table-border-color: #ddd9e6;
  --bs-table-striped-bg: #e8e2ef;
  --bs-table-striped-color: #1B2A4E;
  --bs-table-active-bg: #ddd9e6;
  --bs-table-active-color: #1B2A4E;
  --bs-table-hover-bg: #e3ddea;
  --bs-table-hover-color: #1B2A4E;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #1B2A4E;
  --bs-table-bg: #fffbf1;
  --bs-table-border-color: #e8e6e1;
  --bs-table-striped-bg: #f4f1e9;
  --bs-table-striped-color: #1B2A4E;
  --bs-table-active-bg: #e8e6e1;
  --bs-table-active-color: #1B2A4E;
  --bs-table-hover-bg: #eeebe5;
  --bs-table-hover-color: #1B2A4E;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #1B2A4E;
  --bs-table-bg: #fcedee;
  --bs-table-border-color: #e6dade;
  --bs-table-striped-bg: #f1e3e6;
  --bs-table-striped-color: #1B2A4E;
  --bs-table-active-bg: #e6dade;
  --bs-table-active-color: #1B2A4E;
  --bs-table-hover-bg: #ebdee2;
  --bs-table-hover-color: #1B2A4E;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #1B2A4E;
  --bs-table-bg: #F9FBFD;
  --bs-table-border-color: #e3e6ec;
  --bs-table-striped-bg: #eef1f4;
  --bs-table-striped-color: #1B2A4E;
  --bs-table-active-bg: #e3e6ec;
  --bs-table-active-color: #1B2A4E;
  --bs-table-hover-bg: #e8ebf0;
  --bs-table-hover-color: #1B2A4E;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #FFFFFF;
  --bs-table-bg: #384C74;
  --bs-table-border-color: #4c5e82;
  --bs-table-striped-bg: #42557b;
  --bs-table-striped-color: #FFFFFF;
  --bs-table-active-bg: #4c5e82;
  --bs-table-active-color: #FFFFFF;
  --bs-table-hover-bg: #47597e;
  --bs-table-hover-color: #FFFFFF;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.8125rem + 1px);
  padding-bottom: calc(0.8125rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(1.122rem + 1px);
  padding-bottom: calc(1.122rem + 1px);
  font-size: 1.0625rem;
}

.col-form-label-sm {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 1.0625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 88.2%;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-white);
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: 0 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-white);
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0, 0 0;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.6em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #869AB8;
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.8125rem 1.25rem;
  margin: -0.8125rem -1.25rem;
  margin-inline-end: 1.25rem;
  color: var(--bs-body-color);
  background-color: #F9FBFD;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.8125rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 1.125rem + calc(1px * 2));
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius);
}
.form-control-sm::file-selector-button {
  padding: 0.5625rem 1rem;
  margin: -0.5625rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg {
  min-height: calc(1.6em + 2.244rem + calc(1px * 2));
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius);
}
.form-control-lg::file-selector-button {
  padding: 1.122rem 1.5rem;
  margin: -1.122rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.6em + 1.625rem + calc(1px * 2));
}
textarea.form-control-sm {
  min-height: calc(1.6em + 1.125rem + calc(1px * 2));
}
textarea.form-control-lg {
  min-height: calc(1.6em + 2.244rem + calc(1px * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.6em + 1.625rem + calc(1px * 2));
  padding: 0.8125rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.6em + 1.125rem + calc(1px * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.6em + 2.244rem + calc(1px * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L4.25 4.25L7.5 1' stroke='%23C6D3E6' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.8125rem 1.25rem 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-white);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 1em 1em;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: 0 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0, 0 0;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.25rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  padding-left: 1rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius);
}

.form-select-lg {
  padding-top: 1.122rem;
  padding-bottom: 1.122rem;
  padding-left: 1.5rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius);
}

.form-check {
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5rem;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5rem;
}

.form-check-reverse {
  padding-right: 1.5rem;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5rem;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-gray-300);
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: transparent;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: var(--bs-border-radius);
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: none;
}
.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0;
}
.form-check-input:checked {
  background-color: #335EEA;
  border-color: #335EEA;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #335EEA;
  border-color: #335EEA;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-label {
  cursor: pointer;
}

.form-switch {
  padding-left: 3rem;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
  width: 2.5rem;
  margin-left: -3rem;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2.5rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3rem;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3rem;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #335EEA;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c2cff9;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(22, 28, 45, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #335EEA;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c2cff9;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(22, 28, 45, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(1px * 2));
  min-height: calc(3.5rem + calc(1px * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1.25rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.25rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 1);
  transform: translateY(-0.5rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.625rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-white);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 1);
  transform: translateY(-0.5rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #869AB8;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #869AB8;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 88.2%;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9375rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 88.2%;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9375rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.8125rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.0625rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.6;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0 0;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #335EEA;
  --bs-btn-border-color: #335EEA;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #2b50c7;
  --bs-btn-hover-border-color: #294bbb;
  --bs-btn-focus-shadow-rgb: 82, 118, 237;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #294bbb;
  --bs-btn-active-border-color: #2647b0;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #335EEA;
  --bs-btn-disabled-border-color: #335EEA;
}

.btn-primary-desat {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #6C8AEC;
  --bs-btn-border-color: #6C8AEC;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5c75c9;
  --bs-btn-hover-border-color: #566ebd;
  --bs-btn-focus-shadow-rgb: 130, 156, 239;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #566ebd;
  --bs-btn-active-border-color: #5168b1;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #6C8AEC;
  --bs-btn-disabled-border-color: #6C8AEC;
}

.btn-secondary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #506690;
  --bs-btn-border-color: #506690;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #44577a;
  --bs-btn-hover-border-color: #405273;
  --bs-btn-focus-shadow-rgb: 106, 125, 161;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #405273;
  --bs-btn-active-border-color: #3c4d6c;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #506690;
  --bs-btn-disabled-border-color: #506690;
}

.btn-success {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #42BA96;
  --bs-btn-border-color: #42BA96;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #389e80;
  --bs-btn-hover-border-color: #359578;
  --bs-btn-focus-shadow-rgb: 94, 196, 166;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #359578;
  --bs-btn-active-border-color: #328c71;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #42BA96;
  --bs-btn-disabled-border-color: #42BA96;
}

.btn-info {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #8b44ac;
  --bs-btn-border-color: #8b44ac;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #763a92;
  --bs-btn-hover-border-color: #6f368a;
  --bs-btn-focus-shadow-rgb: 156, 96, 184;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #6f368a;
  --bs-btn-active-border-color: #683381;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #8b44ac;
  --bs-btn-disabled-border-color: #8b44ac;
}

.btn-warning {
  --bs-btn-color: #1B2A4E;
  --bs-btn-bg: #FAD776;
  --bs-btn-border-color: #FAD776;
  --bs-btn-hover-color: #1B2A4E;
  --bs-btn-hover-bg: #fbdd8b;
  --bs-btn-hover-border-color: #fbdb84;
  --bs-btn-focus-shadow-rgb: 217, 189, 112;
  --bs-btn-active-color: #1B2A4E;
  --bs-btn-active-bg: #fbdf91;
  --bs-btn-active-border-color: #fbdb84;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #1B2A4E;
  --bs-btn-disabled-bg: #FAD776;
  --bs-btn-disabled-border-color: #FAD776;
}

.btn-danger {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #DF4759;
  --bs-btn-border-color: #DF4759;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #be3c4c;
  --bs-btn-hover-border-color: #b23947;
  --bs-btn-focus-shadow-rgb: 228, 99, 114;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #b23947;
  --bs-btn-active-border-color: #a73543;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #DF4759;
  --bs-btn-disabled-border-color: #DF4759;
}

.btn-light {
  --bs-btn-color: #1B2A4E;
  --bs-btn-bg: #F9FBFD;
  --bs-btn-border-color: #F9FBFD;
  --bs-btn-hover-color: #1B2A4E;
  --bs-btn-hover-bg: #d4d5d7;
  --bs-btn-hover-border-color: #c7c9ca;
  --bs-btn-focus-shadow-rgb: 216, 220, 227;
  --bs-btn-active-color: #1B2A4E;
  --bs-btn-active-bg: #c7c9ca;
  --bs-btn-active-border-color: #bbbcbe;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #1B2A4E;
  --bs-btn-disabled-bg: #F9FBFD;
  --bs-btn-disabled-border-color: #F9FBFD;
}

.btn-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #1B2A4E;
  --bs-btn-border-color: #1B2A4E;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #3d4a69;
  --bs-btn-hover-border-color: #323f60;
  --bs-btn-focus-shadow-rgb: 61, 74, 105;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #495571;
  --bs-btn-active-border-color: #323f60;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #1B2A4E;
  --bs-btn-disabled-border-color: #1B2A4E;
}

.btn-outline-primary {
  --bs-btn-color: #335EEA;
  --bs-btn-border-color: #335EEA;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #335EEA;
  --bs-btn-hover-border-color: #335EEA;
  --bs-btn-focus-shadow-rgb: 51, 94, 234;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #335EEA;
  --bs-btn-active-border-color: #335EEA;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #335EEA;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #335EEA;
  --bs-gradient: none;
}

.btn-outline-primary-desat {
  --bs-btn-color: #6C8AEC;
  --bs-btn-border-color: #6C8AEC;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #6C8AEC;
  --bs-btn-hover-border-color: #6C8AEC;
  --bs-btn-focus-shadow-rgb: 108, 138, 236;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #6C8AEC;
  --bs-btn-active-border-color: #6C8AEC;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #6C8AEC;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6C8AEC;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #506690;
  --bs-btn-border-color: #506690;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #506690;
  --bs-btn-hover-border-color: #506690;
  --bs-btn-focus-shadow-rgb: 80, 102, 144;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #506690;
  --bs-btn-active-border-color: #506690;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #506690;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #506690;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #42BA96;
  --bs-btn-border-color: #42BA96;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #42BA96;
  --bs-btn-hover-border-color: #42BA96;
  --bs-btn-focus-shadow-rgb: 66, 186, 150;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #42BA96;
  --bs-btn-active-border-color: #42BA96;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #42BA96;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #42BA96;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #8b44ac;
  --bs-btn-border-color: #8b44ac;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #8b44ac;
  --bs-btn-hover-border-color: #8b44ac;
  --bs-btn-focus-shadow-rgb: 139, 68, 172;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #8b44ac;
  --bs-btn-active-border-color: #8b44ac;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #8b44ac;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8b44ac;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FAD776;
  --bs-btn-border-color: #FAD776;
  --bs-btn-hover-color: #1B2A4E;
  --bs-btn-hover-bg: #FAD776;
  --bs-btn-hover-border-color: #FAD776;
  --bs-btn-focus-shadow-rgb: 250, 215, 118;
  --bs-btn-active-color: #1B2A4E;
  --bs-btn-active-bg: #FAD776;
  --bs-btn-active-border-color: #FAD776;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FAD776;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FAD776;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #DF4759;
  --bs-btn-border-color: #DF4759;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #DF4759;
  --bs-btn-hover-border-color: #DF4759;
  --bs-btn-focus-shadow-rgb: 223, 71, 89;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #DF4759;
  --bs-btn-active-border-color: #DF4759;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #DF4759;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #DF4759;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #F9FBFD;
  --bs-btn-border-color: #F9FBFD;
  --bs-btn-hover-color: #1B2A4E;
  --bs-btn-hover-bg: #F9FBFD;
  --bs-btn-hover-border-color: #F9FBFD;
  --bs-btn-focus-shadow-rgb: 249, 251, 253;
  --bs-btn-active-color: #1B2A4E;
  --bs-btn-active-bg: #F9FBFD;
  --bs-btn-active-border-color: #F9FBFD;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #F9FBFD;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F9FBFD;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #1B2A4E;
  --bs-btn-border-color: #1B2A4E;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #1B2A4E;
  --bs-btn-hover-border-color: #1B2A4E;
  --bs-btn-focus-shadow-rgb: 27, 42, 78;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #1B2A4E;
  --bs-btn-active-border-color: #1B2A4E;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #1B2A4E;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1B2A4E;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #869AB8;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 82, 118, 237;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1.122rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 1.0625rem;
  --bs-btn-border-radius: var(--bs-border-radius);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.5625rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.0625rem;
  --bs-btn-border-radius: var(--bs-border-radius);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 14rem;
  --bs-dropdown-padding-x: var(--bs-dropdown-item-padding-x);
  --bs-dropdown-padding-y: 1.625rem;
  --bs-dropdown-spacer: 0;
  --bs-dropdown-font-size: 0.9375rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: 0px;
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - 0px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
  --bs-dropdown-link-color: var(--bs-gray-700);
  --bs-dropdown-link-hover-color: var(--bs-primary);
  --bs-dropdown-link-hover-bg: none;
  --bs-dropdown-link-active-color: var(--bs-primary);
  --bs-dropdown-link-active-bg: none;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1.75rem;
  --bs-dropdown-item-padding-y: 0;
  --bs-dropdown-header-color: var(--bs-primary);
  --bs-dropdown-header-padding-x: 1.75rem;
  --bs-dropdown-header-padding-y: 1.625rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #D9E2EF;
  --bs-dropdown-bg: #384C74;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #D9E2EF;
  --bs-dropdown-link-hover-color: #FFFFFF;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: var(--bs-primary);
  --bs-dropdown-link-active-bg: none;
  --bs-dropdown-link-disabled-color: #ABBCD5;
  --bs-dropdown-header-color: #ABBCD5;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 94, 234, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #FFFFFF;
  --bs-nav-pills-link-active-bg: #335EEA;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 1.25rem;
  --bs-navbar-color: var(--bs-gray-700);
  --bs-navbar-hover-color: var(--bs-primary);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: var(--bs-primary);
  --bs-navbar-brand-padding-y: 0.15rem;
  --bs-navbar-brand-margin-end: 0;
  --bs-navbar-brand-font-size: 1.5rem;
  --bs-navbar-brand-color: var(--bs-primary);
  --bs-navbar-brand-hover-color: var(--bs-primary);
  --bs-navbar-nav-link-padding-x: 1.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.1875rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23506690' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: transparent;
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: var(--bs-white);
  --bs-navbar-hover-color: var(--bs-white);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: var(--bs-white);
  --bs-navbar-brand-color: var(--bs-white);
  --bs-navbar-brand-hover-color: var(--bs-white);
  --bs-navbar-toggler-border-color: transparent;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
  --bs-card-title-spacer-y: 1.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0px;
  --bs-card-border-color: var(--bs-border-color);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - 0px);
  --bs-card-cap-padding-y: 1.5rem;
  --bs-card-cap-padding-x: 2rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 2rem;
  --bs-accordion-btn-padding-y: 1.5rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23869AB8'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 0.85rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23869AB8'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: var(--bs-primary);
  --bs-accordion-btn-focus-box-shadow: 0 0;
  --bs-accordion-body-padding-x: 2rem;
  --bs-accordion-body-padding-y: 1.5rem;
  --bs-accordion-active-color: var(--bs-body-color);
  --bs-accordion-active-bg: var(--bs-body-bg);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.0625rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0.75rem;
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-font-size: 0.9375rem;
  --bs-breadcrumb-bg: transparent;
  --bs-breadcrumb-border-radius: 0;
  --bs-breadcrumb-divider-color: var(--bs-gray-700);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-gray-700);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 1.25rem;
  --bs-pagination-padding-y: 0.8125rem;
  --bs-pagination-font-size: 1.0625rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-gray-300);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-gray-200);
  --bs-pagination-hover-border-color: var(--bs-pagination-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0;
  --bs-pagination-active-color: #FFFFFF;
  --bs-pagination-active-bg: #335EEA;
  --bs-pagination-active-border-color: #335EEA;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-pagination-bg);
  --bs-pagination-disabled-border-color: var(--bs-pagination-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 1.122rem;
  --bs-pagination-font-size: 1.1875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 1rem;
  --bs-pagination-padding-y: 0.5625rem;
  --bs-pagination-font-size: 0.9375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.75rem;
  --bs-badge-padding-y: 0.25rem;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #FFFFFF;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-primary-desat {
  --bs-alert-color: var(--bs-primary-desat-text-emphasis);
  --bs-alert-bg: var(--bs-primary-desat-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-desat-border-subtle);
  --bs-alert-link-color: var(--bs-primary-desat-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.796875rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #FFFFFF;
  --bs-progress-bar-bg: #335EEA;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 2rem;
  --bs-list-group-item-padding-y: 1.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #FFFFFF;
  --bs-list-group-active-bg: #335EEA;
  --bs-list-group-active-border-color: #335EEA;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-primary-desat {
  --bs-list-group-color: var(--bs-primary-desat-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-desat-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-desat-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-desat-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-desat-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-desat-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-desat-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-desat-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #869AB8;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23869AB8'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: none;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 0.5em;
  height: 0.5em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/0.5em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 2.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 2.5rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 2.5rem;
  --bs-modal-header-padding-y: 2.5rem;
  --bs-modal-header-padding: 2.5rem 2.5rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.6;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #161C2D;
  --bs-backdrop-opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.9375rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Outfit", serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 380px;
  --bs-popover-font-size: 0.9375rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: transparent;
  --bs-popover-border-radius: var(--bs-border-radius);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  --bs-popover-header-padding-x: 2rem;
  --bs-popover-header-padding-y: 1.5rem;
  --bs-popover-header-font-size: 0.75rem;
  --bs-popover-header-color: var(--bs-primary);
  --bs-popover-header-bg: transparent;
  --bs-popover-body-padding-x: 2rem;
  --bs-popover-body-padding-y: 1.5rem;
  --bs-popover-body-color: #869AB8;
  --bs-popover-arrow-width: 1.25rem;
  --bs-popover-arrow-height: 0.625rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Outfit", serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFFFFF;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #161C2D;
}
.carousel-dark .carousel-caption {
  color: #161C2D;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 2.5rem;
  --bs-offcanvas-padding-y: 2.5rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.6;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #161C2D;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.8;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #161C2D 55%, rgba(0, 0, 0, 0.8) 75%, #161C2D 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-desat {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-desat-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #1B2A4E !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #1B2A4E !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(41, 75, 187, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 75, 187, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-desat {
  color: RGBA(var(--bs-primary-desat-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-desat-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-desat:hover, .link-primary-desat:focus {
  color: RGBA(86, 110, 189, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 110, 189, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(64, 82, 115, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(64, 82, 115, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(53, 149, 120, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(53, 149, 120, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(111, 54, 138, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(111, 54, 138, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(251, 223, 145, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(251, 223, 145, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(178, 57, 71, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(178, 57, 71, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(250, 252, 253, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(250, 252, 253, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(22, 34, 62, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(22, 34, 62, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.bg-cover {
  background: no-repeat center center/cover;
}

@media (max-width: 991.98px) {
  .bg-between {
    background-image: none !important;
  }
}
@media (min-width: 992px) {
  .bg-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 70%;
  }
}

.bg-gradient-light-white {
  background-image: linear-gradient(to bottom, var(--bs-light) 0%, var(--bs-white) 100%);
}

.bg-gradient-dark-black {
  background-image: linear-gradient(to bottom, var(--bs-dark) 0%, var(--bs-black) 100%);
}

.bg-gradient-multicolor {
  background-image: conic-gradient(from 52deg at 50% 50%, #8024A0 0deg, #31EEC1 33deg, #6924A0 222deg, #D6723B 264deg, #D5498C 295deg, #8024A0 360deg);
}

.bg-pattern-2 {
  background-image: linear-gradient(to bottom, transparent 0%, var(--bs-black) 100%), url("../img/patterns/pattern-2.png");
}

.img-skewed {
  perspective: 1500px;
  transform-style: preserve-3d;
}

.img-skewed-start {
  perspective-origin: left center;
}
.img-skewed-start .img-skewed-item {
  transform: rotateY(calc(35deg * -1)) rotateX(15deg);
  backface-visibility: hidden;
}

.img-skewed-end {
  perspective-origin: right center;
}
.img-skewed-end .img-skewed-item {
  transform: rotateY(35deg) rotateX(15deg);
  backface-visibility: hidden;
}

.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}

.lift:hover,
.lift:focus {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
  transform: translate3d(0, -3px, 0);
}

.lift-lg:hover,
.lift-lg:focus {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
  transform: translate3d(0, -5px, 0);
}

.overlay {
  position: relative;
}
.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.overlay-primary::before {
  background-color: #335EEA;
}

.overlay-gradient-primary-end::before {
  background-image: linear-gradient(to bottom, transparent, #335EEA);
}
@media (min-width: 992px) {
  .overlay-gradient-primary-end::before {
    background-image: linear-gradient(to right, transparent, #335EEA 50%, #335EEA);
  }
}

.overlay-gradient-primary-down::before {
  background-image: linear-gradient(to bottom, transparent, #335EEA);
}
@media (min-width: 992px) {
  .overlay-gradient-primary-down::before {
    background-image: linear-gradient(to bottom, transparent, #335EEA 50%, #335EEA);
  }
}

.overlay-gradient-primary-start::before {
  background-image: linear-gradient(to bottom, transparent, #335EEA);
}
@media (min-width: 992px) {
  .overlay-gradient-primary-start::before {
    background-image: linear-gradient(to left, transparent, #335EEA 50%, #335EEA);
  }
}

.overlay-primary-desat::before {
  background-color: #6C8AEC;
}

.overlay-gradient-primary-desat-end::before {
  background-image: linear-gradient(to bottom, transparent, #6C8AEC);
}
@media (min-width: 992px) {
  .overlay-gradient-primary-desat-end::before {
    background-image: linear-gradient(to right, transparent, #6C8AEC 50%, #6C8AEC);
  }
}

.overlay-gradient-primary-desat-down::before {
  background-image: linear-gradient(to bottom, transparent, #6C8AEC);
}
@media (min-width: 992px) {
  .overlay-gradient-primary-desat-down::before {
    background-image: linear-gradient(to bottom, transparent, #6C8AEC 50%, #6C8AEC);
  }
}

.overlay-gradient-primary-desat-start::before {
  background-image: linear-gradient(to bottom, transparent, #6C8AEC);
}
@media (min-width: 992px) {
  .overlay-gradient-primary-desat-start::before {
    background-image: linear-gradient(to left, transparent, #6C8AEC 50%, #6C8AEC);
  }
}

.overlay-secondary::before {
  background-color: #506690;
}

.overlay-gradient-secondary-end::before {
  background-image: linear-gradient(to bottom, transparent, #506690);
}
@media (min-width: 992px) {
  .overlay-gradient-secondary-end::before {
    background-image: linear-gradient(to right, transparent, #506690 50%, #506690);
  }
}

.overlay-gradient-secondary-down::before {
  background-image: linear-gradient(to bottom, transparent, #506690);
}
@media (min-width: 992px) {
  .overlay-gradient-secondary-down::before {
    background-image: linear-gradient(to bottom, transparent, #506690 50%, #506690);
  }
}

.overlay-gradient-secondary-start::before {
  background-image: linear-gradient(to bottom, transparent, #506690);
}
@media (min-width: 992px) {
  .overlay-gradient-secondary-start::before {
    background-image: linear-gradient(to left, transparent, #506690 50%, #506690);
  }
}

.overlay-success::before {
  background-color: #42BA96;
}

.overlay-gradient-success-end::before {
  background-image: linear-gradient(to bottom, transparent, #42BA96);
}
@media (min-width: 992px) {
  .overlay-gradient-success-end::before {
    background-image: linear-gradient(to right, transparent, #42BA96 50%, #42BA96);
  }
}

.overlay-gradient-success-down::before {
  background-image: linear-gradient(to bottom, transparent, #42BA96);
}
@media (min-width: 992px) {
  .overlay-gradient-success-down::before {
    background-image: linear-gradient(to bottom, transparent, #42BA96 50%, #42BA96);
  }
}

.overlay-gradient-success-start::before {
  background-image: linear-gradient(to bottom, transparent, #42BA96);
}
@media (min-width: 992px) {
  .overlay-gradient-success-start::before {
    background-image: linear-gradient(to left, transparent, #42BA96 50%, #42BA96);
  }
}

.overlay-info::before {
  background-color: #8b44ac;
}

.overlay-gradient-info-end::before {
  background-image: linear-gradient(to bottom, transparent, #8b44ac);
}
@media (min-width: 992px) {
  .overlay-gradient-info-end::before {
    background-image: linear-gradient(to right, transparent, #8b44ac 50%, #8b44ac);
  }
}

.overlay-gradient-info-down::before {
  background-image: linear-gradient(to bottom, transparent, #8b44ac);
}
@media (min-width: 992px) {
  .overlay-gradient-info-down::before {
    background-image: linear-gradient(to bottom, transparent, #8b44ac 50%, #8b44ac);
  }
}

.overlay-gradient-info-start::before {
  background-image: linear-gradient(to bottom, transparent, #8b44ac);
}
@media (min-width: 992px) {
  .overlay-gradient-info-start::before {
    background-image: linear-gradient(to left, transparent, #8b44ac 50%, #8b44ac);
  }
}

.overlay-warning::before {
  background-color: #FAD776;
}

.overlay-gradient-warning-end::before {
  background-image: linear-gradient(to bottom, transparent, #FAD776);
}
@media (min-width: 992px) {
  .overlay-gradient-warning-end::before {
    background-image: linear-gradient(to right, transparent, #FAD776 50%, #FAD776);
  }
}

.overlay-gradient-warning-down::before {
  background-image: linear-gradient(to bottom, transparent, #FAD776);
}
@media (min-width: 992px) {
  .overlay-gradient-warning-down::before {
    background-image: linear-gradient(to bottom, transparent, #FAD776 50%, #FAD776);
  }
}

.overlay-gradient-warning-start::before {
  background-image: linear-gradient(to bottom, transparent, #FAD776);
}
@media (min-width: 992px) {
  .overlay-gradient-warning-start::before {
    background-image: linear-gradient(to left, transparent, #FAD776 50%, #FAD776);
  }
}

.overlay-danger::before {
  background-color: #DF4759;
}

.overlay-gradient-danger-end::before {
  background-image: linear-gradient(to bottom, transparent, #DF4759);
}
@media (min-width: 992px) {
  .overlay-gradient-danger-end::before {
    background-image: linear-gradient(to right, transparent, #DF4759 50%, #DF4759);
  }
}

.overlay-gradient-danger-down::before {
  background-image: linear-gradient(to bottom, transparent, #DF4759);
}
@media (min-width: 992px) {
  .overlay-gradient-danger-down::before {
    background-image: linear-gradient(to bottom, transparent, #DF4759 50%, #DF4759);
  }
}

.overlay-gradient-danger-start::before {
  background-image: linear-gradient(to bottom, transparent, #DF4759);
}
@media (min-width: 992px) {
  .overlay-gradient-danger-start::before {
    background-image: linear-gradient(to left, transparent, #DF4759 50%, #DF4759);
  }
}

.overlay-light::before {
  background-color: #F9FBFD;
}

.overlay-gradient-light-end::before {
  background-image: linear-gradient(to bottom, transparent, #F9FBFD);
}
@media (min-width: 992px) {
  .overlay-gradient-light-end::before {
    background-image: linear-gradient(to right, transparent, #F9FBFD 50%, #F9FBFD);
  }
}

.overlay-gradient-light-down::before {
  background-image: linear-gradient(to bottom, transparent, #F9FBFD);
}
@media (min-width: 992px) {
  .overlay-gradient-light-down::before {
    background-image: linear-gradient(to bottom, transparent, #F9FBFD 50%, #F9FBFD);
  }
}

.overlay-gradient-light-start::before {
  background-image: linear-gradient(to bottom, transparent, #F9FBFD);
}
@media (min-width: 992px) {
  .overlay-gradient-light-start::before {
    background-image: linear-gradient(to left, transparent, #F9FBFD 50%, #F9FBFD);
  }
}

.overlay-dark::before {
  background-color: #1B2A4E;
}

.overlay-gradient-dark-end::before {
  background-image: linear-gradient(to bottom, transparent, #1B2A4E);
}
@media (min-width: 992px) {
  .overlay-gradient-dark-end::before {
    background-image: linear-gradient(to right, transparent, #1B2A4E 50%, #1B2A4E);
  }
}

.overlay-gradient-dark-down::before {
  background-image: linear-gradient(to bottom, transparent, #1B2A4E);
}
@media (min-width: 992px) {
  .overlay-gradient-dark-down::before {
    background-image: linear-gradient(to bottom, transparent, #1B2A4E 50%, #1B2A4E);
  }
}

.overlay-gradient-dark-start::before {
  background-image: linear-gradient(to bottom, transparent, #1B2A4E);
}
@media (min-width: 992px) {
  .overlay-gradient-dark-start::before {
    background-image: linear-gradient(to left, transparent, #1B2A4E 50%, #1B2A4E);
  }
}

.overlay-black::before {
  background-color: #161C2D;
}

.overlay-gradient-black-end::before {
  background-image: linear-gradient(to bottom, transparent, #161C2D);
}
@media (min-width: 992px) {
  .overlay-gradient-black-end::before {
    background-image: linear-gradient(to right, transparent, #161C2D 50%, #161C2D);
  }
}

.overlay-gradient-black-down::before {
  background-image: linear-gradient(to bottom, transparent, #161C2D);
}
@media (min-width: 992px) {
  .overlay-gradient-black-down::before {
    background-image: linear-gradient(to bottom, transparent, #161C2D 50%, #161C2D);
  }
}

.overlay-gradient-black-start::before {
  background-image: linear-gradient(to bottom, transparent, #161C2D);
}
@media (min-width: 992px) {
  .overlay-gradient-black-start::before {
    background-image: linear-gradient(to left, transparent, #161C2D 50%, #161C2D);
  }
}

.overlay-white::before {
  background-color: #FFFFFF;
}

.overlay-gradient-white-end::before {
  background-image: linear-gradient(to bottom, transparent, #FFFFFF);
}
@media (min-width: 992px) {
  .overlay-gradient-white-end::before {
    background-image: linear-gradient(to right, transparent, #FFFFFF 50%, #FFFFFF);
  }
}

.overlay-gradient-white-down::before {
  background-image: linear-gradient(to bottom, transparent, #FFFFFF);
}
@media (min-width: 992px) {
  .overlay-gradient-white-down::before {
    background-image: linear-gradient(to bottom, transparent, #FFFFFF 50%, #FFFFFF);
  }
}

.overlay-gradient-white-start::before {
  background-image: linear-gradient(to bottom, transparent, #FFFFFF);
}
@media (min-width: 992px) {
  .overlay-gradient-white-start::before {
    background-image: linear-gradient(to left, transparent, #FFFFFF 50%, #FFFFFF);
  }
}

.overlay-10::before {
  opacity: 0.1;
}

.overlay-20::before {
  opacity: 0.2;
}

.overlay-30::before {
  opacity: 0.3;
}

.overlay-40::before {
  opacity: 0.4;
}

.overlay-50::before {
  opacity: 0.5;
}

.overlay-60::before {
  opacity: 0.6;
}

.overlay-70::before {
  opacity: 0.7;
}

.overlay-80::before {
  opacity: 0.8;
}

.overlay-90::before {
  opacity: 0.9;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadow-multicolor {
  box-shadow: -300px 100px 300px rgba(80, 26, 232, 0.2), 300px -100px 300px rgba(36, 161, 252, 0.2);
}

@media (min-width: 576px) {
  .w-cover {
    width: calc(100% + (100vw - 540px) * .5 + 2.5rem);
  }
}
@media (min-width: 768px) {
  .w-cover {
    width: calc(100% + (100vw - 720px) * .5 + 2.5rem);
  }
}
@media (min-width: 992px) {
  .w-cover {
    width: calc(100% + (100vw - 960px) * .5 + 2.5rem);
  }
}
@media (min-width: 1200px) {
  .w-cover {
    width: calc(100% + (100vw - 1040px) * .5 + 2.5rem);
  }
}

@media (max-width: 991.98px) {
  [class].fs-lg {
    font-size: 1.0625rem !important;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-light {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05) !important;
}

.shadow-dark {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.15) !important;
}

.shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important;
}

.shadow-dark-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15) !important;
}

.shadow-lift {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-desat {
  --bs-focus-ring-color: rgba(var(--bs-primary-desat-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-desat {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-desat-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-100 {
  --bs-border-opacity: 1;
  border-color: rgba(249, 251, 253, var(--bs-border-opacity)) !important;
}

.border-gray-200 {
  --bs-border-opacity: 1;
  border-color: rgba(241, 244, 248, var(--bs-border-opacity)) !important;
}

.border-gray-300 {
  --bs-border-opacity: 1;
  border-color: rgba(217, 226, 239, var(--bs-border-opacity)) !important;
}

.border-gray-400 {
  --bs-border-opacity: 1;
  border-color: rgba(198, 211, 230, var(--bs-border-opacity)) !important;
}

.border-gray-500 {
  --bs-border-opacity: 1;
  border-color: rgba(171, 188, 213, var(--bs-border-opacity)) !important;
}

.border-gray-600 {
  --bs-border-opacity: 1;
  border-color: rgba(134, 154, 184, var(--bs-border-opacity)) !important;
}

.border-gray-700 {
  --bs-border-opacity: 1;
  border-color: rgba(80, 102, 144, var(--bs-border-opacity)) !important;
}

.border-gray-800 {
  --bs-border-opacity: 1;
  border-color: rgba(56, 76, 116, var(--bs-border-opacity)) !important;
}

.border-gray-900 {
  --bs-border-opacity: 1;
  border-color: rgba(27, 42, 78, var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.border-opacity-5 {
  --bs-border-opacity: 0.05;
}

.border-opacity-15 {
  --bs-border-opacity: 0.15;
}

.border-opacity-20 {
  --bs-border-opacity: 0.2;
}

.border-opacity-30 {
  --bs-border-opacity: 0.3;
}

.border-opacity-35 {
  --bs-border-opacity: 0.35;
}

.border-opacity-40 {
  --bs-border-opacity: 0.4;
}

.border-opacity-45 {
  --bs-border-opacity: 0.45;
}

.border-opacity-55 {
  --bs-border-opacity: 0.55;
}

.border-opacity-60 {
  --bs-border-opacity: 0.6;
}

.border-opacity-65 {
  --bs-border-opacity: 0.65;
}

.border-opacity-70 {
  --bs-border-opacity: 0.7;
}

.border-opacity-80 {
  --bs-border-opacity: 0.8;
}

.border-opacity-85 {
  --bs-border-opacity: 0.85;
}

.border-opacity-90 {
  --bs-border-opacity: 0.9;
}

.border-opacity-95 {
  --bs-border-opacity: 0.95;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-110 {
  width: 110% !important;
}

.w-120 {
  width: 120% !important;
}

.w-130 {
  width: 130% !important;
}

.w-140 {
  width: 140% !important;
}

.w-150 {
  width: 150% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-110 {
  max-width: 110% !important;
}

.mw-120 {
  max-width: 120% !important;
}

.mw-130 {
  max-width: 130% !important;
}

.mw-140 {
  max-width: 140% !important;
}

.mw-150 {
  max-width: 150% !important;
}

.vw-100 {
  width: 100vw !important;
}

.vw-25 {
  width: 25vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.vw-110 {
  width: 110vw !important;
}

.vw-120 {
  width: 120vw !important;
}

.vw-130 {
  width: 130vw !important;
}

.vw-140 {
  width: 140vw !important;
}

.vw-150 {
  width: 150vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-110 {
  height: 110% !important;
}

.h-120 {
  height: 120% !important;
}

.h-130 {
  height: 130% !important;
}

.h-140 {
  height: 140% !important;
}

.h-150 {
  height: 150% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.vh-25 {
  height: 25vw !important;
}

.vh-50 {
  height: 50vw !important;
}

.vh-75 {
  height: 75vw !important;
}

.vh-110 {
  height: 110vw !important;
}

.vh-120 {
  height: 120vw !important;
}

.vh-130 {
  height: 130vw !important;
}

.vh-140 {
  height: 140vw !important;
}

.vh-150 {
  height: 150vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.m-7 {
  margin: 2.5rem !important;
}

.m-8 {
  margin: 3rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.m-11 {
  margin: 6rem !important;
}

.m-12 {
  margin: 8rem !important;
}

.m-13 {
  margin: 10rem !important;
}

.m-14 {
  margin: 12rem !important;
}

.m-15 {
  margin: 16rem !important;
}

.m-16 {
  margin: 25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-6 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-7 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-8 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-11 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-12 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-13 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-14 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-15 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-16 {
  margin-right: 25rem !important;
  margin-left: 25rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-6 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-7 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-8 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-11 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-12 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-13 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-14 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-15 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-16 {
  margin-top: 25rem !important;
  margin-bottom: 25rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mt-6 {
  margin-top: 2rem !important;
}

.mt-7 {
  margin-top: 2.5rem !important;
}

.mt-8 {
  margin-top: 3rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.mt-11 {
  margin-top: 6rem !important;
}

.mt-12 {
  margin-top: 8rem !important;
}

.mt-13 {
  margin-top: 10rem !important;
}

.mt-14 {
  margin-top: 12rem !important;
}

.mt-15 {
  margin-top: 16rem !important;
}

.mt-16 {
  margin-top: 25rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.5rem !important;
}

.me-6 {
  margin-right: 2rem !important;
}

.me-7 {
  margin-right: 2.5rem !important;
}

.me-8 {
  margin-right: 3rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 5rem !important;
}

.me-11 {
  margin-right: 6rem !important;
}

.me-12 {
  margin-right: 8rem !important;
}

.me-13 {
  margin-right: 10rem !important;
}

.me-14 {
  margin-right: 12rem !important;
}

.me-15 {
  margin-right: 16rem !important;
}

.me-16 {
  margin-right: 25rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 2rem !important;
}

.mb-7 {
  margin-bottom: 2.5rem !important;
}

.mb-8 {
  margin-bottom: 3rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.mb-11 {
  margin-bottom: 6rem !important;
}

.mb-12 {
  margin-bottom: 8rem !important;
}

.mb-13 {
  margin-bottom: 10rem !important;
}

.mb-14 {
  margin-bottom: 12rem !important;
}

.mb-15 {
  margin-bottom: 16rem !important;
}

.mb-16 {
  margin-bottom: 25rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.5rem !important;
}

.ms-6 {
  margin-left: 2rem !important;
}

.ms-7 {
  margin-left: 2.5rem !important;
}

.ms-8 {
  margin-left: 3rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 5rem !important;
}

.ms-11 {
  margin-left: 6rem !important;
}

.ms-12 {
  margin-left: 8rem !important;
}

.ms-13 {
  margin-left: 10rem !important;
}

.ms-14 {
  margin-left: 12rem !important;
}

.ms-15 {
  margin-left: 16rem !important;
}

.ms-16 {
  margin-left: 25rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.m-n6 {
  margin: -2rem !important;
}

.m-n7 {
  margin: -2.5rem !important;
}

.m-n8 {
  margin: -3rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.m-n11 {
  margin: -6rem !important;
}

.m-n12 {
  margin: -8rem !important;
}

.m-n13 {
  margin: -10rem !important;
}

.m-n14 {
  margin: -12rem !important;
}

.m-n15 {
  margin: -16rem !important;
}

.m-n16 {
  margin: -25rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n6 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n7 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n8 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n11 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n12 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n13 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n14 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.mx-n15 {
  margin-right: -16rem !important;
  margin-left: -16rem !important;
}

.mx-n16 {
  margin-right: -25rem !important;
  margin-left: -25rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n6 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n7 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n8 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n11 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n12 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n13 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n14 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.my-n15 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.my-n16 {
  margin-top: -25rem !important;
  margin-bottom: -25rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.5rem !important;
}

.mt-n6 {
  margin-top: -2rem !important;
}

.mt-n7 {
  margin-top: -2.5rem !important;
}

.mt-n8 {
  margin-top: -3rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -5rem !important;
}

.mt-n11 {
  margin-top: -6rem !important;
}

.mt-n12 {
  margin-top: -8rem !important;
}

.mt-n13 {
  margin-top: -10rem !important;
}

.mt-n14 {
  margin-top: -12rem !important;
}

.mt-n15 {
  margin-top: -16rem !important;
}

.mt-n16 {
  margin-top: -25rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.5rem !important;
}

.me-n6 {
  margin-right: -2rem !important;
}

.me-n7 {
  margin-right: -2.5rem !important;
}

.me-n8 {
  margin-right: -3rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -5rem !important;
}

.me-n11 {
  margin-right: -6rem !important;
}

.me-n12 {
  margin-right: -8rem !important;
}

.me-n13 {
  margin-right: -10rem !important;
}

.me-n14 {
  margin-right: -12rem !important;
}

.me-n15 {
  margin-right: -16rem !important;
}

.me-n16 {
  margin-right: -25rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.5rem !important;
}

.mb-n6 {
  margin-bottom: -2rem !important;
}

.mb-n7 {
  margin-bottom: -2.5rem !important;
}

.mb-n8 {
  margin-bottom: -3rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -5rem !important;
}

.mb-n11 {
  margin-bottom: -6rem !important;
}

.mb-n12 {
  margin-bottom: -8rem !important;
}

.mb-n13 {
  margin-bottom: -10rem !important;
}

.mb-n14 {
  margin-bottom: -12rem !important;
}

.mb-n15 {
  margin-bottom: -16rem !important;
}

.mb-n16 {
  margin-bottom: -25rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.5rem !important;
}

.ms-n6 {
  margin-left: -2rem !important;
}

.ms-n7 {
  margin-left: -2.5rem !important;
}

.ms-n8 {
  margin-left: -3rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -5rem !important;
}

.ms-n11 {
  margin-left: -6rem !important;
}

.ms-n12 {
  margin-left: -8rem !important;
}

.ms-n13 {
  margin-left: -10rem !important;
}

.ms-n14 {
  margin-left: -12rem !important;
}

.ms-n15 {
  margin-left: -16rem !important;
}

.ms-n16 {
  margin-left: -25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.p-7 {
  padding: 2.5rem !important;
}

.p-8 {
  padding: 3rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.p-11 {
  padding: 6rem !important;
}

.p-12 {
  padding: 8rem !important;
}

.p-13 {
  padding: 10rem !important;
}

.p-14 {
  padding: 12rem !important;
}

.p-15 {
  padding: 16rem !important;
}

.p-16 {
  padding: 25rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-6 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-7 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-8 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-11 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-12 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-13 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-14 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-15 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-16 {
  padding-right: 25rem !important;
  padding-left: 25rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-6 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-7 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-8 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-11 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-12 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-13 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-14 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-15 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-16 {
  padding-top: 25rem !important;
  padding-bottom: 25rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pt-6 {
  padding-top: 2rem !important;
}

.pt-7 {
  padding-top: 2.5rem !important;
}

.pt-8 {
  padding-top: 3rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 5rem !important;
}

.pt-11 {
  padding-top: 6rem !important;
}

.pt-12 {
  padding-top: 8rem !important;
}

.pt-13 {
  padding-top: 10rem !important;
}

.pt-14 {
  padding-top: 12rem !important;
}

.pt-15 {
  padding-top: 16rem !important;
}

.pt-16 {
  padding-top: 25rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.5rem !important;
}

.pe-6 {
  padding-right: 2rem !important;
}

.pe-7 {
  padding-right: 2.5rem !important;
}

.pe-8 {
  padding-right: 3rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 5rem !important;
}

.pe-11 {
  padding-right: 6rem !important;
}

.pe-12 {
  padding-right: 8rem !important;
}

.pe-13 {
  padding-right: 10rem !important;
}

.pe-14 {
  padding-right: 12rem !important;
}

.pe-15 {
  padding-right: 16rem !important;
}

.pe-16 {
  padding-right: 25rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 2rem !important;
}

.pb-7 {
  padding-bottom: 2.5rem !important;
}

.pb-8 {
  padding-bottom: 3rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.pb-11 {
  padding-bottom: 6rem !important;
}

.pb-12 {
  padding-bottom: 8rem !important;
}

.pb-13 {
  padding-bottom: 10rem !important;
}

.pb-14 {
  padding-bottom: 12rem !important;
}

.pb-15 {
  padding-bottom: 16rem !important;
}

.pb-16 {
  padding-bottom: 25rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.5rem !important;
}

.ps-6 {
  padding-left: 2rem !important;
}

.ps-7 {
  padding-left: 2.5rem !important;
}

.ps-8 {
  padding-left: 3rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 5rem !important;
}

.ps-11 {
  padding-left: 6rem !important;
}

.ps-12 {
  padding-left: 8rem !important;
}

.ps-13 {
  padding-left: 10rem !important;
}

.ps-14 {
  padding-left: 12rem !important;
}

.ps-15 {
  padding-left: 16rem !important;
}

.ps-16 {
  padding-left: 25rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.5rem !important;
}

.gap-6 {
  gap: 2rem !important;
}

.gap-7 {
  gap: 2.5rem !important;
}

.gap-8 {
  gap: 3rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 5rem !important;
}

.gap-11 {
  gap: 6rem !important;
}

.gap-12 {
  gap: 8rem !important;
}

.gap-13 {
  gap: 10rem !important;
}

.gap-14 {
  gap: 12rem !important;
}

.gap-15 {
  gap: 16rem !important;
}

.gap-16 {
  gap: 25rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.5rem !important;
}

.row-gap-6 {
  row-gap: 2rem !important;
}

.row-gap-7 {
  row-gap: 2.5rem !important;
}

.row-gap-8 {
  row-gap: 3rem !important;
}

.row-gap-9 {
  row-gap: 4rem !important;
}

.row-gap-10 {
  row-gap: 5rem !important;
}

.row-gap-11 {
  row-gap: 6rem !important;
}

.row-gap-12 {
  row-gap: 8rem !important;
}

.row-gap-13 {
  row-gap: 10rem !important;
}

.row-gap-14 {
  row-gap: 12rem !important;
}

.row-gap-15 {
  row-gap: 16rem !important;
}

.row-gap-16 {
  row-gap: 25rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.5rem !important;
}

.column-gap-6 {
  column-gap: 2rem !important;
}

.column-gap-7 {
  column-gap: 2.5rem !important;
}

.column-gap-8 {
  column-gap: 3rem !important;
}

.column-gap-9 {
  column-gap: 4rem !important;
}

.column-gap-10 {
  column-gap: 5rem !important;
}

.column-gap-11 {
  column-gap: 6rem !important;
}

.column-gap-12 {
  column-gap: 8rem !important;
}

.column-gap-13 {
  column-gap: 10rem !important;
}

.column-gap-14 {
  column-gap: 12rem !important;
}

.column-gap-15 {
  column-gap: 16rem !important;
}

.column-gap-16 {
  column-gap: 25rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: 2.3125rem !important;
}

.fs-2 {
  font-size: 1.9375rem !important;
}

.fs-3 {
  font-size: 1.3125rem !important;
}

.fs-4 {
  font-size: 1.1875rem !important;
}

.fs-5 {
  font-size: 1.0625rem !important;
}

.fs-6 {
  font-size: 0.75rem !important;
}

.fs-sm {
  font-size: 0.9375rem !important;
}

.fs-lg {
  font-size: 1.1875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: 100 !important;
}

.fw-light {
  font-weight: 200 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.3 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 1.3 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-desat {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-desat-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(22, 28, 45, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: rgba(134, 154, 184, var(--bs-text-opacity)) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(249, 251, 253, var(--bs-text-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(241, 244, 248, var(--bs-text-opacity)) !important;
}

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(217, 226, 239, var(--bs-text-opacity)) !important;
}

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(198, 211, 230, var(--bs-text-opacity)) !important;
}

.text-gray-500 {
  --bs-text-opacity: 1;
  color: rgba(171, 188, 213, var(--bs-text-opacity)) !important;
}

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(134, 154, 184, var(--bs-text-opacity)) !important;
}

.text-gray-700 {
  --bs-text-opacity: 1;
  color: rgba(80, 102, 144, var(--bs-text-opacity)) !important;
}

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(56, 76, 116, var(--bs-text-opacity)) !important;
}

.text-gray-900 {
  --bs-text-opacity: 1;
  color: rgba(27, 42, 78, var(--bs-text-opacity)) !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-opacity-5 {
  --bs-text-opacity: 0.05;
}

.text-opacity-10 {
  --bs-text-opacity: 0.1;
}

.text-opacity-15 {
  --bs-text-opacity: 0.15;
}

.text-opacity-20 {
  --bs-text-opacity: 0.2;
}

.text-opacity-30 {
  --bs-text-opacity: 0.3;
}

.text-opacity-35 {
  --bs-text-opacity: 0.35;
}

.text-opacity-40 {
  --bs-text-opacity: 0.4;
}

.text-opacity-45 {
  --bs-text-opacity: 0.45;
}

.text-opacity-55 {
  --bs-text-opacity: 0.55;
}

.text-opacity-60 {
  --bs-text-opacity: 0.6;
}

.text-opacity-65 {
  --bs-text-opacity: 0.65;
}

.text-opacity-70 {
  --bs-text-opacity: 0.7;
}

.text-opacity-80 {
  --bs-text-opacity: 0.8;
}

.text-opacity-85 {
  --bs-text-opacity: 0.85;
}

.text-opacity-90 {
  --bs-text-opacity: 0.9;
}

.text-opacity-95 {
  --bs-text-opacity: 0.95;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-desat {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-desat-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-desat {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-desat-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(249, 251, 253, var(--bs-bg-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 244, 248, var(--bs-bg-opacity)) !important;
}

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(217, 226, 239, var(--bs-bg-opacity)) !important;
}

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(198, 211, 230, var(--bs-bg-opacity)) !important;
}

.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(171, 188, 213, var(--bs-bg-opacity)) !important;
}

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 154, 184, var(--bs-bg-opacity)) !important;
}

.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(80, 102, 144, var(--bs-bg-opacity)) !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(56, 76, 116, var(--bs-bg-opacity)) !important;
}

.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(27, 42, 78, var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-10-hover:hover {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-25-hover:hover {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-50-hover:hover {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-75-hover:hover {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-opacity-100-hover:hover {
  --bs-bg-opacity: 1;
}

.bg-opacity-5 {
  --bs-bg-opacity: 0.05;
}

.bg-opacity-5-hover:hover {
  --bs-bg-opacity: 0.05;
}

.bg-opacity-15 {
  --bs-bg-opacity: 0.15;
}

.bg-opacity-15-hover:hover {
  --bs-bg-opacity: 0.15;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-20-hover:hover {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-30 {
  --bs-bg-opacity: 0.3;
}

.bg-opacity-30-hover:hover {
  --bs-bg-opacity: 0.3;
}

.bg-opacity-35 {
  --bs-bg-opacity: 0.35;
}

.bg-opacity-35-hover:hover {
  --bs-bg-opacity: 0.35;
}

.bg-opacity-40 {
  --bs-bg-opacity: 0.4;
}

.bg-opacity-40-hover:hover {
  --bs-bg-opacity: 0.4;
}

.bg-opacity-45 {
  --bs-bg-opacity: 0.45;
}

.bg-opacity-45-hover:hover {
  --bs-bg-opacity: 0.45;
}

.bg-opacity-55 {
  --bs-bg-opacity: 0.55;
}

.bg-opacity-55-hover:hover {
  --bs-bg-opacity: 0.55;
}

.bg-opacity-60 {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-60-hover:hover {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-65 {
  --bs-bg-opacity: 0.65;
}

.bg-opacity-65-hover:hover {
  --bs-bg-opacity: 0.65;
}

.bg-opacity-70 {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-70-hover:hover {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-80 {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-80-hover:hover {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-85 {
  --bs-bg-opacity: 0.85;
}

.bg-opacity-85-hover:hover {
  --bs-bg-opacity: 0.85;
}

.bg-opacity-90 {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-90-hover:hover {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-95 {
  --bs-bg-opacity: 0.95;
}

.bg-opacity-95-hover:hover {
  --bs-bg-opacity: 0.95;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-primary-desat-subtle {
  background-color: var(--bs-primary-desat-bg-subtle) !important;
}

.bg-white-subtle {
  background-color: var(--bs-white-bg-subtle) !important;
}

.bg-gray-700-subtle {
  background-color: var(--bs-gray-700-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.letter-spacing-lg {
  letter-spacing: 0.08em !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .border-sm {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-end-sm {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-start-sm {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-110 {
    width: 110% !important;
  }
  .w-sm-120 {
    width: 120% !important;
  }
  .w-sm-130 {
    width: 130% !important;
  }
  .w-sm-140 {
    width: 140% !important;
  }
  .w-sm-150 {
    width: 150% !important;
  }
  .mw-sm-100 {
    max-width: 100% !important;
  }
  .mw-sm-25 {
    max-width: 25% !important;
  }
  .mw-sm-50 {
    max-width: 50% !important;
  }
  .mw-sm-75 {
    max-width: 75% !important;
  }
  .mw-sm-110 {
    max-width: 110% !important;
  }
  .mw-sm-120 {
    max-width: 120% !important;
  }
  .mw-sm-130 {
    max-width: 130% !important;
  }
  .mw-sm-140 {
    max-width: 140% !important;
  }
  .mw-sm-150 {
    max-width: 150% !important;
  }
  .vw-sm-100 {
    width: 100vw !important;
  }
  .vw-sm-25 {
    width: 25vw !important;
  }
  .vw-sm-50 {
    width: 50vw !important;
  }
  .vw-sm-75 {
    width: 75vw !important;
  }
  .vw-sm-110 {
    width: 110vw !important;
  }
  .vw-sm-120 {
    width: 120vw !important;
  }
  .vw-sm-130 {
    width: 130vw !important;
  }
  .vw-sm-140 {
    width: 140vw !important;
  }
  .vw-sm-150 {
    width: 150vw !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .h-sm-110 {
    height: 110% !important;
  }
  .h-sm-120 {
    height: 120% !important;
  }
  .h-sm-130 {
    height: 130% !important;
  }
  .h-sm-140 {
    height: 140% !important;
  }
  .h-sm-150 {
    height: 150% !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .vh-sm-25 {
    height: 25vw !important;
  }
  .vh-sm-50 {
    height: 50vw !important;
  }
  .vh-sm-75 {
    height: 75vw !important;
  }
  .vh-sm-110 {
    height: 110vw !important;
  }
  .vh-sm-120 {
    height: 120vw !important;
  }
  .vh-sm-130 {
    height: 130vw !important;
  }
  .vh-sm-140 {
    height: 140vw !important;
  }
  .vh-sm-150 {
    height: 150vw !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.5rem !important;
  }
  .m-sm-6 {
    margin: 2rem !important;
  }
  .m-sm-7 {
    margin: 2.5rem !important;
  }
  .m-sm-8 {
    margin: 3rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .m-sm-10 {
    margin: 5rem !important;
  }
  .m-sm-11 {
    margin: 6rem !important;
  }
  .m-sm-12 {
    margin: 8rem !important;
  }
  .m-sm-13 {
    margin: 10rem !important;
  }
  .m-sm-14 {
    margin: 12rem !important;
  }
  .m-sm-15 {
    margin: 16rem !important;
  }
  .m-sm-16 {
    margin: 25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-7 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-8 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-12 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-13 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-14 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-15 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-sm-16 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-7 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-8 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-12 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-13 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-14 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-15 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-sm-16 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-6 {
    margin-top: 2rem !important;
  }
  .mt-sm-7 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-8 {
    margin-top: 3rem !important;
  }
  .mt-sm-9 {
    margin-top: 4rem !important;
  }
  .mt-sm-10 {
    margin-top: 5rem !important;
  }
  .mt-sm-11 {
    margin-top: 6rem !important;
  }
  .mt-sm-12 {
    margin-top: 8rem !important;
  }
  .mt-sm-13 {
    margin-top: 10rem !important;
  }
  .mt-sm-14 {
    margin-top: 12rem !important;
  }
  .mt-sm-15 {
    margin-top: 16rem !important;
  }
  .mt-sm-16 {
    margin-top: 25rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1rem !important;
  }
  .me-sm-5 {
    margin-right: 1.5rem !important;
  }
  .me-sm-6 {
    margin-right: 2rem !important;
  }
  .me-sm-7 {
    margin-right: 2.5rem !important;
  }
  .me-sm-8 {
    margin-right: 3rem !important;
  }
  .me-sm-9 {
    margin-right: 4rem !important;
  }
  .me-sm-10 {
    margin-right: 5rem !important;
  }
  .me-sm-11 {
    margin-right: 6rem !important;
  }
  .me-sm-12 {
    margin-right: 8rem !important;
  }
  .me-sm-13 {
    margin-right: 10rem !important;
  }
  .me-sm-14 {
    margin-right: 12rem !important;
  }
  .me-sm-15 {
    margin-right: 16rem !important;
  }
  .me-sm-16 {
    margin-right: 25rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 16rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 25rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-6 {
    margin-left: 2rem !important;
  }
  .ms-sm-7 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-8 {
    margin-left: 3rem !important;
  }
  .ms-sm-9 {
    margin-left: 4rem !important;
  }
  .ms-sm-10 {
    margin-left: 5rem !important;
  }
  .ms-sm-11 {
    margin-left: 6rem !important;
  }
  .ms-sm-12 {
    margin-left: 8rem !important;
  }
  .ms-sm-13 {
    margin-left: 10rem !important;
  }
  .ms-sm-14 {
    margin-left: 12rem !important;
  }
  .ms-sm-15 {
    margin-left: 16rem !important;
  }
  .ms-sm-16 {
    margin-left: 25rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.5rem !important;
  }
  .m-sm-n6 {
    margin: -2rem !important;
  }
  .m-sm-n7 {
    margin: -2.5rem !important;
  }
  .m-sm-n8 {
    margin: -3rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .m-sm-n10 {
    margin: -5rem !important;
  }
  .m-sm-n11 {
    margin: -6rem !important;
  }
  .m-sm-n12 {
    margin: -8rem !important;
  }
  .m-sm-n13 {
    margin: -10rem !important;
  }
  .m-sm-n14 {
    margin: -12rem !important;
  }
  .m-sm-n15 {
    margin: -16rem !important;
  }
  .m-sm-n16 {
    margin: -25rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n6 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n8 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n12 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-sm-n13 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-sm-n14 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-sm-n15 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-sm-n16 {
    margin-right: -25rem !important;
    margin-left: -25rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n6 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n8 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n12 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-sm-n13 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-sm-n14 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-sm-n15 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-sm-n16 {
    margin-top: -25rem !important;
    margin-bottom: -25rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n6 {
    margin-top: -2rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n8 {
    margin-top: -3rem !important;
  }
  .mt-sm-n9 {
    margin-top: -4rem !important;
  }
  .mt-sm-n10 {
    margin-top: -5rem !important;
  }
  .mt-sm-n11 {
    margin-top: -6rem !important;
  }
  .mt-sm-n12 {
    margin-top: -8rem !important;
  }
  .mt-sm-n13 {
    margin-top: -10rem !important;
  }
  .mt-sm-n14 {
    margin-top: -12rem !important;
  }
  .mt-sm-n15 {
    margin-top: -16rem !important;
  }
  .mt-sm-n16 {
    margin-top: -25rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n4 {
    margin-right: -1rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n6 {
    margin-right: -2rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n8 {
    margin-right: -3rem !important;
  }
  .me-sm-n9 {
    margin-right: -4rem !important;
  }
  .me-sm-n10 {
    margin-right: -5rem !important;
  }
  .me-sm-n11 {
    margin-right: -6rem !important;
  }
  .me-sm-n12 {
    margin-right: -8rem !important;
  }
  .me-sm-n13 {
    margin-right: -10rem !important;
  }
  .me-sm-n14 {
    margin-right: -12rem !important;
  }
  .me-sm-n15 {
    margin-right: -16rem !important;
  }
  .me-sm-n16 {
    margin-right: -25rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -8rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -12rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -16rem !important;
  }
  .mb-sm-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n6 {
    margin-left: -2rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n8 {
    margin-left: -3rem !important;
  }
  .ms-sm-n9 {
    margin-left: -4rem !important;
  }
  .ms-sm-n10 {
    margin-left: -5rem !important;
  }
  .ms-sm-n11 {
    margin-left: -6rem !important;
  }
  .ms-sm-n12 {
    margin-left: -8rem !important;
  }
  .ms-sm-n13 {
    margin-left: -10rem !important;
  }
  .ms-sm-n14 {
    margin-left: -12rem !important;
  }
  .ms-sm-n15 {
    margin-left: -16rem !important;
  }
  .ms-sm-n16 {
    margin-left: -25rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.5rem !important;
  }
  .p-sm-6 {
    padding: 2rem !important;
  }
  .p-sm-7 {
    padding: 2.5rem !important;
  }
  .p-sm-8 {
    padding: 3rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .p-sm-10 {
    padding: 5rem !important;
  }
  .p-sm-11 {
    padding: 6rem !important;
  }
  .p-sm-12 {
    padding: 8rem !important;
  }
  .p-sm-13 {
    padding: 10rem !important;
  }
  .p-sm-14 {
    padding: 12rem !important;
  }
  .p-sm-15 {
    padding: 16rem !important;
  }
  .p-sm-16 {
    padding: 25rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-7 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-8 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-12 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-13 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-14 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-sm-15 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-sm-16 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-7 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-8 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-12 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-13 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-14 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-sm-15 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-sm-16 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-6 {
    padding-top: 2rem !important;
  }
  .pt-sm-7 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-8 {
    padding-top: 3rem !important;
  }
  .pt-sm-9 {
    padding-top: 4rem !important;
  }
  .pt-sm-10 {
    padding-top: 5rem !important;
  }
  .pt-sm-11 {
    padding-top: 6rem !important;
  }
  .pt-sm-12 {
    padding-top: 8rem !important;
  }
  .pt-sm-13 {
    padding-top: 10rem !important;
  }
  .pt-sm-14 {
    padding-top: 12rem !important;
  }
  .pt-sm-15 {
    padding-top: 16rem !important;
  }
  .pt-sm-16 {
    padding-top: 25rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-6 {
    padding-right: 2rem !important;
  }
  .pe-sm-7 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-8 {
    padding-right: 3rem !important;
  }
  .pe-sm-9 {
    padding-right: 4rem !important;
  }
  .pe-sm-10 {
    padding-right: 5rem !important;
  }
  .pe-sm-11 {
    padding-right: 6rem !important;
  }
  .pe-sm-12 {
    padding-right: 8rem !important;
  }
  .pe-sm-13 {
    padding-right: 10rem !important;
  }
  .pe-sm-14 {
    padding-right: 12rem !important;
  }
  .pe-sm-15 {
    padding-right: 16rem !important;
  }
  .pe-sm-16 {
    padding-right: 25rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 12rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 16rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 25rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-6 {
    padding-left: 2rem !important;
  }
  .ps-sm-7 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-8 {
    padding-left: 3rem !important;
  }
  .ps-sm-9 {
    padding-left: 4rem !important;
  }
  .ps-sm-10 {
    padding-left: 5rem !important;
  }
  .ps-sm-11 {
    padding-left: 6rem !important;
  }
  .ps-sm-12 {
    padding-left: 8rem !important;
  }
  .ps-sm-13 {
    padding-left: 10rem !important;
  }
  .ps-sm-14 {
    padding-left: 12rem !important;
  }
  .ps-sm-15 {
    padding-left: 16rem !important;
  }
  .ps-sm-16 {
    padding-left: 25rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.5rem !important;
  }
  .gap-sm-6 {
    gap: 2rem !important;
  }
  .gap-sm-7 {
    gap: 2.5rem !important;
  }
  .gap-sm-8 {
    gap: 3rem !important;
  }
  .gap-sm-9 {
    gap: 4rem !important;
  }
  .gap-sm-10 {
    gap: 5rem !important;
  }
  .gap-sm-11 {
    gap: 6rem !important;
  }
  .gap-sm-12 {
    gap: 8rem !important;
  }
  .gap-sm-13 {
    gap: 10rem !important;
  }
  .gap-sm-14 {
    gap: 12rem !important;
  }
  .gap-sm-15 {
    gap: 16rem !important;
  }
  .gap-sm-16 {
    gap: 25rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-11 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-12 {
    row-gap: 8rem !important;
  }
  .row-gap-sm-13 {
    row-gap: 10rem !important;
  }
  .row-gap-sm-14 {
    row-gap: 12rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 16rem !important;
  }
  .row-gap-sm-16 {
    row-gap: 25rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 2rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-11 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-12 {
    column-gap: 8rem !important;
  }
  .column-gap-sm-13 {
    column-gap: 10rem !important;
  }
  .column-gap-sm-14 {
    column-gap: 12rem !important;
  }
  .column-gap-sm-15 {
    column-gap: 16rem !important;
  }
  .column-gap-sm-16 {
    column-gap: 25rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .border-md {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-end-md {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-start-md {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-110 {
    width: 110% !important;
  }
  .w-md-120 {
    width: 120% !important;
  }
  .w-md-130 {
    width: 130% !important;
  }
  .w-md-140 {
    width: 140% !important;
  }
  .w-md-150 {
    width: 150% !important;
  }
  .mw-md-100 {
    max-width: 100% !important;
  }
  .mw-md-25 {
    max-width: 25% !important;
  }
  .mw-md-50 {
    max-width: 50% !important;
  }
  .mw-md-75 {
    max-width: 75% !important;
  }
  .mw-md-110 {
    max-width: 110% !important;
  }
  .mw-md-120 {
    max-width: 120% !important;
  }
  .mw-md-130 {
    max-width: 130% !important;
  }
  .mw-md-140 {
    max-width: 140% !important;
  }
  .mw-md-150 {
    max-width: 150% !important;
  }
  .vw-md-100 {
    width: 100vw !important;
  }
  .vw-md-25 {
    width: 25vw !important;
  }
  .vw-md-50 {
    width: 50vw !important;
  }
  .vw-md-75 {
    width: 75vw !important;
  }
  .vw-md-110 {
    width: 110vw !important;
  }
  .vw-md-120 {
    width: 120vw !important;
  }
  .vw-md-130 {
    width: 130vw !important;
  }
  .vw-md-140 {
    width: 140vw !important;
  }
  .vw-md-150 {
    width: 150vw !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .h-md-110 {
    height: 110% !important;
  }
  .h-md-120 {
    height: 120% !important;
  }
  .h-md-130 {
    height: 130% !important;
  }
  .h-md-140 {
    height: 140% !important;
  }
  .h-md-150 {
    height: 150% !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .vh-md-25 {
    height: 25vw !important;
  }
  .vh-md-50 {
    height: 50vw !important;
  }
  .vh-md-75 {
    height: 75vw !important;
  }
  .vh-md-110 {
    height: 110vw !important;
  }
  .vh-md-120 {
    height: 120vw !important;
  }
  .vh-md-130 {
    height: 130vw !important;
  }
  .vh-md-140 {
    height: 140vw !important;
  }
  .vh-md-150 {
    height: 150vw !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.5rem !important;
  }
  .m-md-6 {
    margin: 2rem !important;
  }
  .m-md-7 {
    margin: 2.5rem !important;
  }
  .m-md-8 {
    margin: 3rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .m-md-10 {
    margin: 5rem !important;
  }
  .m-md-11 {
    margin: 6rem !important;
  }
  .m-md-12 {
    margin: 8rem !important;
  }
  .m-md-13 {
    margin: 10rem !important;
  }
  .m-md-14 {
    margin: 12rem !important;
  }
  .m-md-15 {
    margin: 16rem !important;
  }
  .m-md-16 {
    margin: 25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-7 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-8 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-12 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-13 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-14 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-15 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-md-16 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-7 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-8 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-12 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-13 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-14 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-15 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-md-16 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 1.5rem !important;
  }
  .mt-md-6 {
    margin-top: 2rem !important;
  }
  .mt-md-7 {
    margin-top: 2.5rem !important;
  }
  .mt-md-8 {
    margin-top: 3rem !important;
  }
  .mt-md-9 {
    margin-top: 4rem !important;
  }
  .mt-md-10 {
    margin-top: 5rem !important;
  }
  .mt-md-11 {
    margin-top: 6rem !important;
  }
  .mt-md-12 {
    margin-top: 8rem !important;
  }
  .mt-md-13 {
    margin-top: 10rem !important;
  }
  .mt-md-14 {
    margin-top: 12rem !important;
  }
  .mt-md-15 {
    margin-top: 16rem !important;
  }
  .mt-md-16 {
    margin-top: 25rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1rem !important;
  }
  .me-md-5 {
    margin-right: 1.5rem !important;
  }
  .me-md-6 {
    margin-right: 2rem !important;
  }
  .me-md-7 {
    margin-right: 2.5rem !important;
  }
  .me-md-8 {
    margin-right: 3rem !important;
  }
  .me-md-9 {
    margin-right: 4rem !important;
  }
  .me-md-10 {
    margin-right: 5rem !important;
  }
  .me-md-11 {
    margin-right: 6rem !important;
  }
  .me-md-12 {
    margin-right: 8rem !important;
  }
  .me-md-13 {
    margin-right: 10rem !important;
  }
  .me-md-14 {
    margin-right: 12rem !important;
  }
  .me-md-15 {
    margin-right: 16rem !important;
  }
  .me-md-16 {
    margin-right: 25rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2rem !important;
  }
  .mb-md-7 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 3rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4rem !important;
  }
  .mb-md-10 {
    margin-bottom: 5rem !important;
  }
  .mb-md-11 {
    margin-bottom: 6rem !important;
  }
  .mb-md-12 {
    margin-bottom: 8rem !important;
  }
  .mb-md-13 {
    margin-bottom: 10rem !important;
  }
  .mb-md-14 {
    margin-bottom: 12rem !important;
  }
  .mb-md-15 {
    margin-bottom: 16rem !important;
  }
  .mb-md-16 {
    margin-bottom: 25rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1rem !important;
  }
  .ms-md-5 {
    margin-left: 1.5rem !important;
  }
  .ms-md-6 {
    margin-left: 2rem !important;
  }
  .ms-md-7 {
    margin-left: 2.5rem !important;
  }
  .ms-md-8 {
    margin-left: 3rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 5rem !important;
  }
  .ms-md-11 {
    margin-left: 6rem !important;
  }
  .ms-md-12 {
    margin-left: 8rem !important;
  }
  .ms-md-13 {
    margin-left: 10rem !important;
  }
  .ms-md-14 {
    margin-left: 12rem !important;
  }
  .ms-md-15 {
    margin-left: 16rem !important;
  }
  .ms-md-16 {
    margin-left: 25rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.5rem !important;
  }
  .m-md-n6 {
    margin: -2rem !important;
  }
  .m-md-n7 {
    margin: -2.5rem !important;
  }
  .m-md-n8 {
    margin: -3rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .m-md-n10 {
    margin: -5rem !important;
  }
  .m-md-n11 {
    margin: -6rem !important;
  }
  .m-md-n12 {
    margin: -8rem !important;
  }
  .m-md-n13 {
    margin: -10rem !important;
  }
  .m-md-n14 {
    margin: -12rem !important;
  }
  .m-md-n15 {
    margin: -16rem !important;
  }
  .m-md-n16 {
    margin: -25rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n6 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n8 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n12 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-md-n13 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-md-n14 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-md-n15 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-md-n16 {
    margin-right: -25rem !important;
    margin-left: -25rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n6 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n7 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n8 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n12 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-md-n13 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-md-n14 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-md-n15 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-md-n16 {
    margin-top: -25rem !important;
    margin-bottom: -25rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n4 {
    margin-top: -1rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n6 {
    margin-top: -2rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n8 {
    margin-top: -3rem !important;
  }
  .mt-md-n9 {
    margin-top: -4rem !important;
  }
  .mt-md-n10 {
    margin-top: -5rem !important;
  }
  .mt-md-n11 {
    margin-top: -6rem !important;
  }
  .mt-md-n12 {
    margin-top: -8rem !important;
  }
  .mt-md-n13 {
    margin-top: -10rem !important;
  }
  .mt-md-n14 {
    margin-top: -12rem !important;
  }
  .mt-md-n15 {
    margin-top: -16rem !important;
  }
  .mt-md-n16 {
    margin-top: -25rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -0.75rem !important;
  }
  .me-md-n4 {
    margin-right: -1rem !important;
  }
  .me-md-n5 {
    margin-right: -1.5rem !important;
  }
  .me-md-n6 {
    margin-right: -2rem !important;
  }
  .me-md-n7 {
    margin-right: -2.5rem !important;
  }
  .me-md-n8 {
    margin-right: -3rem !important;
  }
  .me-md-n9 {
    margin-right: -4rem !important;
  }
  .me-md-n10 {
    margin-right: -5rem !important;
  }
  .me-md-n11 {
    margin-right: -6rem !important;
  }
  .me-md-n12 {
    margin-right: -8rem !important;
  }
  .me-md-n13 {
    margin-right: -10rem !important;
  }
  .me-md-n14 {
    margin-right: -12rem !important;
  }
  .me-md-n15 {
    margin-right: -16rem !important;
  }
  .me-md-n16 {
    margin-right: -25rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -8rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -12rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -16rem !important;
  }
  .mb-md-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n4 {
    margin-left: -1rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n6 {
    margin-left: -2rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n8 {
    margin-left: -3rem !important;
  }
  .ms-md-n9 {
    margin-left: -4rem !important;
  }
  .ms-md-n10 {
    margin-left: -5rem !important;
  }
  .ms-md-n11 {
    margin-left: -6rem !important;
  }
  .ms-md-n12 {
    margin-left: -8rem !important;
  }
  .ms-md-n13 {
    margin-left: -10rem !important;
  }
  .ms-md-n14 {
    margin-left: -12rem !important;
  }
  .ms-md-n15 {
    margin-left: -16rem !important;
  }
  .ms-md-n16 {
    margin-left: -25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.5rem !important;
  }
  .p-md-6 {
    padding: 2rem !important;
  }
  .p-md-7 {
    padding: 2.5rem !important;
  }
  .p-md-8 {
    padding: 3rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .p-md-10 {
    padding: 5rem !important;
  }
  .p-md-11 {
    padding: 6rem !important;
  }
  .p-md-12 {
    padding: 8rem !important;
  }
  .p-md-13 {
    padding: 10rem !important;
  }
  .p-md-14 {
    padding: 12rem !important;
  }
  .p-md-15 {
    padding: 16rem !important;
  }
  .p-md-16 {
    padding: 25rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-7 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-8 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-12 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-13 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-14 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-md-15 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-md-16 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-7 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-8 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-12 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-13 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-14 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-md-15 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-md-16 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1rem !important;
  }
  .pt-md-5 {
    padding-top: 1.5rem !important;
  }
  .pt-md-6 {
    padding-top: 2rem !important;
  }
  .pt-md-7 {
    padding-top: 2.5rem !important;
  }
  .pt-md-8 {
    padding-top: 3rem !important;
  }
  .pt-md-9 {
    padding-top: 4rem !important;
  }
  .pt-md-10 {
    padding-top: 5rem !important;
  }
  .pt-md-11 {
    padding-top: 6rem !important;
  }
  .pt-md-12 {
    padding-top: 8rem !important;
  }
  .pt-md-13 {
    padding-top: 10rem !important;
  }
  .pt-md-14 {
    padding-top: 12rem !important;
  }
  .pt-md-15 {
    padding-top: 16rem !important;
  }
  .pt-md-16 {
    padding-top: 25rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1rem !important;
  }
  .pe-md-5 {
    padding-right: 1.5rem !important;
  }
  .pe-md-6 {
    padding-right: 2rem !important;
  }
  .pe-md-7 {
    padding-right: 2.5rem !important;
  }
  .pe-md-8 {
    padding-right: 3rem !important;
  }
  .pe-md-9 {
    padding-right: 4rem !important;
  }
  .pe-md-10 {
    padding-right: 5rem !important;
  }
  .pe-md-11 {
    padding-right: 6rem !important;
  }
  .pe-md-12 {
    padding-right: 8rem !important;
  }
  .pe-md-13 {
    padding-right: 10rem !important;
  }
  .pe-md-14 {
    padding-right: 12rem !important;
  }
  .pe-md-15 {
    padding-right: 16rem !important;
  }
  .pe-md-16 {
    padding-right: 25rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2rem !important;
  }
  .pb-md-7 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 3rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4rem !important;
  }
  .pb-md-10 {
    padding-bottom: 5rem !important;
  }
  .pb-md-11 {
    padding-bottom: 6rem !important;
  }
  .pb-md-12 {
    padding-bottom: 8rem !important;
  }
  .pb-md-13 {
    padding-bottom: 10rem !important;
  }
  .pb-md-14 {
    padding-bottom: 12rem !important;
  }
  .pb-md-15 {
    padding-bottom: 16rem !important;
  }
  .pb-md-16 {
    padding-bottom: 25rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1rem !important;
  }
  .ps-md-5 {
    padding-left: 1.5rem !important;
  }
  .ps-md-6 {
    padding-left: 2rem !important;
  }
  .ps-md-7 {
    padding-left: 2.5rem !important;
  }
  .ps-md-8 {
    padding-left: 3rem !important;
  }
  .ps-md-9 {
    padding-left: 4rem !important;
  }
  .ps-md-10 {
    padding-left: 5rem !important;
  }
  .ps-md-11 {
    padding-left: 6rem !important;
  }
  .ps-md-12 {
    padding-left: 8rem !important;
  }
  .ps-md-13 {
    padding-left: 10rem !important;
  }
  .ps-md-14 {
    padding-left: 12rem !important;
  }
  .ps-md-15 {
    padding-left: 16rem !important;
  }
  .ps-md-16 {
    padding-left: 25rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.5rem !important;
  }
  .gap-md-6 {
    gap: 2rem !important;
  }
  .gap-md-7 {
    gap: 2.5rem !important;
  }
  .gap-md-8 {
    gap: 3rem !important;
  }
  .gap-md-9 {
    gap: 4rem !important;
  }
  .gap-md-10 {
    gap: 5rem !important;
  }
  .gap-md-11 {
    gap: 6rem !important;
  }
  .gap-md-12 {
    gap: 8rem !important;
  }
  .gap-md-13 {
    gap: 10rem !important;
  }
  .gap-md-14 {
    gap: 12rem !important;
  }
  .gap-md-15 {
    gap: 16rem !important;
  }
  .gap-md-16 {
    gap: 25rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1rem !important;
  }
  .row-gap-md-5 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-6 {
    row-gap: 2rem !important;
  }
  .row-gap-md-7 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-8 {
    row-gap: 3rem !important;
  }
  .row-gap-md-9 {
    row-gap: 4rem !important;
  }
  .row-gap-md-10 {
    row-gap: 5rem !important;
  }
  .row-gap-md-11 {
    row-gap: 6rem !important;
  }
  .row-gap-md-12 {
    row-gap: 8rem !important;
  }
  .row-gap-md-13 {
    row-gap: 10rem !important;
  }
  .row-gap-md-14 {
    row-gap: 12rem !important;
  }
  .row-gap-md-15 {
    row-gap: 16rem !important;
  }
  .row-gap-md-16 {
    row-gap: 25rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1rem !important;
  }
  .column-gap-md-5 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-6 {
    column-gap: 2rem !important;
  }
  .column-gap-md-7 {
    column-gap: 2.5rem !important;
  }
  .column-gap-md-8 {
    column-gap: 3rem !important;
  }
  .column-gap-md-9 {
    column-gap: 4rem !important;
  }
  .column-gap-md-10 {
    column-gap: 5rem !important;
  }
  .column-gap-md-11 {
    column-gap: 6rem !important;
  }
  .column-gap-md-12 {
    column-gap: 8rem !important;
  }
  .column-gap-md-13 {
    column-gap: 10rem !important;
  }
  .column-gap-md-14 {
    column-gap: 12rem !important;
  }
  .column-gap-md-15 {
    column-gap: 16rem !important;
  }
  .column-gap-md-16 {
    column-gap: 25rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .border-lg {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-end-lg {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-start-lg {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-110 {
    width: 110% !important;
  }
  .w-lg-120 {
    width: 120% !important;
  }
  .w-lg-130 {
    width: 130% !important;
  }
  .w-lg-140 {
    width: 140% !important;
  }
  .w-lg-150 {
    width: 150% !important;
  }
  .mw-lg-100 {
    max-width: 100% !important;
  }
  .mw-lg-25 {
    max-width: 25% !important;
  }
  .mw-lg-50 {
    max-width: 50% !important;
  }
  .mw-lg-75 {
    max-width: 75% !important;
  }
  .mw-lg-110 {
    max-width: 110% !important;
  }
  .mw-lg-120 {
    max-width: 120% !important;
  }
  .mw-lg-130 {
    max-width: 130% !important;
  }
  .mw-lg-140 {
    max-width: 140% !important;
  }
  .mw-lg-150 {
    max-width: 150% !important;
  }
  .vw-lg-100 {
    width: 100vw !important;
  }
  .vw-lg-25 {
    width: 25vw !important;
  }
  .vw-lg-50 {
    width: 50vw !important;
  }
  .vw-lg-75 {
    width: 75vw !important;
  }
  .vw-lg-110 {
    width: 110vw !important;
  }
  .vw-lg-120 {
    width: 120vw !important;
  }
  .vw-lg-130 {
    width: 130vw !important;
  }
  .vw-lg-140 {
    width: 140vw !important;
  }
  .vw-lg-150 {
    width: 150vw !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .h-lg-110 {
    height: 110% !important;
  }
  .h-lg-120 {
    height: 120% !important;
  }
  .h-lg-130 {
    height: 130% !important;
  }
  .h-lg-140 {
    height: 140% !important;
  }
  .h-lg-150 {
    height: 150% !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .vh-lg-25 {
    height: 25vw !important;
  }
  .vh-lg-50 {
    height: 50vw !important;
  }
  .vh-lg-75 {
    height: 75vw !important;
  }
  .vh-lg-110 {
    height: 110vw !important;
  }
  .vh-lg-120 {
    height: 120vw !important;
  }
  .vh-lg-130 {
    height: 130vw !important;
  }
  .vh-lg-140 {
    height: 140vw !important;
  }
  .vh-lg-150 {
    height: 150vw !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.5rem !important;
  }
  .m-lg-6 {
    margin: 2rem !important;
  }
  .m-lg-7 {
    margin: 2.5rem !important;
  }
  .m-lg-8 {
    margin: 3rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .m-lg-10 {
    margin: 5rem !important;
  }
  .m-lg-11 {
    margin: 6rem !important;
  }
  .m-lg-12 {
    margin: 8rem !important;
  }
  .m-lg-13 {
    margin: 10rem !important;
  }
  .m-lg-14 {
    margin: 12rem !important;
  }
  .m-lg-15 {
    margin: 16rem !important;
  }
  .m-lg-16 {
    margin: 25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-7 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-8 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-12 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-13 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-14 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-15 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-lg-16 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-7 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-8 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-12 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-13 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-14 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-15 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-lg-16 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-6 {
    margin-top: 2rem !important;
  }
  .mt-lg-7 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-8 {
    margin-top: 3rem !important;
  }
  .mt-lg-9 {
    margin-top: 4rem !important;
  }
  .mt-lg-10 {
    margin-top: 5rem !important;
  }
  .mt-lg-11 {
    margin-top: 6rem !important;
  }
  .mt-lg-12 {
    margin-top: 8rem !important;
  }
  .mt-lg-13 {
    margin-top: 10rem !important;
  }
  .mt-lg-14 {
    margin-top: 12rem !important;
  }
  .mt-lg-15 {
    margin-top: 16rem !important;
  }
  .mt-lg-16 {
    margin-top: 25rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1rem !important;
  }
  .me-lg-5 {
    margin-right: 1.5rem !important;
  }
  .me-lg-6 {
    margin-right: 2rem !important;
  }
  .me-lg-7 {
    margin-right: 2.5rem !important;
  }
  .me-lg-8 {
    margin-right: 3rem !important;
  }
  .me-lg-9 {
    margin-right: 4rem !important;
  }
  .me-lg-10 {
    margin-right: 5rem !important;
  }
  .me-lg-11 {
    margin-right: 6rem !important;
  }
  .me-lg-12 {
    margin-right: 8rem !important;
  }
  .me-lg-13 {
    margin-right: 10rem !important;
  }
  .me-lg-14 {
    margin-right: 12rem !important;
  }
  .me-lg-15 {
    margin-right: 16rem !important;
  }
  .me-lg-16 {
    margin-right: 25rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 16rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 25rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-6 {
    margin-left: 2rem !important;
  }
  .ms-lg-7 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-8 {
    margin-left: 3rem !important;
  }
  .ms-lg-9 {
    margin-left: 4rem !important;
  }
  .ms-lg-10 {
    margin-left: 5rem !important;
  }
  .ms-lg-11 {
    margin-left: 6rem !important;
  }
  .ms-lg-12 {
    margin-left: 8rem !important;
  }
  .ms-lg-13 {
    margin-left: 10rem !important;
  }
  .ms-lg-14 {
    margin-left: 12rem !important;
  }
  .ms-lg-15 {
    margin-left: 16rem !important;
  }
  .ms-lg-16 {
    margin-left: 25rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.5rem !important;
  }
  .m-lg-n6 {
    margin: -2rem !important;
  }
  .m-lg-n7 {
    margin: -2.5rem !important;
  }
  .m-lg-n8 {
    margin: -3rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .m-lg-n10 {
    margin: -5rem !important;
  }
  .m-lg-n11 {
    margin: -6rem !important;
  }
  .m-lg-n12 {
    margin: -8rem !important;
  }
  .m-lg-n13 {
    margin: -10rem !important;
  }
  .m-lg-n14 {
    margin: -12rem !important;
  }
  .m-lg-n15 {
    margin: -16rem !important;
  }
  .m-lg-n16 {
    margin: -25rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n6 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n8 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n12 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-lg-n13 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-lg-n14 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-lg-n15 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-lg-n16 {
    margin-right: -25rem !important;
    margin-left: -25rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n6 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n8 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n12 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-lg-n13 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-lg-n14 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-lg-n15 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-lg-n16 {
    margin-top: -25rem !important;
    margin-bottom: -25rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n6 {
    margin-top: -2rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n8 {
    margin-top: -3rem !important;
  }
  .mt-lg-n9 {
    margin-top: -4rem !important;
  }
  .mt-lg-n10 {
    margin-top: -5rem !important;
  }
  .mt-lg-n11 {
    margin-top: -6rem !important;
  }
  .mt-lg-n12 {
    margin-top: -8rem !important;
  }
  .mt-lg-n13 {
    margin-top: -10rem !important;
  }
  .mt-lg-n14 {
    margin-top: -12rem !important;
  }
  .mt-lg-n15 {
    margin-top: -16rem !important;
  }
  .mt-lg-n16 {
    margin-top: -25rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n4 {
    margin-right: -1rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n6 {
    margin-right: -2rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n8 {
    margin-right: -3rem !important;
  }
  .me-lg-n9 {
    margin-right: -4rem !important;
  }
  .me-lg-n10 {
    margin-right: -5rem !important;
  }
  .me-lg-n11 {
    margin-right: -6rem !important;
  }
  .me-lg-n12 {
    margin-right: -8rem !important;
  }
  .me-lg-n13 {
    margin-right: -10rem !important;
  }
  .me-lg-n14 {
    margin-right: -12rem !important;
  }
  .me-lg-n15 {
    margin-right: -16rem !important;
  }
  .me-lg-n16 {
    margin-right: -25rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -8rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -12rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -16rem !important;
  }
  .mb-lg-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n6 {
    margin-left: -2rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n8 {
    margin-left: -3rem !important;
  }
  .ms-lg-n9 {
    margin-left: -4rem !important;
  }
  .ms-lg-n10 {
    margin-left: -5rem !important;
  }
  .ms-lg-n11 {
    margin-left: -6rem !important;
  }
  .ms-lg-n12 {
    margin-left: -8rem !important;
  }
  .ms-lg-n13 {
    margin-left: -10rem !important;
  }
  .ms-lg-n14 {
    margin-left: -12rem !important;
  }
  .ms-lg-n15 {
    margin-left: -16rem !important;
  }
  .ms-lg-n16 {
    margin-left: -25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.5rem !important;
  }
  .p-lg-6 {
    padding: 2rem !important;
  }
  .p-lg-7 {
    padding: 2.5rem !important;
  }
  .p-lg-8 {
    padding: 3rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .p-lg-10 {
    padding: 5rem !important;
  }
  .p-lg-11 {
    padding: 6rem !important;
  }
  .p-lg-12 {
    padding: 8rem !important;
  }
  .p-lg-13 {
    padding: 10rem !important;
  }
  .p-lg-14 {
    padding: 12rem !important;
  }
  .p-lg-15 {
    padding: 16rem !important;
  }
  .p-lg-16 {
    padding: 25rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-7 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-8 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-12 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-13 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-14 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-lg-15 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-lg-16 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-7 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-8 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-12 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-13 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-14 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-lg-15 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-lg-16 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-6 {
    padding-top: 2rem !important;
  }
  .pt-lg-7 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-8 {
    padding-top: 3rem !important;
  }
  .pt-lg-9 {
    padding-top: 4rem !important;
  }
  .pt-lg-10 {
    padding-top: 5rem !important;
  }
  .pt-lg-11 {
    padding-top: 6rem !important;
  }
  .pt-lg-12 {
    padding-top: 8rem !important;
  }
  .pt-lg-13 {
    padding-top: 10rem !important;
  }
  .pt-lg-14 {
    padding-top: 12rem !important;
  }
  .pt-lg-15 {
    padding-top: 16rem !important;
  }
  .pt-lg-16 {
    padding-top: 25rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-6 {
    padding-right: 2rem !important;
  }
  .pe-lg-7 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-8 {
    padding-right: 3rem !important;
  }
  .pe-lg-9 {
    padding-right: 4rem !important;
  }
  .pe-lg-10 {
    padding-right: 5rem !important;
  }
  .pe-lg-11 {
    padding-right: 6rem !important;
  }
  .pe-lg-12 {
    padding-right: 8rem !important;
  }
  .pe-lg-13 {
    padding-right: 10rem !important;
  }
  .pe-lg-14 {
    padding-right: 12rem !important;
  }
  .pe-lg-15 {
    padding-right: 16rem !important;
  }
  .pe-lg-16 {
    padding-right: 25rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 16rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 25rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-6 {
    padding-left: 2rem !important;
  }
  .ps-lg-7 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-8 {
    padding-left: 3rem !important;
  }
  .ps-lg-9 {
    padding-left: 4rem !important;
  }
  .ps-lg-10 {
    padding-left: 5rem !important;
  }
  .ps-lg-11 {
    padding-left: 6rem !important;
  }
  .ps-lg-12 {
    padding-left: 8rem !important;
  }
  .ps-lg-13 {
    padding-left: 10rem !important;
  }
  .ps-lg-14 {
    padding-left: 12rem !important;
  }
  .ps-lg-15 {
    padding-left: 16rem !important;
  }
  .ps-lg-16 {
    padding-left: 25rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.5rem !important;
  }
  .gap-lg-6 {
    gap: 2rem !important;
  }
  .gap-lg-7 {
    gap: 2.5rem !important;
  }
  .gap-lg-8 {
    gap: 3rem !important;
  }
  .gap-lg-9 {
    gap: 4rem !important;
  }
  .gap-lg-10 {
    gap: 5rem !important;
  }
  .gap-lg-11 {
    gap: 6rem !important;
  }
  .gap-lg-12 {
    gap: 8rem !important;
  }
  .gap-lg-13 {
    gap: 10rem !important;
  }
  .gap-lg-14 {
    gap: 12rem !important;
  }
  .gap-lg-15 {
    gap: 16rem !important;
  }
  .gap-lg-16 {
    gap: 25rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-11 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-12 {
    row-gap: 8rem !important;
  }
  .row-gap-lg-13 {
    row-gap: 10rem !important;
  }
  .row-gap-lg-14 {
    row-gap: 12rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 16rem !important;
  }
  .row-gap-lg-16 {
    row-gap: 25rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 2rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-11 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-12 {
    column-gap: 8rem !important;
  }
  .column-gap-lg-13 {
    column-gap: 10rem !important;
  }
  .column-gap-lg-14 {
    column-gap: 12rem !important;
  }
  .column-gap-lg-15 {
    column-gap: 16rem !important;
  }
  .column-gap-lg-16 {
    column-gap: 25rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .border-xl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-end-xl {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xl {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-110 {
    width: 110% !important;
  }
  .w-xl-120 {
    width: 120% !important;
  }
  .w-xl-130 {
    width: 130% !important;
  }
  .w-xl-140 {
    width: 140% !important;
  }
  .w-xl-150 {
    width: 150% !important;
  }
  .mw-xl-100 {
    max-width: 100% !important;
  }
  .mw-xl-25 {
    max-width: 25% !important;
  }
  .mw-xl-50 {
    max-width: 50% !important;
  }
  .mw-xl-75 {
    max-width: 75% !important;
  }
  .mw-xl-110 {
    max-width: 110% !important;
  }
  .mw-xl-120 {
    max-width: 120% !important;
  }
  .mw-xl-130 {
    max-width: 130% !important;
  }
  .mw-xl-140 {
    max-width: 140% !important;
  }
  .mw-xl-150 {
    max-width: 150% !important;
  }
  .vw-xl-100 {
    width: 100vw !important;
  }
  .vw-xl-25 {
    width: 25vw !important;
  }
  .vw-xl-50 {
    width: 50vw !important;
  }
  .vw-xl-75 {
    width: 75vw !important;
  }
  .vw-xl-110 {
    width: 110vw !important;
  }
  .vw-xl-120 {
    width: 120vw !important;
  }
  .vw-xl-130 {
    width: 130vw !important;
  }
  .vw-xl-140 {
    width: 140vw !important;
  }
  .vw-xl-150 {
    width: 150vw !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .h-xl-110 {
    height: 110% !important;
  }
  .h-xl-120 {
    height: 120% !important;
  }
  .h-xl-130 {
    height: 130% !important;
  }
  .h-xl-140 {
    height: 140% !important;
  }
  .h-xl-150 {
    height: 150% !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .vh-xl-25 {
    height: 25vw !important;
  }
  .vh-xl-50 {
    height: 50vw !important;
  }
  .vh-xl-75 {
    height: 75vw !important;
  }
  .vh-xl-110 {
    height: 110vw !important;
  }
  .vh-xl-120 {
    height: 120vw !important;
  }
  .vh-xl-130 {
    height: 130vw !important;
  }
  .vh-xl-140 {
    height: 140vw !important;
  }
  .vh-xl-150 {
    height: 150vw !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.5rem !important;
  }
  .m-xl-6 {
    margin: 2rem !important;
  }
  .m-xl-7 {
    margin: 2.5rem !important;
  }
  .m-xl-8 {
    margin: 3rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .m-xl-10 {
    margin: 5rem !important;
  }
  .m-xl-11 {
    margin: 6rem !important;
  }
  .m-xl-12 {
    margin: 8rem !important;
  }
  .m-xl-13 {
    margin: 10rem !important;
  }
  .m-xl-14 {
    margin: 12rem !important;
  }
  .m-xl-15 {
    margin: 16rem !important;
  }
  .m-xl-16 {
    margin: 25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-7 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-8 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-12 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-13 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-14 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-15 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xl-16 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-7 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-8 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-12 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-13 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-14 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-15 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xl-16 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-6 {
    margin-top: 2rem !important;
  }
  .mt-xl-7 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-8 {
    margin-top: 3rem !important;
  }
  .mt-xl-9 {
    margin-top: 4rem !important;
  }
  .mt-xl-10 {
    margin-top: 5rem !important;
  }
  .mt-xl-11 {
    margin-top: 6rem !important;
  }
  .mt-xl-12 {
    margin-top: 8rem !important;
  }
  .mt-xl-13 {
    margin-top: 10rem !important;
  }
  .mt-xl-14 {
    margin-top: 12rem !important;
  }
  .mt-xl-15 {
    margin-top: 16rem !important;
  }
  .mt-xl-16 {
    margin-top: 25rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1rem !important;
  }
  .me-xl-5 {
    margin-right: 1.5rem !important;
  }
  .me-xl-6 {
    margin-right: 2rem !important;
  }
  .me-xl-7 {
    margin-right: 2.5rem !important;
  }
  .me-xl-8 {
    margin-right: 3rem !important;
  }
  .me-xl-9 {
    margin-right: 4rem !important;
  }
  .me-xl-10 {
    margin-right: 5rem !important;
  }
  .me-xl-11 {
    margin-right: 6rem !important;
  }
  .me-xl-12 {
    margin-right: 8rem !important;
  }
  .me-xl-13 {
    margin-right: 10rem !important;
  }
  .me-xl-14 {
    margin-right: 12rem !important;
  }
  .me-xl-15 {
    margin-right: 16rem !important;
  }
  .me-xl-16 {
    margin-right: 25rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 16rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 25rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-6 {
    margin-left: 2rem !important;
  }
  .ms-xl-7 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-8 {
    margin-left: 3rem !important;
  }
  .ms-xl-9 {
    margin-left: 4rem !important;
  }
  .ms-xl-10 {
    margin-left: 5rem !important;
  }
  .ms-xl-11 {
    margin-left: 6rem !important;
  }
  .ms-xl-12 {
    margin-left: 8rem !important;
  }
  .ms-xl-13 {
    margin-left: 10rem !important;
  }
  .ms-xl-14 {
    margin-left: 12rem !important;
  }
  .ms-xl-15 {
    margin-left: 16rem !important;
  }
  .ms-xl-16 {
    margin-left: 25rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.5rem !important;
  }
  .m-xl-n6 {
    margin: -2rem !important;
  }
  .m-xl-n7 {
    margin: -2.5rem !important;
  }
  .m-xl-n8 {
    margin: -3rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .m-xl-n10 {
    margin: -5rem !important;
  }
  .m-xl-n11 {
    margin: -6rem !important;
  }
  .m-xl-n12 {
    margin: -8rem !important;
  }
  .m-xl-n13 {
    margin: -10rem !important;
  }
  .m-xl-n14 {
    margin: -12rem !important;
  }
  .m-xl-n15 {
    margin: -16rem !important;
  }
  .m-xl-n16 {
    margin: -25rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n6 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n8 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n12 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xl-n13 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xl-n14 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-xl-n15 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-xl-n16 {
    margin-right: -25rem !important;
    margin-left: -25rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n6 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n8 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n12 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xl-n13 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xl-n14 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-xl-n15 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-xl-n16 {
    margin-top: -25rem !important;
    margin-bottom: -25rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n6 {
    margin-top: -2rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n8 {
    margin-top: -3rem !important;
  }
  .mt-xl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xl-n10 {
    margin-top: -5rem !important;
  }
  .mt-xl-n11 {
    margin-top: -6rem !important;
  }
  .mt-xl-n12 {
    margin-top: -8rem !important;
  }
  .mt-xl-n13 {
    margin-top: -10rem !important;
  }
  .mt-xl-n14 {
    margin-top: -12rem !important;
  }
  .mt-xl-n15 {
    margin-top: -16rem !important;
  }
  .mt-xl-n16 {
    margin-top: -25rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n4 {
    margin-right: -1rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n6 {
    margin-right: -2rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n8 {
    margin-right: -3rem !important;
  }
  .me-xl-n9 {
    margin-right: -4rem !important;
  }
  .me-xl-n10 {
    margin-right: -5rem !important;
  }
  .me-xl-n11 {
    margin-right: -6rem !important;
  }
  .me-xl-n12 {
    margin-right: -8rem !important;
  }
  .me-xl-n13 {
    margin-right: -10rem !important;
  }
  .me-xl-n14 {
    margin-right: -12rem !important;
  }
  .me-xl-n15 {
    margin-right: -16rem !important;
  }
  .me-xl-n16 {
    margin-right: -25rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -8rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -12rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -16rem !important;
  }
  .mb-xl-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n6 {
    margin-left: -2rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n8 {
    margin-left: -3rem !important;
  }
  .ms-xl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xl-n10 {
    margin-left: -5rem !important;
  }
  .ms-xl-n11 {
    margin-left: -6rem !important;
  }
  .ms-xl-n12 {
    margin-left: -8rem !important;
  }
  .ms-xl-n13 {
    margin-left: -10rem !important;
  }
  .ms-xl-n14 {
    margin-left: -12rem !important;
  }
  .ms-xl-n15 {
    margin-left: -16rem !important;
  }
  .ms-xl-n16 {
    margin-left: -25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.5rem !important;
  }
  .p-xl-6 {
    padding: 2rem !important;
  }
  .p-xl-7 {
    padding: 2.5rem !important;
  }
  .p-xl-8 {
    padding: 3rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .p-xl-10 {
    padding: 5rem !important;
  }
  .p-xl-11 {
    padding: 6rem !important;
  }
  .p-xl-12 {
    padding: 8rem !important;
  }
  .p-xl-13 {
    padding: 10rem !important;
  }
  .p-xl-14 {
    padding: 12rem !important;
  }
  .p-xl-15 {
    padding: 16rem !important;
  }
  .p-xl-16 {
    padding: 25rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-7 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-8 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-12 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-13 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-14 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xl-15 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xl-16 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-7 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-8 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-12 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-13 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-14 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xl-15 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xl-16 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-6 {
    padding-top: 2rem !important;
  }
  .pt-xl-7 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-8 {
    padding-top: 3rem !important;
  }
  .pt-xl-9 {
    padding-top: 4rem !important;
  }
  .pt-xl-10 {
    padding-top: 5rem !important;
  }
  .pt-xl-11 {
    padding-top: 6rem !important;
  }
  .pt-xl-12 {
    padding-top: 8rem !important;
  }
  .pt-xl-13 {
    padding-top: 10rem !important;
  }
  .pt-xl-14 {
    padding-top: 12rem !important;
  }
  .pt-xl-15 {
    padding-top: 16rem !important;
  }
  .pt-xl-16 {
    padding-top: 25rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-6 {
    padding-right: 2rem !important;
  }
  .pe-xl-7 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-8 {
    padding-right: 3rem !important;
  }
  .pe-xl-9 {
    padding-right: 4rem !important;
  }
  .pe-xl-10 {
    padding-right: 5rem !important;
  }
  .pe-xl-11 {
    padding-right: 6rem !important;
  }
  .pe-xl-12 {
    padding-right: 8rem !important;
  }
  .pe-xl-13 {
    padding-right: 10rem !important;
  }
  .pe-xl-14 {
    padding-right: 12rem !important;
  }
  .pe-xl-15 {
    padding-right: 16rem !important;
  }
  .pe-xl-16 {
    padding-right: 25rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 12rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 16rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 25rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-6 {
    padding-left: 2rem !important;
  }
  .ps-xl-7 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-8 {
    padding-left: 3rem !important;
  }
  .ps-xl-9 {
    padding-left: 4rem !important;
  }
  .ps-xl-10 {
    padding-left: 5rem !important;
  }
  .ps-xl-11 {
    padding-left: 6rem !important;
  }
  .ps-xl-12 {
    padding-left: 8rem !important;
  }
  .ps-xl-13 {
    padding-left: 10rem !important;
  }
  .ps-xl-14 {
    padding-left: 12rem !important;
  }
  .ps-xl-15 {
    padding-left: 16rem !important;
  }
  .ps-xl-16 {
    padding-left: 25rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.5rem !important;
  }
  .gap-xl-6 {
    gap: 2rem !important;
  }
  .gap-xl-7 {
    gap: 2.5rem !important;
  }
  .gap-xl-8 {
    gap: 3rem !important;
  }
  .gap-xl-9 {
    gap: 4rem !important;
  }
  .gap-xl-10 {
    gap: 5rem !important;
  }
  .gap-xl-11 {
    gap: 6rem !important;
  }
  .gap-xl-12 {
    gap: 8rem !important;
  }
  .gap-xl-13 {
    gap: 10rem !important;
  }
  .gap-xl-14 {
    gap: 12rem !important;
  }
  .gap-xl-15 {
    gap: 16rem !important;
  }
  .gap-xl-16 {
    gap: 25rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-11 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-12 {
    row-gap: 8rem !important;
  }
  .row-gap-xl-13 {
    row-gap: 10rem !important;
  }
  .row-gap-xl-14 {
    row-gap: 12rem !important;
  }
  .row-gap-xl-15 {
    row-gap: 16rem !important;
  }
  .row-gap-xl-16 {
    row-gap: 25rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 2rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-11 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-12 {
    column-gap: 8rem !important;
  }
  .column-gap-xl-13 {
    column-gap: 10rem !important;
  }
  .column-gap-xl-14 {
    column-gap: 12rem !important;
  }
  .column-gap-xl-15 {
    column-gap: 16rem !important;
  }
  .column-gap-xl-16 {
    column-gap: 25rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .border-xxl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-end-xxl {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xxl {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-110 {
    width: 110% !important;
  }
  .w-xxl-120 {
    width: 120% !important;
  }
  .w-xxl-130 {
    width: 130% !important;
  }
  .w-xxl-140 {
    width: 140% !important;
  }
  .w-xxl-150 {
    width: 150% !important;
  }
  .mw-xxl-100 {
    max-width: 100% !important;
  }
  .mw-xxl-25 {
    max-width: 25% !important;
  }
  .mw-xxl-50 {
    max-width: 50% !important;
  }
  .mw-xxl-75 {
    max-width: 75% !important;
  }
  .mw-xxl-110 {
    max-width: 110% !important;
  }
  .mw-xxl-120 {
    max-width: 120% !important;
  }
  .mw-xxl-130 {
    max-width: 130% !important;
  }
  .mw-xxl-140 {
    max-width: 140% !important;
  }
  .mw-xxl-150 {
    max-width: 150% !important;
  }
  .vw-xxl-100 {
    width: 100vw !important;
  }
  .vw-xxl-25 {
    width: 25vw !important;
  }
  .vw-xxl-50 {
    width: 50vw !important;
  }
  .vw-xxl-75 {
    width: 75vw !important;
  }
  .vw-xxl-110 {
    width: 110vw !important;
  }
  .vw-xxl-120 {
    width: 120vw !important;
  }
  .vw-xxl-130 {
    width: 130vw !important;
  }
  .vw-xxl-140 {
    width: 140vw !important;
  }
  .vw-xxl-150 {
    width: 150vw !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .h-xxl-110 {
    height: 110% !important;
  }
  .h-xxl-120 {
    height: 120% !important;
  }
  .h-xxl-130 {
    height: 130% !important;
  }
  .h-xxl-140 {
    height: 140% !important;
  }
  .h-xxl-150 {
    height: 150% !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .vh-xxl-25 {
    height: 25vw !important;
  }
  .vh-xxl-50 {
    height: 50vw !important;
  }
  .vh-xxl-75 {
    height: 75vw !important;
  }
  .vh-xxl-110 {
    height: 110vw !important;
  }
  .vh-xxl-120 {
    height: 120vw !important;
  }
  .vh-xxl-130 {
    height: 130vw !important;
  }
  .vh-xxl-140 {
    height: 140vw !important;
  }
  .vh-xxl-150 {
    height: 150vw !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.5rem !important;
  }
  .m-xxl-6 {
    margin: 2rem !important;
  }
  .m-xxl-7 {
    margin: 2.5rem !important;
  }
  .m-xxl-8 {
    margin: 3rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .m-xxl-10 {
    margin: 5rem !important;
  }
  .m-xxl-11 {
    margin: 6rem !important;
  }
  .m-xxl-12 {
    margin: 8rem !important;
  }
  .m-xxl-13 {
    margin: 10rem !important;
  }
  .m-xxl-14 {
    margin: 12rem !important;
  }
  .m-xxl-15 {
    margin: 16rem !important;
  }
  .m-xxl-16 {
    margin: 25rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-7 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-12 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-13 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-14 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-15 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xxl-16 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-7 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-8 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-12 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-13 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-14 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-15 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xxl-16 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 2rem !important;
  }
  .mt-xxl-7 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 3rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4rem !important;
  }
  .mt-xxl-10 {
    margin-top: 5rem !important;
  }
  .mt-xxl-11 {
    margin-top: 6rem !important;
  }
  .mt-xxl-12 {
    margin-top: 8rem !important;
  }
  .mt-xxl-13 {
    margin-top: 10rem !important;
  }
  .mt-xxl-14 {
    margin-top: 12rem !important;
  }
  .mt-xxl-15 {
    margin-top: 16rem !important;
  }
  .mt-xxl-16 {
    margin-top: 25rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-6 {
    margin-right: 2rem !important;
  }
  .me-xxl-7 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-8 {
    margin-right: 3rem !important;
  }
  .me-xxl-9 {
    margin-right: 4rem !important;
  }
  .me-xxl-10 {
    margin-right: 5rem !important;
  }
  .me-xxl-11 {
    margin-right: 6rem !important;
  }
  .me-xxl-12 {
    margin-right: 8rem !important;
  }
  .me-xxl-13 {
    margin-right: 10rem !important;
  }
  .me-xxl-14 {
    margin-right: 12rem !important;
  }
  .me-xxl-15 {
    margin-right: 16rem !important;
  }
  .me-xxl-16 {
    margin-right: 25rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 16rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 25rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-6 {
    margin-left: 2rem !important;
  }
  .ms-xxl-7 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 3rem !important;
  }
  .ms-xxl-9 {
    margin-left: 4rem !important;
  }
  .ms-xxl-10 {
    margin-left: 5rem !important;
  }
  .ms-xxl-11 {
    margin-left: 6rem !important;
  }
  .ms-xxl-12 {
    margin-left: 8rem !important;
  }
  .ms-xxl-13 {
    margin-left: 10rem !important;
  }
  .ms-xxl-14 {
    margin-left: 12rem !important;
  }
  .ms-xxl-15 {
    margin-left: 16rem !important;
  }
  .ms-xxl-16 {
    margin-left: 25rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -0.75rem !important;
  }
  .m-xxl-n4 {
    margin: -1rem !important;
  }
  .m-xxl-n5 {
    margin: -1.5rem !important;
  }
  .m-xxl-n6 {
    margin: -2rem !important;
  }
  .m-xxl-n7 {
    margin: -2.5rem !important;
  }
  .m-xxl-n8 {
    margin: -3rem !important;
  }
  .m-xxl-n9 {
    margin: -4rem !important;
  }
  .m-xxl-n10 {
    margin: -5rem !important;
  }
  .m-xxl-n11 {
    margin: -6rem !important;
  }
  .m-xxl-n12 {
    margin: -8rem !important;
  }
  .m-xxl-n13 {
    margin: -10rem !important;
  }
  .m-xxl-n14 {
    margin: -12rem !important;
  }
  .m-xxl-n15 {
    margin: -16rem !important;
  }
  .m-xxl-n16 {
    margin: -25rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n12 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xxl-n13 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xxl-n14 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-xxl-n15 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-xxl-n16 {
    margin-right: -25rem !important;
    margin-left: -25rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n6 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n7 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n8 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n12 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xxl-n13 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xxl-n14 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-xxl-n15 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-xxl-n16 {
    margin-top: -25rem !important;
    margin-bottom: -25rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -8rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -12rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -16rem !important;
  }
  .mt-xxl-n16 {
    margin-top: -25rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n6 {
    margin-right: -2rem !important;
  }
  .me-xxl-n7 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n8 {
    margin-right: -3rem !important;
  }
  .me-xxl-n9 {
    margin-right: -4rem !important;
  }
  .me-xxl-n10 {
    margin-right: -5rem !important;
  }
  .me-xxl-n11 {
    margin-right: -6rem !important;
  }
  .me-xxl-n12 {
    margin-right: -8rem !important;
  }
  .me-xxl-n13 {
    margin-right: -10rem !important;
  }
  .me-xxl-n14 {
    margin-right: -12rem !important;
  }
  .me-xxl-n15 {
    margin-right: -16rem !important;
  }
  .me-xxl-n16 {
    margin-right: -25rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -8rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -12rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -16rem !important;
  }
  .mb-xxl-n16 {
    margin-bottom: -25rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -8rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -10rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -12rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -16rem !important;
  }
  .ms-xxl-n16 {
    margin-left: -25rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.5rem !important;
  }
  .p-xxl-6 {
    padding: 2rem !important;
  }
  .p-xxl-7 {
    padding: 2.5rem !important;
  }
  .p-xxl-8 {
    padding: 3rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .p-xxl-10 {
    padding: 5rem !important;
  }
  .p-xxl-11 {
    padding: 6rem !important;
  }
  .p-xxl-12 {
    padding: 8rem !important;
  }
  .p-xxl-13 {
    padding: 10rem !important;
  }
  .p-xxl-14 {
    padding: 12rem !important;
  }
  .p-xxl-15 {
    padding: 16rem !important;
  }
  .p-xxl-16 {
    padding: 25rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-7 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-8 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-12 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-13 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-14 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xxl-15 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xxl-16 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-7 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-8 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-12 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-13 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-14 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xxl-15 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xxl-16 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-6 {
    padding-top: 2rem !important;
  }
  .pt-xxl-7 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 3rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4rem !important;
  }
  .pt-xxl-10 {
    padding-top: 5rem !important;
  }
  .pt-xxl-11 {
    padding-top: 6rem !important;
  }
  .pt-xxl-12 {
    padding-top: 8rem !important;
  }
  .pt-xxl-13 {
    padding-top: 10rem !important;
  }
  .pt-xxl-14 {
    padding-top: 12rem !important;
  }
  .pt-xxl-15 {
    padding-top: 16rem !important;
  }
  .pt-xxl-16 {
    padding-top: 25rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-6 {
    padding-right: 2rem !important;
  }
  .pe-xxl-7 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-8 {
    padding-right: 3rem !important;
  }
  .pe-xxl-9 {
    padding-right: 4rem !important;
  }
  .pe-xxl-10 {
    padding-right: 5rem !important;
  }
  .pe-xxl-11 {
    padding-right: 6rem !important;
  }
  .pe-xxl-12 {
    padding-right: 8rem !important;
  }
  .pe-xxl-13 {
    padding-right: 10rem !important;
  }
  .pe-xxl-14 {
    padding-right: 12rem !important;
  }
  .pe-xxl-15 {
    padding-right: 16rem !important;
  }
  .pe-xxl-16 {
    padding-right: 25rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 12rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 16rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 25rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-6 {
    padding-left: 2rem !important;
  }
  .ps-xxl-7 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 3rem !important;
  }
  .ps-xxl-9 {
    padding-left: 4rem !important;
  }
  .ps-xxl-10 {
    padding-left: 5rem !important;
  }
  .ps-xxl-11 {
    padding-left: 6rem !important;
  }
  .ps-xxl-12 {
    padding-left: 8rem !important;
  }
  .ps-xxl-13 {
    padding-left: 10rem !important;
  }
  .ps-xxl-14 {
    padding-left: 12rem !important;
  }
  .ps-xxl-15 {
    padding-left: 16rem !important;
  }
  .ps-xxl-16 {
    padding-left: 25rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.5rem !important;
  }
  .gap-xxl-6 {
    gap: 2rem !important;
  }
  .gap-xxl-7 {
    gap: 2.5rem !important;
  }
  .gap-xxl-8 {
    gap: 3rem !important;
  }
  .gap-xxl-9 {
    gap: 4rem !important;
  }
  .gap-xxl-10 {
    gap: 5rem !important;
  }
  .gap-xxl-11 {
    gap: 6rem !important;
  }
  .gap-xxl-12 {
    gap: 8rem !important;
  }
  .gap-xxl-13 {
    gap: 10rem !important;
  }
  .gap-xxl-14 {
    gap: 12rem !important;
  }
  .gap-xxl-15 {
    gap: 16rem !important;
  }
  .gap-xxl-16 {
    gap: 25rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-11 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-12 {
    row-gap: 8rem !important;
  }
  .row-gap-xxl-13 {
    row-gap: 10rem !important;
  }
  .row-gap-xxl-14 {
    row-gap: 12rem !important;
  }
  .row-gap-xxl-15 {
    row-gap: 16rem !important;
  }
  .row-gap-xxl-16 {
    row-gap: 25rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 2rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-11 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-12 {
    column-gap: 8rem !important;
  }
  .column-gap-xxl-13 {
    column-gap: 10rem !important;
  }
  .column-gap-xxl-14 {
    column-gap: 12rem !important;
  }
  .column-gap-xxl-15 {
    column-gap: 16rem !important;
  }
  .column-gap-xxl-16 {
    column-gap: 25rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root,
[data-bs-theme=light] {
  --bs-primary-bg-subtle-rgb: 51, 94, 234;
  --bs-primary-desat-bg-subtle-rgb: 51, 94, 234;
  --bs-secondary-bg-subtle-rgb: 80, 102, 144;
  --bs-success-bg-subtle-rgb: 66, 186, 150;
  --bs-info-bg-subtle-rgb: 139, 68, 172;
  --bs-warning-bg-subtle-rgb: 250, 215, 118;
  --bs-danger-bg-subtle-rgb: 223, 71, 89;
  --bs-light-bg-subtle-rgb: 249, 251, 253;
  --bs-dark-bg-subtle-rgb: 27, 42, 78;
  --bs-white-bg-subtle-rgb: 255, 255, 255;
  --bs-gray-700-bg-subtle-rgb: 80, 102, 144;
  --bs-gray-100-rgb: 249, 251, 253;
  --bs-gray-200-rgb: 241, 244, 248;
  --bs-gray-300-rgb: 217, 226, 239;
  --bs-gray-400-rgb: 198, 211, 230;
  --bs-gray-500-rgb: 171, 188, 213;
  --bs-gray-600-rgb: 134, 154, 184;
  --bs-gray-700-rgb: 80, 102, 144;
  --bs-gray-800-rgb: 56, 76, 116;
  --bs-gray-900-rgb: 27, 42, 78;
}

body {
  overflow-x: clip;
}

@font-face {
  font-family: "Feather";
  src: url("../fonts/Feather/Feather.ttf?sdxovp") format("truetype"), url("../fonts/Feather/Feather.woff?sdxovp") format("woff"), url("../fonts/Feather/Feather.svg?sdxovp#Feather") format("svg");
  font-weight: 400;
  font-style: normal;
}
p {
  font-size: 1.3125rem;
  font-weight: 300;
  color: #506690;
}

h1, .h1, h2, .h2 {
  margin-bottom: 1rem;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  letter-spacing: -0.01em;
}

h1, .h1 {
  line-height: 1.3;
}
@media (max-width: 991.98px) {
  h1, .h1 {
    font-size: 1.875rem;
  }
}

h2, .h2 {
  line-height: 1.35;
}
@media (max-width: 991.98px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}

h3, .h3 {
  line-height: 1.4;
}
@media (max-width: 991.98px) {
  h3, .h3 {
    font-size: 1.125rem;
  }
}

h4, .h4 {
  line-height: 1.45;
}
@media (max-width: 991.98px) {
  h4, .h4 {
    font-size: 1.0625rem;
  }
}

h5, .h5 {
  line-height: 1.5;
}

h6, .h6 {
  line-height: 1.55;
}
h6.text-uppercase, .h6.text-uppercase {
  letter-spacing: 0.08em;
}

.display-1, .display-2, .display-3, .display-4 {
  letter-spacing: -0.02em;
}

.display-1 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .display-1 {
    font-size: 3.125rem;
  }
}

.display-2 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .display-2 {
    font-size: 2.5rem;
  }
}

.display-3 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .display-3 {
    font-size: 2.25rem;
  }
}

.display-4 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .display-4 {
    font-size: 2rem;
  }
}

.lead {
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .lead {
    font-size: 1.1875rem;
  }
}

.blockquote-img {
  max-height: 2.5rem;
  max-width: 100%;
  width: auto;
}

.blockquote-footer {
  margin-top: 0;
}
.blockquote-footer::before {
  display: none;
}

.list-social-icon {
  max-width: 1.25rem;
  height: auto;
}

[class*=hr-] {
  margin-left: auto;
  margin-right: auto;
}

.hr-sm {
  width: 60px;
}

.hr-sm:not([size]) {
  height: calc(var(--bs-border-width) * 2);
}

.hr-md {
  width: 100px;
}

.hr-md:not([size]) {
  height: calc(var(--bs-border-width) * 2);
}

code {
  --bs-code-line-height: 1.35;
  line-height: var(--bs-code-line-height);
}

.img-cover {
  width: 100vw;
  height: auto;
  margin-left: calc(50% - 50vw);
}

.img-fluid > img,
.img-fluid > svg {
  max-width: inherit;
  height: inherit;
}

.figure {
  display: block;
}

.figure-img {
  margin-bottom: 1rem;
}

[class^=container] {
  position: relative;
  z-index: 1;
}

[class^=col] {
  position: relative;
}

.table > :not(:first-child) {
  border-top: 0;
}

.table-align-middle thead > tr > th,
.table-align-middle tbody > tr > td {
  vertical-align: middle;
}

.form-floating > label {
  color: #869AB8 !important;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  font-size: 0.75rem;
}

.form-check {
  margin-bottom: 0;
}

.form-check + .form-check {
  margin-top: 0.125rem;
}

.form-check-dark .form-check-input {
  background-color: rgba(255, 255, 255, 0.2);
}

.form-check-dark .form-check-input:checked {
  background-color: var(--bs-white);
}

.form-switch {
  min-height: 1.375rem;
}

.form-switch > .form-check-input {
  height: 1.375rem;
}

.form-switch > .form-check-label {
  margin-top: calc((1.375rem - 1.6 * 1em) * 0.5);
}

.form-check-dark.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}

.form-check-dark.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}

.form-check-dark.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23335EEA'/%3e%3c/svg%3e");
}

.form-control-xs {
  min-height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 0.625rem;
  line-height: 1.6;
  font-size: 0.9375rem;
}

.form-control-flush,
.form-floating > .form-control-flush {
  padding-left: 0;
  padding-right: 0;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.form-floating > .form-control-flush ~ label {
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-select-xs {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem calc(0.625rem + 0.625rem + 0.75em) 0.25rem 0.625rem;
  line-height: 1.6;
  font-size: 0.9375rem;
  background-size: 0.75em;
  background-position: right 0.625rem center;
}

.input-group-xs .form-control {
  min-height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 0.625rem;
  line-height: 1.6;
  font-size: 0.9375rem;
}

.input-group-xs .input-group-text {
  padding: 0.25rem 0.625rem;
  line-height: 1.6;
  font-size: 0.9375rem;
}

.btn > img {
  max-height: 1em;
  width: auto;
}

.btn:not([class*=btn-outline]) {
  border-color: transparent !important;
}

.btn-xs {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.625rem;
  --bs-btn-font-size: 0.9375rem;
}

.btn-white {
  --bs-btn-bg: var(--bs-white);
  --bs-btn-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-hover-color: #1c4ce8;
  --bs-btn-focus-bg: var(--bs-white);
  --bs-btn-focus-color: #1c4ce8;
  --bs-btn-active-bg: #f2f2f2;
  --bs-btn-active-color: #1c4ce8;
}

.btn-group .btn-white {
  --bs-btn-border-color: var(--bs-gray-300);
  --bs-btn-color: var(--bs-secondary-color);
  --bs-btn-active-border-color: var(--bs-gray-300);
  --bs-btn-active-color: var(--bs-body-color);
  border-color: var(--bs-btn-border-color) !important;
}

.btn-outline-white {
  --bs-btn-border-color: var(--bs-gray-300);
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-hover-bg: #f2f2f2;
  --bs-btn-hover-border-color: var(--bs-gray-400);
  --bs-btn-hover-color: var(--bs-body-color);
  --bs-btn-active-bg: #f2f2f2;
  --bs-btn-active-border-color: var(--bs-gray-400);
  --bs-btn-active-color: var(--bs-body-color);
  --bs-btn-disabled-border-color: var(--bs-gray-300);
  --bs-btn-disabled-color: var(--bs-body-color);
}

.btn-outline-gray-300 {
  --bs-btn-border-color: var(--bs-gray-300);
  --bs-btn-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-color: var(--bs-white);
}

.btn[class*=-subtle] {
  --bs-btn-subtle-bg-opacity: 0.1;
}

.btn-primary-subtle {
  --bs-btn-bg: RGBA(var(--bs-primary-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-primary);
  --bs-btn-hover-bg: RGBA(var(--bs-primary-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-active-bg: RGBA(var(--bs-primary-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-primary);
  --bs-btn-disabled-bg: RGBA(var(--bs-primary-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-primary);
}

.btn-primary-desat-subtle {
  --bs-btn-bg: RGBA(var(--bs-primary-desat-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-primary-desat);
  --bs-btn-hover-bg: RGBA(var(--bs-primary-desat-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-primary-desat);
  --bs-btn-active-bg: RGBA(var(--bs-primary-desat-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-primary-desat);
  --bs-btn-disabled-bg: RGBA(var(--bs-primary-desat-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-primary-desat);
}

.btn-secondary-subtle {
  --bs-btn-bg: RGBA(var(--bs-secondary-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-hover-bg: RGBA(var(--bs-secondary-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-active-bg: RGBA(var(--bs-secondary-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-secondary);
  --bs-btn-disabled-bg: RGBA(var(--bs-secondary-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-secondary);
}

.btn-success-subtle {
  --bs-btn-bg: RGBA(var(--bs-success-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-success);
  --bs-btn-hover-bg: RGBA(var(--bs-success-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-success);
  --bs-btn-active-bg: RGBA(var(--bs-success-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-success);
  --bs-btn-disabled-bg: RGBA(var(--bs-success-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-success);
}

.btn-info-subtle {
  --bs-btn-bg: RGBA(var(--bs-info-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-info);
  --bs-btn-hover-bg: RGBA(var(--bs-info-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-info);
  --bs-btn-active-bg: RGBA(var(--bs-info-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-info);
  --bs-btn-disabled-bg: RGBA(var(--bs-info-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-info);
}

.btn-warning-subtle {
  --bs-btn-bg: RGBA(var(--bs-warning-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-warning);
  --bs-btn-hover-bg: RGBA(var(--bs-warning-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-warning);
  --bs-btn-active-bg: RGBA(var(--bs-warning-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-warning);
  --bs-btn-disabled-bg: RGBA(var(--bs-warning-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-warning);
}

.btn-danger-subtle {
  --bs-btn-bg: RGBA(var(--bs-danger-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-danger);
  --bs-btn-hover-bg: RGBA(var(--bs-danger-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-danger);
  --bs-btn-active-bg: RGBA(var(--bs-danger-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-danger);
  --bs-btn-disabled-bg: RGBA(var(--bs-danger-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-danger);
}

.btn-light-subtle {
  --bs-btn-bg: RGBA(var(--bs-light-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-light);
  --bs-btn-hover-bg: RGBA(var(--bs-light-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-light);
  --bs-btn-active-bg: RGBA(var(--bs-light-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-light);
  --bs-btn-disabled-bg: RGBA(var(--bs-light-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-light);
}

.btn-dark-subtle {
  --bs-btn-bg: RGBA(var(--bs-dark-rgb), var(--bs-btn-subtle-bg-opacity));
  --bs-btn-color: var(--bs-dark);
  --bs-btn-hover-bg: RGBA(var(--bs-dark-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-hover-color: var(--bs-dark);
  --bs-btn-active-bg: RGBA(var(--bs-dark-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1.5));
  --bs-btn-active-color: var(--bs-dark);
  --bs-btn-disabled-bg: RGBA(var(--bs-dark-rgb), calc(var(--bs-btn-subtle-bg-opacity) * 1));
  --bs-btn-disabled-color: var(--bs-dark);
}

.btn-pill {
  --bs-btn-border-radius: var(--bs-border-radius-pill);
}

.btn-rounded-circle {
  --bs-btn-padding-x: var(--bs-btn-padding-y);
  --bs-btn-border-radius: var(--bs-border-radius-pill);
}
.btn-rounded-circle > * {
  display: block;
  line-height: 1 !important;
  width: 1em;
}

.dropdown-menu {
  padding-right: var(--bs-dropdown-padding-x);
  padding-left: var(--bs-dropdown-padding-x);
}

.dropdown-item {
  width: auto;
  min-width: 100%;
  margin-left: calc(var(--bs-dropdown-item-padding-x) * -1);
  margin-right: calc(var(--bs-dropdown-item-padding-x) * -1);
}
.dropdown-item:hover, .dropdown-item:focus {
  outline: none;
}

.dropdown-menu > li + li,
.dropdown-item + .dropdown-item {
  margin-top: 0.45rem;
}

.dropdown-link {
  color: inherit;
}
.dropdown-link:hover {
  text-decoration: inherit;
}
.dropdown-link:hover, .dropdown-link:focus {
  outline: none;
}

.dropdown-header {
  margin-left: calc(var(--bs-dropdown-item-padding-x) * -1);
  margin-right: calc(var(--bs-dropdown-item-padding-x) * -1);
  padding: 0 var(--bs-dropdown-item-padding-x) 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.55;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.dropdown-toggle::after {
  font-family: "Feather";
  vertical-align: middle;
  border: none !important;
  content: "\e92e";
}

.dropend > .dropdown-toggle::after {
  content: "\e930";
}

.dropdown-menu-xs {
  --bs-dropdown-min-width: 0;
  --prefixdropdown-padding-y: 1rem;
  --prefixdropdown-padding-x: 1.25rem;
}

.dropdown-menu-md {
  --bs-dropdown-min-width: 22rem;
}

.dropdown-menu-lg {
  --bs-dropdown-min-width: 35rem;
  --bs-dropdown-padding-y: 2.375rem;
  --bs-dropdown-padding-x: 2.5rem;
  --bs-dropdown-item-padding-x: 2.5rem;
}

.dropdown-menu-xl {
  --bs-dropdown-min-width: 42rem;
  --bs-dropdown-padding-y: 2.375rem;
  --bs-dropdown-padding-x: 2.5rem;
  --bs-dropdown-item-padding-x: 2.5rem;
}

.dropdown-menu-lg .dropdown-item,
.dropdown-menu-xl .dropdown-header {
  margin-left: calc(var(--bs-dropdown-padding-x) * -1);
  margin-right: calc(var(--bs-dropdown-padding-x) * -1);
}

.dropend > .dropdown-menu {
  top: calc(1.625rem * -1);
}

.navbar {
  transition: all 0.2s ease-in-out;
  transition-property: background-color, color;
  z-index: 1030;
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-item .nav-link {
    color: var(--bs-gray-700);
  }
  .navbar-dark .navbar-nav .nav-item .nav-link:hover, .navbar-dark .navbar-nav .nav-item .nav-link:focus {
    color: var(--bs-primary);
  }
  .navbar-dark .navbar-nav .nav-item.active .nav-link,
  .navbar-dark .navbar-nav .nav-item .nav-link.active {
    color: var(--bs-primary);
  }
  .navbar-dark .navbar-collapse .navbar-toggler {
    color: var(--bs-gray-700);
  }
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.navbar-brand {
  font-weight: 400;
}

.navbar-brand-img {
  max-height: 2.4rem;
  width: auto;
}

.navbar-dark .navbar-brand {
  filter: brightness(0) invert(1);
}

@media (max-width: 991.98px) {
  .navbar-btn {
    width: 100%;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: var(--bs-btn-font-size);
  }
}

.navbar-nav .nav-link {
  font-weight: 400;
}
.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
  outline: none;
}

.navbar-dark.fixed-top > .container {
  position: relative;
}
.navbar-dark.fixed-top > .container::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: calc(1.25rem * -1);
  left: 0;
  border-top: var(--bs-border-width) solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    position: fixed;
    top: 1rem;
    left: 1rem;
    height: auto;
    max-height: calc(100% - 2rem) !important;
    width: calc(100% - 2rem);
    background-color: var(--bs-white);
    border-radius: var(--bs-dropdown-border-radius);
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .navbar-collapse.show,
  .navbar-collapse.collapsing {
    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform, -webkit-transform;
    transform-origin: top right;
  }
  .navbar-collapse.show {
    opacity: 1;
    transform: scale(1);
  }
  .navbar-collapse.collapsing {
    opacity: 0;
    transform: scale(0.9);
  }
}
.navbar .navbar-toggler {
  margin-left: auto;
}

.navbar-collapse .navbar-toggler {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}
@media (min-width: 992px) {
  .navbar-collapse .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .nav-item {
    padding: 1.5rem;
  }
  .navbar-collapse .navbar-nav .nav-item + .nav-item {
    border-top: var(--bs-border-width) solid var(--bs-gray-200);
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-nav .dropdown-menu {
  box-shadow: none;
}
@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown-menu {
    min-width: 0;
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown > .dropdown-menu {
    display: block !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropend > .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
  }
}
@media (min-width: 992px) {
  .navbar-collapse .navbar-nav .dropend > .dropdown-menu {
    left: 100%;
    right: auto;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown-item,
  .navbar-collapse .navbar-nav .dropdown-header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar-nav .dropdown > .dropdown-toggle::after {
  display: none;
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown > .dropdown-toggle {
    margin-bottom: 1.5rem;
    pointer-events: none;
  }
}

.navbar-nav .dropend > .dropdown-toggle {
  display: flex;
}
.navbar-nav .dropend > .dropdown-toggle::after {
  margin-left: auto;
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropend > .dropdown-toggle::after {
    content: "\e92e";
  }
  .navbar-collapse .navbar-nav .dropend > .dropdown-toggle[aria-expanded=true]::after {
    transform-origin: center center;
    transform: rotate(180deg);
  }
}

.navbar-nav .dropdown-img-start {
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  background: no-repeat center center/cover;
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
@media (min-width: 992px) {
  .navbar-nav .dropdown-img-start {
    display: flex;
  }
}
.navbar-nav .dropdown-img-start::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 94, 234, 0.8);
  border-radius: inherit;
}
.navbar-nav .dropdown-img-start * {
  position: relative;
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu-lg .dropdown-img-start,
  .navbar-nav .dropdown-menu-xl .dropdown-img-start {
    padding: 2.375rem 2.5rem;
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-body {
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu-lg .dropdown-body,
  .navbar-nav .dropdown-menu-xl .dropdown-body {
    padding: 2.375rem 2.5rem;
  }
}

.navbar-nav .dropdown-menu .list-group-item {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: inherit;
}
.navbar-nav .dropdown-menu .list-group-item:hover {
  text-decoration: none;
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform, -webkit-transform;
  }
  .navbar-nav .dropend > .dropdown-menu {
    transform: translateY(10px);
  }
  .navbar-nav .dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 10px);
  }
  .navbar-nav .dropdown-menu.showing {
    display: block;
  }
  .navbar-nav .dropdown-menu.show {
    display: block;
    opacity: 1;
  }
  .navbar-nav .dropend > .dropdown-menu.show {
    transform: translateY(0);
  }
  .navbar-nav .dropdown > .dropdown-menu.show {
    transform: translate(-50%, 0);
  }
}
.card {
  --bs-card-meta-spacer-y: 1.5rem;
  --bs-card-row-spacer-y: 4rem;
  --bs-card-row-spacer-x: 2.5rem;
}

.card {
  position: relative;
  width: 100%;
}

.card-header {
  background-color: transparent;
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
}

.card-body {
  display: block;
  flex-grow: 0;
}

.card-footer {
  background-color: unset;
}

.card-body, .card-footer, .card-meta, .card-img, .card-img-top, .card-img-start, .card-img-end {
  position: relative;
  min-height: 1px;
}

a.card, a.card-body, a.card-footer, a.card-meta {
  color: inherit;
}
a.card:hover, a.card-body:hover, a.card-footer:hover, a.card-meta:hover {
  text-decoration: none;
}

.card-flush {
  background-color: unset;
}

.card-flush > *:not(.card-btn) {
  padding-left: 0;
  padding-right: 0;
}

.card-border {
  position: relative;
}

.card-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top-width: calc(var(--bs-border-width) * 2);
  border-bottom-width: calc(var(--bs-card-border-radius) - var(--bs-border-width) * 2);
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: inherit;
  border-bottom-color: transparent;
  border-top-left-radius: var(--bs-card-border-radius);
  border-top-right-radius: var(--bs-card-border-radius);
}

.card-border-lg::after {
  border-top-width: calc(var(--bs-border-width) * 3);
  border-bottom-width: calc(var(--bs-card-border-radius) - var(--bs-border-width) * 3);
}

.card-border-xl::after {
  border-top-width: calc(var(--bs-border-width) * 4);
  border-bottom-width: calc(var(--bs-card-border-radius) - var(--bs-border-width) * 3);
}

@media (min-width: 768px) {
  .card-row .card-body {
    padding: var(--bs-card-row-spacer-y) var(--bs-card-row-spacer-x);
  }
}
@media (min-width: 768px) {
  .card-row .card-meta {
    padding-left: var(--bs-card-row-spacer-x);
    padding-right: var(--bs-card-row-spacer-x);
  }
}

.card-img-end {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
}
@media (min-width: 768px) {
  .card-img-end {
    border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
  }
}

.card-img-start {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
}
@media (min-width: 768px) {
  .card-img-start {
    border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
  }
}

@media (min-width: 768px) {
  .card-img-slider {
    height: 100%;
    width: 100%;
  }
  .card-img-slider * {
    height: inherit !important;
    width: inherit !important;
  }
}

@media (max-width: 575.98px) { /* Bootstrap’s xs breakpoint */
  .card-img-overlay-hover {
    overflow: hidden;
  }
  .card-img-overlay-hover .card-body {
    transform: translateY(110%);
    transition: all 0.3s ease;
  }
  .card-img-overlay-hover:hover .card-body {
    transform: translateY(0);
  }
}
.card-img-overlay {
  display: flex;
  flex-direction: column;
  padding: var(--bs-card-spacer-x);
}

.card-img-overlay .card-body {
  margin: calc(2rem * -1);
  margin-top: auto;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card-group {
    flex-direction: column !important;
  }
  .card-group > .card {
    flex: auto;
  }
}

.card-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 var(--bs-card-spacer-x) var(--bs-card-meta-spacer-y);
}

.card-meta-divider {
  width: 100%;
  margin: 0 0 var(--bs-card-meta-spacer-y);
}

.card-btn:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.card-zoom {
  overflow: hidden !important;
  border-radius: inherit;
}

.card-zoom > [class*=card-img] {
  transition: all 0.3s ease;
  transform-origin: center center;
}

.card:hover > .card-zoom > [class*=card-img] {
  transform: scale(1.1);
}

.card-list .list-link {
  position: relative;
}

.card-list .list-link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(2rem * -1);
  border-right: calc(var(--bs-border-width) * 2) solid var(--bs-primary);
  display: none;
}

.card-list .list-link:hover {
  color: var(--bs-body-color) !important;
}

.card-list .active .list-link {
  color: var(--bs-body-color) !important;
}

.card-list .active .list-link::before {
  display: block;
}

@media (max-width: 767.98px) {
  .card-bleed {
    width: auto;
    min-width: 100%;
    margin-right: calc(2.5rem * -.5);
    margin-left: calc(2.5rem * -.5);
    border-radius: 0;
  }
  .card-bleed .card-footer,
  .card-bleed .card-header,
  .card-bleed .card-body {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    border-radius: 0;
  }
  .card-bleed .card-list .list-link::before {
    left: calc(2.5rem * -.5);
  }
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button:not([data-bs-toggle=collapse])::after {
  display: none;
}

.accordion-button::after {
  margin-left: 1rem;
}

.accordion-body {
  padding-top: 0;
}

.breadcrumb {
  --bs-breadcrumb-font-size: 0.9375rem;
}

.breadcrumb-item {
  font-size: var(--bs-breadcrumb-font-size);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "\e930";
  font-family: "Feather";
}

.breadcrumb-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
}
.breadcrumb-scroll .breadcrumb-item {
  white-space: nowrap;
}
.breadcrumb-scroll::-webkit-scrollbar {
  display: none;
}

.pagination-sm {
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-font-size: 1.0625rem;
}

.pagination-sm .page-link {
  line-height: 1.6;
}

.pagination-lg {
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-font-size: 1.0625rem;
}

.pagination-lg .page-link {
  line-height: 1.6;
}

.badge[href]:hover {
  text-decoration: none;
}

.badge-lg {
  --bs-badge-padding-y: 0.7em;
  --bs-badge-padding-x: 1.15em;
}

.badge-rounded-circle {
  height: calc(1em + var(--bs-badge-padding-y) * 2);
  padding-left: var(--bs-badge-padding-y);
  padding-right: var(--bs-badge-padding-y);
  border-radius: var(--bs-border-radius-pill);
}
.badge-rounded-circle > * {
  display: block;
  width: 1em;
}

.badge-float {
  position: absolute;
  z-index: 1000;
}

.badge-float-inside {
  top: 1rem;
  right: 1rem;
}

.badge-float-outside {
  top: -0.5rem;
  right: -0.5rem;
}

.badge.text-bg-primary-subtle[href]:hover, .badge.text-bg-primary-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-primary-desat-subtle[href]:hover, .badge.text-bg-primary-desat-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-secondary-subtle[href]:hover, .badge.text-bg-secondary-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-success-subtle[href]:hover, .badge.text-bg-success-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-info-subtle[href]:hover, .badge.text-bg-info-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-warning-subtle[href]:hover, .badge.text-bg-warning-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-danger-subtle[href]:hover, .badge.text-bg-danger-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-light-subtle[href]:hover, .badge.text-bg-light-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-dark-subtle[href]:hover, .badge.text-bg-dark-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-white-subtle[href]:hover, .badge.text-bg-white-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-700-subtle[href]:hover, .badge.text-bg-gray-700-subtle[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-100[href]:hover, .badge.text-bg-gray-100[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-200[href]:hover, .badge.text-bg-gray-200[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-300[href]:hover, .badge.text-bg-gray-300[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-400[href]:hover, .badge.text-bg-gray-400[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-500[href]:hover, .badge.text-bg-gray-500[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-600[href]:hover, .badge.text-bg-gray-600[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-700[href]:hover, .badge.text-bg-gray-700[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-800[href]:hover, .badge.text-bg-gray-800[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-gray-900[href]:hover, .badge.text-bg-gray-900[href]:focus {
  --bs-bg-opacity: .2;
}

.badge.text-bg-white-subtle.active {
  background-color: var(--bs-white) !important;
  color: var(--bs-primary) !important;
}

.badge.text-bg-secondary-subtle.active {
  background-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.alert-primary {
  --bs-alert-color: #FFFFFF;
  --bs-alert-bg: var(--bs-primary);
  --bs-alert-border-color: var(--bs-primary);
  --bs-alert-link-color: #FFFFFF;
}
.alert-primary .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-primary hr {
  color: #1c4ce8;
}

.alert-primary-desat {
  --bs-alert-color: #FFFFFF;
  --bs-alert-bg: var(--bs-primary-desat);
  --bs-alert-border-color: var(--bs-primary-desat);
  --bs-alert-link-color: #FFFFFF;
}
.alert-primary-desat .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-primary-desat hr {
  color: #5578e9;
}

.alert-secondary {
  --bs-alert-color: #FFFFFF;
  --bs-alert-bg: var(--bs-secondary);
  --bs-alert-border-color: var(--bs-secondary);
  --bs-alert-link-color: #FFFFFF;
}
.alert-secondary .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-secondary hr {
  color: #475a80;
}

.alert-success {
  --bs-alert-color: #FFFFFF;
  --bs-alert-bg: var(--bs-success);
  --bs-alert-border-color: var(--bs-success);
  --bs-alert-link-color: #FFFFFF;
}
.alert-success .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-success hr {
  color: #3ba787;
}

.alert-info {
  --bs-alert-color: #FFFFFF;
  --bs-alert-bg: var(--bs-info);
  --bs-alert-border-color: var(--bs-info);
  --bs-alert-link-color: #FFFFFF;
}
.alert-info .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-info hr {
  color: #7c3d9a;
}

.alert-warning {
  --bs-alert-color: #1B2A4E;
  --bs-alert-bg: var(--bs-warning);
  --bs-alert-border-color: var(--bs-warning);
  --bs-alert-link-color: #1B2A4E;
}
.alert-warning .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-warning hr {
  color: #f9d05d;
}

.alert-danger {
  --bs-alert-color: #FFFFFF;
  --bs-alert-bg: var(--bs-danger);
  --bs-alert-border-color: var(--bs-danger);
  --bs-alert-link-color: #FFFFFF;
}
.alert-danger .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-danger hr {
  color: #db3145;
}

.alert-light {
  --bs-alert-color: #1B2A4E;
  --bs-alert-bg: var(--bs-light);
  --bs-alert-border-color: var(--bs-light);
  --bs-alert-link-color: #1B2A4E;
}
.alert-light .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-light hr {
  color: #e6eef7;
}

.alert-dark {
  --bs-alert-color: #FFFFFF;
  --bs-alert-bg: var(--bs-dark);
  --bs-alert-border-color: var(--bs-dark);
  --bs-alert-link-color: #FFFFFF;
}
.alert-dark .btn-close {
  color: var(--bs-alert-link-color);
}
.alert-dark hr {
  color: #14203b;
}

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.list-group-flush .list-group-item:first-child {
  padding-top: 0 !important;
  border-top: 0;
}
.list-group-flush .list-group-item:last-child {
  padding-bottom: 0 !important;
  border-bottom: 0;
}
.list-group-flush .list-group-item h6, .list-group-flush .list-group-item .h6 {
  color: #335EEA;
  font-weight: 600;
}

.dropdown-link {
  display: flex;
  flex: 1;
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  border-radius: 8px;
}

.dropdown-link:hover {
  background-color: #D9E2EF;
}

.modal-open .navbar.fixed-top {
  padding-right: inherit;
}

.modal .btn-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1000;
}

.popover-header {
  padding-bottom: 0;
  margin-bottom: 0.5rem;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
}

.popover-header:not(:empty) ~ .popover-body {
  padding-top: 0;
}

.avatar {
  --bs-avatar-size: 1.875rem;
  --bs-avatar-title-bg: var(--bs-gray-500);
  --bs-avatar-title-color: var(--bs-white);
  position: relative;
  display: inline-block;
  width: var(--bs-avatar-size);
  height: var(--bs-avatar-size);
  font-size: calc(var(--bs-avatar-size) / 3);
}
.avatar:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg);
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--bs-avatar-title-bg);
  color: var(--bs-avatar-title-color);
}

.avatar-online::before,
.avatar-offline::before {
  content: "";
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border-radius: 50%;
}
.avatar-online .avatar-img,
.avatar-offline .avatar-img {
  mask-image: url(../img/masks/avatar-status.svg);
  mask-size: 100% 100%;
}

.avatar-online::before {
  background-color: var(--bs-success);
}

.avatar-offline::before {
  background-color: var(--bs-gray-500);
}

.avatar-xs {
  --bs-avatar-size: 1rem;
}

.avatar-sm {
  --bs-avatar-size: 1.5rem;
}

.avatar-lg {
  --bs-avatar-size: 2.25rem;
}

.avatar-xl {
  --bs-avatar-size: 2.25rem;
}
@media (min-width: 992px) {
  .avatar-xl {
    --bs-avatar-size: 4rem;
  }
}

.avatar-xxl {
  --bs-avatar-size: 4rem;
}
@media (min-width: 992px) {
  .avatar-xxl {
    --bs-avatar-size: 5rem;
  }
}

.avatar.avatar-4by3 {
  width: calc(var(--bs-avatar-size) * 4 / 3);
}

.avatar-group {
  display: inline-flex;
}
.avatar-group .avatar + .avatar {
  margin-left: calc(var(--bs-avatar-size) * -.25);
}
.avatar-group .avatar:not(:last-child) {
  mask-image: url(../img/masks/avatar-group.svg);
  mask-size: 100% 100%;
}
.avatar-group .avatar:hover {
  mask-image: none;
  z-index: 1;
}
.avatar-group .avatar:hover + .avatar {
  mask-image: url(../img/masks/avatar-group-hover.svg);
  mask-size: 100% 100%;
}
.avatar-group .avatar:hover + .avatar:last-child {
  mask-image: url(../img/masks/avatar-group-hover-last.svg);
}

.device {
  position: relative;
}
.device > .img-fluid {
  position: relative;
}

.device::before {
  content: "";
  background-color: var(--bs-light);
}

.device::before,
.device-screen {
  position: absolute;
  object-fit: cover;
}

.device-iphonex::before,
.device-iphonex > .device-screen {
  top: 7.784431138%;
  left: 16.4021164%;
  width: 66.137566137%;
  height: 80.838323353%;
}

.device-macbook::before,
.device-macbook > .device-screen {
  top: 11.53846154%;
  left: 13.38709677%;
  width: 73.548387096%;
  height: 73.076923076%;
}

.device-hmi::before,
.device-hmi > .device-screen {
  top: 11.53846154%;
  left: 13.901%;
  width: 76.3%;
  height: 73.076923076%;
}

.device-combo {
  position: relative;
}

.device-combo > .device {
  position: absolute;
}

.device-combo-iphonex-iphonex {
  padding-bottom: 130.250482%;
}

.device-combo-iphonex-iphonex > .device-iphonex:first-child {
  bottom: 0;
  left: 0;
  width: 65.5260116%;
  z-index: 1;
}
.device-combo-iphonex-iphonex > .device-iphonex:last-child {
  top: 0;
  right: 0;
  width: 72.8323699%;
}

.device-combo-macbook-iphonex,
.device-combo-iphonex-macbook {
  padding-bottom: 62.4260355%;
}

.device-combo-macbook-iphonex > .device-macbook,
.device-combo-iphonex-macbook > .device-macbook {
  width: 91.7159763%;
}

.device-combo-macbook-iphonex > .device-iphonex,
.device-combo-iphonex-macbook > .device-iphonex {
  width: 27.9585799%;
  z-index: 1;
}

.device-combo-macbook-iphonex > .device-macbook {
  top: 0;
  left: 0;
}

.device-combo-iphonex-macbook > .device-macbook {
  top: 0;
  right: 0;
}

.device-combo-macbook-iphonex > .device-iphonex {
  bottom: 0;
  right: 0;
}

.device-combo-iphonex-macbook > .device-iphonex {
  bottom: 0;
  left: 0;
}

.bg-dark .footer-brand {
  filter: brightness(0) invert(1);
}

.icon {
  --bs-icon-size: 3rem;
}

.icon > svg {
  width: var(--bs-icon-size);
  height: var(--bs-icon-size);
}

.icon[class*=text-] > svg [fill]:not([fill=none]) {
  fill: currentColor !important;
}

.icon-xs {
  --bs-icon-size: 1.5rem;
}

.icon-sm {
  --bs-icon-size: 2.25rem;
}

.icon-lg {
  --bs-icon-size: 4rem;
}

.icon-xl {
  --bs-icon-size: 5rem;
}

.icon-circle {
  --bs-icon-circle-size: 4.1875rem;
  --bs-icon-circle-font-size: 1.875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--bs-icon-circle-size);
  height: var(--bs-icon-circle-size);
  border-radius: 50%;
}
.icon-circle > .fe {
  font-size: var(--bs-icon-circle-font-size);
}

.list {
  --bs-list-item-spacing-y: 0.35rem;
  --bs-list-link-font-size: 0.9375rem;
  --bs-list-link-color: var(--bs-gray-700);
  --bs-list-link-hover-color: var(--bs-primary);
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.list-item + .list-item {
  margin-top: var(--bs-list-item-spacing-y);
}

.list-link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: var(--bs-list-link-font-size);
  color: var(--bs-list-link-color);
}
.list-link:hover, .list-link:focus {
  text-decoration: none;
  color: var(--bs-list-link-hover-color);
}

.screenshot {
  --bs-screenshot-border-radius: 0.625rem;
  --bs-screenshot-box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1), 16px 40px 75px -40px rgba(0, 0, 0, 0.2);
  border-radius: var(--bs-screenshot-border-radius);
  box-shadow: var(--bs-screenshot-box-shadow);
}

.section-border {
  position: relative;
}

.section-border::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-width: calc(var(--bs-border-width) * 2) 0 0 0;
  border-style: solid;
  border-color: inherit;
  content: "";
}

.shape {
  position: absolute;
  pointer-events: none;
}

.shape > * {
  display: block;
}

.shape:not([class*=shape-blur]) {
  overflow: hidden;
}

.shape:not([class*=shape-blur]) > * {
  transform: scale(2);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .shape > svg {
    display: none;
  }
}
.shape-top {
  top: 0;
  right: 0;
  left: 0;
}

.shape-top > * {
  transform-origin: bottom center;
}

.shape-end {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.shape-end > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  right: inherit;
  transform-origin: center left;
}

.shape-bottom {
  right: 0;
  bottom: 0;
  left: 0;
}

.shape-bottom > * {
  transform-origin: top center;
}

.shape-bottom-100 {
  right: 0;
  bottom: 100%;
  left: 0;
}

.shape-bottom-100 > * {
  transform-origin: top center;
}

.shape-start {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.shape-start > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  left: inherit;
  transform-origin: center right;
}

.shape-fluid-x > * {
  width: 100%;
  height: auto;
}

.shape-fluid-y > * {
  width: auto;
  height: 100%;
}

.shape-flip-x {
  transform: scaleX(-1);
}

.shape-blur-1 {
  top: 0;
  right: 0;
  left: 0;
}
@media (min-width: 992px) {
  .shape-blur-1 {
    bottom: 0;
    left: 50%;
    right: auto;
  }
}

.shape-blur-1 > * {
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .shape-blur-1 > * {
    width: auto;
    height: 100%;
  }
}

.shape-blur-2 {
  top: 0;
  right: 0;
  left: 0;
}
@media (min-width: 992px) {
  .shape-blur-2 {
    bottom: 0;
    right: 50%;
    left: auto;
  }
}

.shape-blur-2 > * {
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .shape-blur-2 > * {
    width: auto;
    height: 100%;
    float: right;
  }
}

.shape-blur-3 {
  top: 0;
  right: 0;
  left: 0;
}

.shape-blur-3 > * {
  width: 100%;
  height: auto;
}

.shape-blur-4 {
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) scale(1.3);
}

.shape-blur-4 > * {
  width: auto;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .sidenav {
    position: fixed;
    top: 84px;
    height: calc(100vh - 84px);
    overflow: auto;
  }
}

@media (min-width: 992px) {
  .sidenav-start {
    left: 0;
  }
}

@media (min-width: 992px) {
  .sidenav-end {
    right: 0;
  }
}

[data-aos=img-skewed-item-start],
[data-aos=img-skewed-item-end] {
  opacity: 0;
  transition-property: opacity, transform, -webkit-transform;
  will-change: opacity, transform, -webkit-transform;
  backface-visibility: hidden;
}
[data-aos=img-skewed-item-start].aos-animate,
[data-aos=img-skewed-item-end].aos-animate {
  opacity: 1;
}

[data-aos=img-skewed-item-start] {
  transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0);
}
[data-aos=img-skewed-item-start].aos-animate {
  transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0);
}

[data-aos=img-skewed-item-end] {
  transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0);
}
[data-aos=img-skewed-item-end].aos-animate {
  transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0);
}

#bp_container {
  background: rgba(255, 255, 255, 0.873) !important;
}

#bp_container > * {
  box-shadow: none !important;
}

.bp-x {
  display: none;
}

.choices {
  position: relative;
  cursor: pointer;
}

.choices:focus {
  outline: none;
}

.choices::after {
  display: none;
}

.choices > .dropdown-menu {
  width: 100%;
}

.choices > .dropdown-menu [class^=form-control] {
  width: 100%;
  margin-bottom: 1rem;
}

.choices > .dropdown-menu .dropdown-item.is-highlighted {
  color: var(--bs-dropdown-link-hover-color);
}

.choices > .form-control-xs + .dropdown-menu,
.choices > .form-select-xs + .dropdown-menu {
  min-width: 100%;
  width: auto;
  padding: 1rem 1.25rem;
}

.choices > .form-select-xs + .dropdown-menu,
.choices > .form-control-xs + .dropdown-menu {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.choices > .form-select-lg + .dropdown-menu,
.choices > .form-control-lg + .dropdown-menu {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.choices__placeholder {
  color: #869AB8;
  opacity: 1;
}

.choices.is-open > .form-control,
.choices.is-open > .form-select {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.choices.is-open > .form-control + .dropdown-menu,
.choices.is-open > .form-select + .dropdown-menu {
  margin-top: calc(0px * -1);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.choices__list--multiple + .form-control {
  display: inline-block;
  min-height: 0;
  width: auto;
  padding: 0;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 1.0625rem;
}

.form-control-lg + .choices__list--multiple + .form-control,
.form-select-lg + .choices__list--multiple + .form-control {
  font-size: 1.0625rem;
}

.form-control-sm + .choices__list--multiple + .form-control,
.form-select-sm + .choices__list--multiple + .form-control {
  font-size: 1.0625rem;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple > .choices__item {
  display: inline-block;
  margin-right: 0.2rem;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  font-size: 0.9375rem;
  background-color: var(--bs-light);
  border-radius: var(--bs-border-radius-sm);
}

.choices__list--multiple > .choices__item > .choices__button {
  width: 0.5rem;
  margin-left: 0.5rem;
  padding: 0;
  outline: none;
  border-width: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='var%28--bs-body-color%29' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
  background-size: 100% auto;
  text-indent: -9999px;
  opacity: 0.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.choices__list--multiple > .choices__item:hover > .choices__button {
  opacity: 1;
}

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column;
}

.dz-message {
  padding: 5rem 1rem;
  background-color: var(--bs-white);
  border: 1px dashed var(--bs-gray-400);
  border-radius: var(--bs-border-radius);
  text-align: center;
  color: #869AB8;
  transition: all 0.2s ease-in-out;
  order: -1;
  cursor: pointer;
  z-index: 999;
}
.dz-message:hover {
  border-color: #869AB8;
  color: var(--bs-body-color);
}

.dz-button {
  background: none;
  border: 0;
  font-size: inherit;
  color: inherit;
}

.dz-drag-hover .dz-message {
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(22, 28, 45, 0.9);
  color: white;
  opacity: 0;
}
.dropzone-single.dz-max-files-reached .dz-message:hover {
  opacity: 1;
}

.dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--bs-border-radius);
}

.dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--bs-border-radius);
}

.dz-preview-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: var(--bs-border-radius);
}

.dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

[data-dz-size] strong {
  font-weight: 400;
}

.fe {
  font-family: "Feather" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fe-activity:before {
  content: "\e900";
}

.fe-airplay:before {
  content: "\e901";
}

.fe-alert-circle:before {
  content: "\e902";
}

.fe-alert-octagon:before {
  content: "\e903";
}

.fe-alert-triangle:before {
  content: "\e904";
}

.fe-align-center:before {
  content: "\e905";
}

.fe-align-justify:before {
  content: "\e906";
}

.fe-align-left:before {
  content: "\e907";
}

.fe-align-right:before {
  content: "\e908";
}

.fe-anchor:before {
  content: "\e909";
}

.fe-aperture:before {
  content: "\e90a";
}

.fe-archive:before {
  content: "\e90b";
}

.fe-arrow-down:before {
  content: "\e90c";
}

.fe-arrow-down-circle:before {
  content: "\e90d";
}

.fe-arrow-down-left:before {
  content: "\e90e";
}

.fe-arrow-down-right:before {
  content: "\e90f";
}

.fe-arrow-left:before {
  content: "\e910";
}

.fe-arrow-left-circle:before {
  content: "\e911";
}

.fe-arrow-right:before {
  content: "\e912";
}

.fe-arrow-right-circle:before {
  content: "\e913";
}

.fe-arrow-up:before {
  content: "\e914";
}

.fe-arrow-up-circle:before {
  content: "\e915";
}

.fe-arrow-up-left:before {
  content: "\e916";
}

.fe-arrow-up-right:before {
  content: "\e917";
}

.fe-at-sign:before {
  content: "\e918";
}

.fe-award:before {
  content: "\e919";
}

.fe-bar-chart:before {
  content: "\e91a";
}

.fe-bar-chart-2:before {
  content: "\e91b";
}

.fe-battery:before {
  content: "\e91c";
}

.fe-battery-charging:before {
  content: "\e91d";
}

.fe-bell:before {
  content: "\e91e";
}

.fe-bell-off:before {
  content: "\e91f";
}

.fe-bluetooth:before {
  content: "\e920";
}

.fe-bold:before {
  content: "\e921";
}

.fe-book:before {
  content: "\e922";
}

.fe-book-open:before {
  content: "\e923";
}

.fe-bookmark:before {
  content: "\e924";
}

.fe-box:before {
  content: "\e925";
}

.fe-briefcase:before {
  content: "\e926";
}

.fe-calendar:before {
  content: "\e927";
}

.fe-camera:before {
  content: "\e928";
}

.fe-camera-off:before {
  content: "\e929";
}

.fe-cast:before {
  content: "\e92a";
}

.fe-check:before {
  content: "\e92b";
}

.fe-check-circle:before {
  content: "\e92c";
}

.fe-check-square:before {
  content: "\e92d";
}

.fe-chevron-down:before {
  content: "\e92e";
}

.fe-chevron-left:before {
  content: "\e92f";
}

.fe-chevron-right:before {
  content: "\e930";
}

.fe-chevron-up:before {
  content: "\e931";
}

.fe-chevrons-down:before {
  content: "\e932";
}

.fe-chevrons-left:before {
  content: "\e933";
}

.fe-chevrons-right:before {
  content: "\e934";
}

.fe-chevrons-up:before {
  content: "\e935";
}

.fe-chrome:before {
  content: "\e936";
}

.fe-circle:before {
  content: "\e937";
}

.fe-clipboard:before {
  content: "\e938";
}

.fe-clock:before {
  content: "\e939";
}

.fe-cloud:before {
  content: "\e93a";
}

.fe-cloud-drizzle:before {
  content: "\e93b";
}

.fe-cloud-lightning:before {
  content: "\e93c";
}

.fe-cloud-off:before {
  content: "\e93d";
}

.fe-cloud-rain:before {
  content: "\e93e";
}

.fe-cloud-snow:before {
  content: "\e93f";
}

.fe-code:before {
  content: "\e940";
}

.fe-codepen:before {
  content: "\e941";
}

.fe-codesandbox:before {
  content: "\e942";
}

.fe-coffee:before {
  content: "\e943";
}

.fe-columns:before {
  content: "\e944";
}

.fe-command:before {
  content: "\e945";
}

.fe-compass:before {
  content: "\e946";
}

.fe-copy:before {
  content: "\e947";
}

.fe-corner-down-left:before {
  content: "\e948";
}

.fe-corner-down-right:before {
  content: "\e949";
}

.fe-corner-left-down:before {
  content: "\e94a";
}

.fe-corner-left-up:before {
  content: "\e94b";
}

.fe-corner-right-down:before {
  content: "\e94c";
}

.fe-corner-right-up:before {
  content: "\e94d";
}

.fe-corner-up-left:before {
  content: "\e94e";
}

.fe-corner-up-right:before {
  content: "\e94f";
}

.fe-cpu:before {
  content: "\e950";
}

.fe-credit-card:before {
  content: "\e951";
}

.fe-crop:before {
  content: "\e952";
}

.fe-crosshair:before {
  content: "\e953";
}

.fe-database:before {
  content: "\e954";
}

.fe-delete:before {
  content: "\e955";
}

.fe-disc:before {
  content: "\e956";
}

.fe-dollar-sign:before {
  content: "\e957";
}

.fe-download:before {
  content: "\e958";
}

.fe-download-cloud:before {
  content: "\e959";
}

.fe-droplet:before {
  content: "\e95a";
}

.fe-edit:before {
  content: "\e95b";
}

.fe-edit-2:before {
  content: "\e95c";
}

.fe-edit-3:before {
  content: "\e95d";
}

.fe-external-link:before {
  content: "\e95e";
}

.fe-eye:before {
  content: "\e95f";
}

.fe-eye-off:before {
  content: "\e960";
}

.fe-facebook:before {
  content: "\e961";
}

.fe-fast-forward:before {
  content: "\e962";
}

.fe-feather:before {
  content: "\e963";
}

.fe-figma:before {
  content: "\e964";
}

.fe-file:before {
  content: "\e965";
}

.fe-file-minus:before {
  content: "\e966";
}

.fe-file-plus:before {
  content: "\e967";
}

.fe-file-text:before {
  content: "\e968";
}

.fe-film:before {
  content: "\e969";
}

.fe-filter:before {
  content: "\e96a";
}

.fe-flag:before {
  content: "\e96b";
}

.fe-folder:before {
  content: "\e96c";
}

.fe-folder-minus:before {
  content: "\e96d";
}

.fe-folder-plus:before {
  content: "\e96e";
}

.fe-framer:before {
  content: "\e96f";
}

.fe-frown:before {
  content: "\e970";
}

.fe-gift:before {
  content: "\e971";
}

.fe-git-branch:before {
  content: "\e972";
}

.fe-git-commit:before {
  content: "\e973";
}

.fe-git-merge:before {
  content: "\e974";
}

.fe-git-pull-request:before {
  content: "\e975";
}

.fe-github:before {
  content: "\e976";
}

.fe-gitlab:before {
  content: "\e977";
}

.fe-globe:before {
  content: "\e978";
}

.fe-grid:before {
  content: "\e979";
}

.fe-hard-drive:before {
  content: "\e97a";
}

.fe-hash:before {
  content: "\e97b";
}

.fe-headphones:before {
  content: "\e97c";
}

.fe-heart:before {
  content: "\e97d";
}

.fe-help-circle:before {
  content: "\e97e";
}

.fe-hexagon:before {
  content: "\e97f";
}

.fe-home:before {
  content: "\e980";
}

.fe-image:before {
  content: "\e981";
}

.fe-inbox:before {
  content: "\e982";
}

.fe-info:before {
  content: "\e983";
}

.fe-instagram:before {
  content: "\e984";
}

.fe-italic:before {
  content: "\e985";
}

.fe-key:before {
  content: "\e986";
}

.fe-layers:before {
  content: "\e987";
}

.fe-layout:before {
  content: "\e988";
}

.fe-life-buoy:before {
  content: "\e989";
}

.fe-link:before {
  content: "\e98a";
}

.fe-link-2:before {
  content: "\e98b";
}

.fe-linkedin:before {
  content: "\e98c";
}

.fe-list:before {
  content: "\e98d";
}

.fe-loader:before {
  content: "\e98e";
}

.fe-lock:before {
  content: "\e98f";
}

.fe-log-in:before {
  content: "\e990";
}

.fe-log-out:before {
  content: "\e991";
}

.fe-mail:before {
  content: "\e992";
}

.fe-map:before {
  content: "\e993";
}

.fe-map-pin:before {
  content: "\e994";
}

.fe-maximize:before {
  content: "\e995";
}

.fe-maximize-2:before {
  content: "\e996";
}

.fe-meh:before {
  content: "\e997";
}

.fe-menu:before {
  content: "\e998";
}

.fe-message-circle:before {
  content: "\e999";
}

.fe-message-square:before {
  content: "\e99a";
}

.fe-mic:before {
  content: "\e99b";
}

.fe-mic-off:before {
  content: "\e99c";
}

.fe-minimize:before {
  content: "\e99d";
}

.fe-minimize-2:before {
  content: "\e99e";
}

.fe-minus:before {
  content: "\e99f";
}

.fe-minus-circle:before {
  content: "\e9a0";
}

.fe-minus-square:before {
  content: "\e9a1";
}

.fe-monitor:before {
  content: "\e9a2";
}

.fe-moon:before {
  content: "\e9a3";
}

.fe-more-horizontal:before {
  content: "\e9a4";
}

.fe-more-vertical:before {
  content: "\e9a5";
}

.fe-mouse-pointer:before {
  content: "\e9a6";
}

.fe-move:before {
  content: "\e9a7";
}

.fe-music:before {
  content: "\e9a8";
}

.fe-navigation:before {
  content: "\e9a9";
}

.fe-navigation-2:before {
  content: "\e9aa";
}

.fe-octagon:before {
  content: "\e9ab";
}

.fe-package:before {
  content: "\e9ac";
}

.fe-paperclip:before {
  content: "\e9ad";
}

.fe-pause:before {
  content: "\e9ae";
}

.fe-pause-circle:before {
  content: "\e9af";
}

.fe-pen-tool:before {
  content: "\e9b0";
}

.fe-percent:before {
  content: "\e9b1";
}

.fe-phone:before {
  content: "\e9b2";
}

.fe-phone-call:before {
  content: "\e9b3";
}

.fe-phone-forwarded:before {
  content: "\e9b4";
}

.fe-phone-incoming:before {
  content: "\e9b5";
}

.fe-phone-missed:before {
  content: "\e9b6";
}

.fe-phone-off:before {
  content: "\e9b7";
}

.fe-phone-outgoing:before {
  content: "\e9b8";
}

.fe-pie-chart:before {
  content: "\e9b9";
}

.fe-play:before {
  content: "\e9ba";
}

.fe-play-circle:before {
  content: "\e9bb";
}

.fe-plus:before {
  content: "\e9bc";
}

.fe-plus-circle:before {
  content: "\e9bd";
}

.fe-plus-square:before {
  content: "\e9be";
}

.fe-pocket:before {
  content: "\e9bf";
}

.fe-power:before {
  content: "\e9c0";
}

.fe-printer:before {
  content: "\e9c1";
}

.fe-radio:before {
  content: "\e9c2";
}

.fe-refresh-ccw:before {
  content: "\e9c3";
}

.fe-refresh-cw:before {
  content: "\e9c4";
}

.fe-repeat:before {
  content: "\e9c5";
}

.fe-rewind:before {
  content: "\e9c6";
}

.fe-rotate-ccw:before {
  content: "\e9c7";
}

.fe-rotate-cw:before {
  content: "\e9c8";
}

.fe-rss:before {
  content: "\e9c9";
}

.fe-save:before {
  content: "\e9ca";
}

.fe-scissors:before {
  content: "\e9cb";
}

.fe-search:before {
  content: "\e9cc";
}

.fe-send:before {
  content: "\e9cd";
}

.fe-server:before {
  content: "\e9ce";
}

.fe-settings:before {
  content: "\e9cf";
}

.fe-share:before {
  content: "\e9d0";
}

.fe-share-2:before {
  content: "\e9d1";
}

.fe-shield:before {
  content: "\e9d2";
}

.fe-shield-off:before {
  content: "\e9d3";
}

.fe-shopping-bag:before {
  content: "\e9d4";
}

.fe-shopping-cart:before {
  content: "\e9d5";
}

.fe-shuffle:before {
  content: "\e9d6";
}

.fe-sidebar:before {
  content: "\e9d7";
}

.fe-skip-back:before {
  content: "\e9d8";
}

.fe-skip-forward:before {
  content: "\e9d9";
}

.fe-slack:before {
  content: "\e9da";
}

.fe-slash:before {
  content: "\e9db";
}

.fe-sliders:before {
  content: "\e9dc";
}

.fe-smartphone:before {
  content: "\e9dd";
}

.fe-smile:before {
  content: "\e9de";
}

.fe-speaker:before {
  content: "\e9df";
}

.fe-square:before {
  content: "\e9e0";
}

.fe-star:before {
  content: "\e9e1";
}

.fe-stop-circle:before {
  content: "\e9e2";
}

.fe-sun:before {
  content: "\e9e3";
}

.fe-sunrise:before {
  content: "\e9e4";
}

.fe-sunset:before {
  content: "\e9e5";
}

.fe-tablet:before {
  content: "\e9e6";
}

.fe-tag:before {
  content: "\e9e7";
}

.fe-target:before {
  content: "\e9e8";
}

.fe-terminal:before {
  content: "\e9e9";
}

.fe-thermometer:before {
  content: "\e9ea";
}

.fe-thumbs-down:before {
  content: "\e9eb";
}

.fe-thumbs-up:before {
  content: "\e9ec";
}

.fe-toggle-left:before {
  content: "\e9ed";
}

.fe-toggle-right:before {
  content: "\e9ee";
}

.fe-tool:before {
  content: "\e9ef";
}

.fe-trash:before {
  content: "\e9f0";
}

.fe-trash-2:before {
  content: "\e9f1";
}

.fe-trello:before {
  content: "\e9f2";
}

.fe-trending-down:before {
  content: "\e9f3";
}

.fe-trending-up:before {
  content: "\e9f4";
}

.fe-triangle:before {
  content: "\e9f5";
}

.fe-truck:before {
  content: "\e9f6";
}

.fe-tv:before {
  content: "\e9f7";
}

.fe-twitch:before {
  content: "\e9f8";
}

.fe-twitter:before {
  content: "\e9f9";
}

.fe-type:before {
  content: "\e9fa";
}

.fe-umbrella:before {
  content: "\e9fb";
}

.fe-underline:before {
  content: "\e9fc";
}

.fe-unlock:before {
  content: "\e9fd";
}

.fe-upload:before {
  content: "\e9fe";
}

.fe-upload-cloud:before {
  content: "\e9ff";
}

.fe-user:before {
  content: "\ea00";
}

.fe-user-check:before {
  content: "\ea01";
}

.fe-user-minus:before {
  content: "\ea02";
}

.fe-user-plus:before {
  content: "\ea03";
}

.fe-user-x:before {
  content: "\ea04";
}

.fe-users:before {
  content: "\ea05";
}

.fe-video:before {
  content: "\ea06";
}

.fe-video-off:before {
  content: "\ea07";
}

.fe-voicemail:before {
  content: "\ea08";
}

.fe-volume:before {
  content: "\ea09";
}

.fe-volume-1:before {
  content: "\ea0a";
}

.fe-volume-2:before {
  content: "\ea0b";
}

.fe-volume-x:before {
  content: "\ea0c";
}

.fe-watch:before {
  content: "\ea0d";
}

.fe-wifi:before {
  content: "\ea0e";
}

.fe-wifi-off:before {
  content: "\ea0f";
}

.fe-wind:before {
  content: "\ea10";
}

.fe-x:before {
  content: "\ea11";
}

.fe-x-circle:before {
  content: "\ea12";
}

.fe-x-octagon:before {
  content: "\ea13";
}

.fe-x-square:before {
  content: "\ea14";
}

.fe-youtube:before {
  content: "\ea15";
}

.fe-zap:before {
  content: "\ea16";
}

.fe-zap-off:before {
  content: "\ea17";
}

.fe-zoom-in:before {
  content: "\ea18";
}

.fe-zoom-out:before {
  content: "\ea19";
}

.fe-lg {
  font-size: 1.3125rem;
}
@media (min-width: 992px) {
  .fe-lg {
    font-size: 1.125rem;
  }
}

.flickity-prev-next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(1.6em + 1.625rem + calc(1px * 2));
  height: calc(1.6em + 1.625rem + calc(1px * 2));
  background-color: var(--bs-primary);
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05);
  color: var(--bs-white);
}
.flickity-prev-next-button::before, .flickity-prev-next-button::after {
  font-family: "Feather";
}
.flickity-prev-next-button:hover, .flickity-prev-next-button:focus {
  background-color: var(--bs-primary);
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
}

.flickity-prev-next-button.previous {
  left: 0;
  transform: translate(calc(-50% + 0.625rem), -50%);
}
@media (min-width: 992px) {
  .flickity-prev-next-button.previous {
    transform: translate(-50%, -50%);
  }
}
.flickity-prev-next-button.previous::before {
  content: "\e910";
}

.flickity-prev-next-button.next {
  right: 0;
  transform: translate(calc(50% - 0.625rem), -50%);
}
@media (min-width: 992px) {
  .flickity-prev-next-button.next {
    transform: translate(50%, -50%);
  }
}
.flickity-prev-next-button.next::before {
  content: "\e912";
}

.flickity-button-icon {
  display: none;
}

.flickity-button-white .flickity-prev-next-button {
  background-color: var(--bs-white);
  color: var(--bs-primary);
}

.flickity-button-bottom .flickity-prev-next-button {
  top: auto;
  bottom: 1.5rem;
  transform: none;
}

.flickity-button-bottom .flickity-prev-next-button.previous {
  left: auto;
  right: 6.375rem;
}

.flickity-button-bottom .flickity-prev-next-button.next {
  left: auto;
  right: 2.5rem;
}

.flickity-button-inset .flickity-prev-next-button {
  transform: translateY(-50%);
}

.flickity-button-inset .flickity-prev-next-button.previous {
  left: 1.5rem;
}

.flickity-button-inset .flickity-prev-next-button.next {
  right: 1.5rem;
}

.flickity-viewport-visible .flickity-viewport {
  overflow: visible;
}

[data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
  transition: all 0.5s ease;
}

.flickity-slider > [class*=col] {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.hljs {
  background-color: transparent;
}

.ql-container {
  font-family: "Outfit", serif;
}

.ql-toolbar {
  position: relative;
  padding: 0.8125rem 1.25rem;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
  color: var(--bs-body-color);
}

.ql-toolbar + .ql-container {
  margin-top: calc(1px * -1);
}

.ql-toolbar + .ql-container .ql-editor {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ql-editor {
  min-height: 6.8rem;
  display: block;
  width: 100%;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: var(--bs-white);
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: 0 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .ql-editor {
    transition: none;
  }
}
.ql-editor::-ms-expand {
  background-color: transparent;
  border: 0;
}
.ql-editor:focus {
  border-color: var(--bs-primary);
}

.ql-hidden {
  position: absolute;
  transform: scale(0);
}

.ql-editor.ql-blank::before {
  top: 0.8125rem;
  left: 1.25rem;
  font-style: normal;
  color: #869AB8;
}

.ql-editor:focus::before {
  display: none;
}

.ql-formats {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.ql-formats:first-child {
  padding-left: 0;
}
.ql-formats:last-child {
  padding-right: 0;
}

.ql-toolbar button {
  padding: 0 0.25rem;
  background: none;
  border: none;
  color: var(--bs-body-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.ql-toolbar button:hover {
  color: var(--bs-primary);
}
.ql-toolbar button:first-child {
  margin-left: -0.25rem;
}

.ql-toolbar .ql-active {
  color: var(--bs-primary);
}

.ql-toolbar button svg {
  height: 1.1875rem;
  width: 1.1875rem;
}

.ql-toolbar .ql-stroke {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.ql-toolbar .ql-thin {
  stroke-width: 1;
}

.ql-toolbar .ql-fill {
  fill: currentColor;
}

.ql-toolbar input.ql-image {
  position: absolute;
  transform: scale(0);
}

.ql-tooltip {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  width: 18.5rem;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  padding: 0.8125rem 1.25rem;
  margin-top: 0.6rem;
  box-shadow: var(--bs-popover-box-shadow);
}
.ql-tooltip:before, .ql-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
}
.ql-tooltip:before {
  border-bottom: 0.6rem solid var(--bs-popover-border-color);
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
}
.ql-tooltip:after {
  border-bottom: 0.5rem solid var(--bs-popover-bg);
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}

.ql-container .ql-tooltip:hover {
  display: flex !important;
}

.ql-tooltip .ql-preview {
  width: 100%;
  --bs-btn-padding-y: 0.5625rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-border-radius: var(--bs-border-radius);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-preview {
    transition: none;
  }
}

.ql-tooltip.ql-editing .ql-preview {
  display: none;
}

.ql-tooltip input {
  display: none;
  width: 100%;
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.6;
  border: none;
  color: var(--bs-body-color);
}
.ql-tooltip input:focus {
  outline: none;
}

.ql-tooltip.ql-editing input {
  display: block;
}

.ql-tooltip .ql-action,
.ql-tooltip .ql-remove {
  margin-left: 0.25rem;
}

.ql-tooltip .ql-action::before,
.ql-tooltip .ql-remove::before {
  display: inline-block;
  font-weight: var(--bs-btn-font-weight);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: var(--bs-btn-border-width) solid transparent;
  cursor: pointer;
  --bs-btn-padding-y: 0.5625rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-border-radius: var(--bs-border-radius);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-action::before,
  .ql-tooltip .ql-remove::before {
    transition: none;
  }
}
.ql-tooltip .ql-action::before:hover, .ql-tooltip .ql-action::before:focus,
.ql-tooltip .ql-remove::before:hover,
.ql-tooltip .ql-remove::before:focus {
  text-decoration: none;
}
.ql-tooltip .ql-action::before:focus, .ql-tooltip .ql-action::before.focus,
.ql-tooltip .ql-remove::before:focus,
.ql-tooltip .ql-remove::before.focus {
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.ql-tooltip .ql-action::before,
.ql-tooltip.ql-editing .ql-action::before {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #335EEA;
  --bs-btn-border-color: #335EEA;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #2b50c7;
  --bs-btn-hover-border-color: #294bbb;
  --bs-btn-focus-shadow-rgb: 82, 118, 237;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #294bbb;
  --bs-btn-active-border-color: #2647b0;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #335EEA;
  --bs-btn-disabled-border-color: #335EEA;
}

.ql-tooltip .ql-action::before {
  content: "Edit";
}

.ql-tooltip.ql-editing .ql-action::before {
  content: "Save";
}

.ql-tooltip .ql-remove::before {
  --bs-btn-color: #1B2A4E;
  --bs-btn-bg: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #1B2A4E;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 221, 223, 228;
  --bs-btn-active-color: #1B2A4E;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: 0 0;
  --bs-btn-disabled-color: #1B2A4E;
  --bs-btn-disabled-bg: #FFFFFF;
  --bs-btn-disabled-border-color: #FFFFFF;
  content: "Remove";
  border-color: var(--bs-gray-300);
}

.ql-tooltip.ql-editing .ql-remove::before {
  display: none;
}

.ql-editor blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
}

.text-bg-primary-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-primary-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-primary-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-primary-desat-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-primary-desat-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-primary-desat-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-secondary-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-secondary-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-secondary-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-success-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-success-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-success-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-info-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-info-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-info-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-warning-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-warning-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-warning-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-danger-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-danger-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-danger-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-light-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-light-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-light-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-dark-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-dark-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-dark-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-white-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-white-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-white-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-gray-700-subtle {
  --bs-bg-opacity: .1;
  color: RGBA(var(--bs-gray-700-bg-subtle-rgb), 1) !important;
  background-color: RGBA(var(--bs-gray-700-bg-subtle-rgb), var(--bs-bg-opacity, 0.1)) !important;
}

.text-bg-gray-100 {
  color: #1B2A4E !important;
  background-color: RGBA(var(--bs-gray-100-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-200 {
  color: #1B2A4E !important;
  background-color: RGBA(var(--bs-gray-200-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-300 {
  color: #1B2A4E !important;
  background-color: RGBA(var(--bs-gray-300-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-400 {
  color: #1B2A4E !important;
  background-color: RGBA(var(--bs-gray-400-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-500 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-500-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-600 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-600-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-700 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-700-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-800 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-800-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-900 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-900-rgb), var(--bs-bg-opacity, 1)) !important;
}

.steps {
  --bs-steps-padding-y: 1.5rem;
  --bs-steps-padding-x: 1.5rem;
  --bs-steps-number-size: 7.625rem;
  --bs-steps-number-inner-size: 5.625rem;
  --bs-steps-number-size-sm: 5rem;
  --bs-steps-number-inner-size-sm: 3.5rem;
  --bs-steps-number-border-radius: 50%;
  --bs-steps-number-bg: #D9E2EF;
  --bs-steps-number-inner-bg: #F1F4F8;
  --bs-steps-number-inner-box-shadow: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075);
  --bs-steps-number-font-size: 2.125rem;
  --bs-steps-number-font-size-sm: 1.59375rem;
  --bs-steps-number-color: #1B2A4E;
  --bs-steps-connect-width: var(--bs-border-width);
  --bs-steps-connect-color: #1B2A4E;
  display: flex;
  flex-direction: column;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--bs-steps-padding-y) 0;
}
.step:first-child {
  padding-top: 0 !important;
}
.step:last-child {
  padding-bottom: 0 !important;
}
.step::before, .step::after {
  position: absolute;
  left: calc(var(--bs-steps-number-size) * 0.5);
  width: var(--bs-steps-connect-width);
  height: 50%;
  content: "";
}
.step::before {
  top: 0;
}
.step::after {
  top: 50%;
}
.step:not(:first-child)::before {
  background-color: var(--bs-steps-connect-color);
}
.step:not(:last-child)::after {
  background-color: var(--bs-steps-connect-color);
}

.step-number {
  position: relative;
  width: var(--bs-steps-number-size);
  height: var(--bs-steps-number-size);
  flex-shrink: 0;
  padding-top: calc((var(--bs-steps-number-size) - var(--bs-steps-number-inner-size)) * 0.5);
  padding-left: calc((var(--bs-steps-number-size) - var(--bs-steps-number-inner-size)) * 0.5);
  border-radius: var(--bs-steps-number-border-radius);
  background-color: var(--bs-steps-number-bg);
  color: var(--bs-steps-number-color);
  font-size: var(--bs-steps-number-font-size);
  font-weight: 600;
  z-index: 2;
}
.step-number .step-number-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--bs-steps-number-inner-size);
  height: var(--bs-steps-number-inner-size);
  border-radius: var(--bs-steps-number-border-radius);
  background-color: var(--bs-steps-number-inner-bg);
  box-shadow: var(--bs-steps-number-inner-box-shadow);
}

.step-body {
  padding-left: var(--bs-steps-padding-x);
}

.steps-sm .step::before,
.steps-sm .step::after {
  left: calc(var(--bs-steps-number-size-sm) * 0.5);
}
.steps-sm .step-number {
  width: var(--bs-steps-number-size-sm);
  height: var(--bs-steps-number-size-sm);
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  font-size: var(--bs-steps-number-font-size-sm);
}
.steps-sm .step-number .step-number-inner {
  width: var(--bs-steps-number-inner-size-sm);
  height: var(--bs-steps-number-inner-size-sm);
}

@media (min-width: 576px) {
  .steps-horizontal-sm.steps {
    flex-direction: row;
    margin-right: calc(var(--bs-steps-padding-x) * -1);
    margin-left: calc(var(--bs-steps-padding-x) * -1);
  }
  .steps-horizontal-sm .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--bs-steps-padding-x);
  }
  .steps-horizontal-sm .step::before, .steps-horizontal-sm .step::after {
    top: calc(var(--bs-steps-number-size) * 0.5);
    height: var(--bs-steps-connect-width);
  }
  .steps-horizontal-sm .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-sm .step::after {
    left: var(--bs-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-sm.steps-sm .step::before, .steps-horizontal-sm.steps-sm .step::after {
    top: calc(var(--bs-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-sm .step-number {
    margin-bottom: var(--bs-steps-padding-y);
  }
  .steps-horizontal-sm .step-body {
    padding-left: 0;
  }
  .steps-horizontal-sm.steps-center {
    text-align: center;
  }
  .steps-horizontal-sm.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-sm.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-sm.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-sm.steps-end {
    text-align: right;
  }
  .steps-horizontal-sm.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--bs-steps-padding-x));
  }
  .steps-horizontal-sm.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--bs-steps-padding-x);
  }
  .steps-horizontal-sm.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-sm.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .steps-horizontal-md.steps {
    flex-direction: row;
    margin-right: calc(var(--bs-steps-padding-x) * -1);
    margin-left: calc(var(--bs-steps-padding-x) * -1);
  }
  .steps-horizontal-md .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--bs-steps-padding-x);
  }
  .steps-horizontal-md .step::before, .steps-horizontal-md .step::after {
    top: calc(var(--bs-steps-number-size) * 0.5);
    height: var(--bs-steps-connect-width);
  }
  .steps-horizontal-md .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-md .step::after {
    left: var(--bs-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-md.steps-sm .step::before, .steps-horizontal-md.steps-sm .step::after {
    top: calc(var(--bs-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-md .step-number {
    margin-bottom: var(--bs-steps-padding-y);
  }
  .steps-horizontal-md .step-body {
    padding-left: 0;
  }
  .steps-horizontal-md.steps-center {
    text-align: center;
  }
  .steps-horizontal-md.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-md.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-md.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-md.steps-end {
    text-align: right;
  }
  .steps-horizontal-md.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--bs-steps-padding-x));
  }
  .steps-horizontal-md.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--bs-steps-padding-x);
  }
  .steps-horizontal-md.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-md.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .steps-horizontal-lg.steps {
    flex-direction: row;
    margin-right: calc(var(--bs-steps-padding-x) * -1);
    margin-left: calc(var(--bs-steps-padding-x) * -1);
  }
  .steps-horizontal-lg .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--bs-steps-padding-x);
  }
  .steps-horizontal-lg .step::before, .steps-horizontal-lg .step::after {
    top: calc(var(--bs-steps-number-size) * 0.5);
    height: var(--bs-steps-connect-width);
  }
  .steps-horizontal-lg .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-lg .step::after {
    left: var(--bs-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-lg.steps-sm .step::before, .steps-horizontal-lg.steps-sm .step::after {
    top: calc(var(--bs-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-lg .step-number {
    margin-bottom: var(--bs-steps-padding-y);
  }
  .steps-horizontal-lg .step-body {
    padding-left: 0;
  }
  .steps-horizontal-lg.steps-center {
    text-align: center;
  }
  .steps-horizontal-lg.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-lg.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-lg.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-lg.steps-end {
    text-align: right;
  }
  .steps-horizontal-lg.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--bs-steps-padding-x));
  }
  .steps-horizontal-lg.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--bs-steps-padding-x);
  }
  .steps-horizontal-lg.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-lg.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .steps-horizontal-xl.steps {
    flex-direction: row;
    margin-right: calc(var(--bs-steps-padding-x) * -1);
    margin-left: calc(var(--bs-steps-padding-x) * -1);
  }
  .steps-horizontal-xl .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--bs-steps-padding-x);
  }
  .steps-horizontal-xl .step::before, .steps-horizontal-xl .step::after {
    top: calc(var(--bs-steps-number-size) * 0.5);
    height: var(--bs-steps-connect-width);
  }
  .steps-horizontal-xl .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-xl .step::after {
    left: var(--bs-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-xl.steps-sm .step::before, .steps-horizontal-xl.steps-sm .step::after {
    top: calc(var(--bs-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-xl .step-number {
    margin-bottom: var(--bs-steps-padding-y);
  }
  .steps-horizontal-xl .step-body {
    padding-left: 0;
  }
  .steps-horizontal-xl.steps-center {
    text-align: center;
  }
  .steps-horizontal-xl.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-xl.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-xl.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-xl.steps-end {
    text-align: right;
  }
  .steps-horizontal-xl.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--bs-steps-padding-x));
  }
  .steps-horizontal-xl.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--bs-steps-padding-x);
  }
  .steps-horizontal-xl.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-xl.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  .steps-horizontal-xxl.steps {
    flex-direction: row;
    margin-right: calc(var(--bs-steps-padding-x) * -1);
    margin-left: calc(var(--bs-steps-padding-x) * -1);
  }
  .steps-horizontal-xxl .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--bs-steps-padding-x);
  }
  .steps-horizontal-xxl .step::before, .steps-horizontal-xxl .step::after {
    top: calc(var(--bs-steps-number-size) * 0.5);
    height: var(--bs-steps-connect-width);
  }
  .steps-horizontal-xxl .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-xxl .step::after {
    left: var(--bs-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-xxl.steps-sm .step::before, .steps-horizontal-xxl.steps-sm .step::after {
    top: calc(var(--bs-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-xxl .step-number {
    margin-bottom: var(--bs-steps-padding-y);
  }
  .steps-horizontal-xxl .step-body {
    padding-left: 0;
  }
  .steps-horizontal-xxl.steps-center {
    text-align: center;
  }
  .steps-horizontal-xxl.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-xxl.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-xxl.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-xxl.steps-end {
    text-align: right;
  }
  .steps-horizontal-xxl.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--bs-steps-padding-x));
  }
  .steps-horizontal-xxl.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--bs-steps-padding-x);
  }
  .steps-horizontal-xxl.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-xxl.steps-end .step-number {
    margin-left: auto;
  }
}
.steps-horizontal.steps {
  flex-direction: row;
  margin-right: calc(var(--bs-steps-padding-x) * -1);
  margin-left: calc(var(--bs-steps-padding-x) * -1);
}
.steps-horizontal .step {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 var(--bs-steps-padding-x);
}
.steps-horizontal .step::before, .steps-horizontal .step::after {
  top: calc(var(--bs-steps-number-size) * 0.5);
  height: var(--bs-steps-connect-width);
}
.steps-horizontal .step::before {
  display: none;
  left: 0;
}
.steps-horizontal .step::after {
  left: var(--bs-steps-padding-x);
  width: 100%;
}
.steps-horizontal.steps-sm .step::before, .steps-horizontal.steps-sm .step::after {
  top: calc(var(--bs-steps-number-size-sm) * 0.5);
}
.steps-horizontal .step-number {
  margin-bottom: var(--bs-steps-padding-y);
}
.steps-horizontal .step-body {
  padding-left: 0;
}
.steps-horizontal.steps-center {
  text-align: center;
}
.steps-horizontal.steps-center .step::after {
  left: 50%;
  width: 50%;
}
.steps-horizontal.steps-center .step:not(:first-child)::before {
  display: block;
  width: 50%;
}
.steps-horizontal.steps-center .step-number {
  margin-right: auto;
  margin-left: auto;
}
.steps-horizontal.steps-end {
  text-align: right;
}
.steps-horizontal.steps-end .step:not(:first-child)::before {
  display: block;
  width: calc(100% - var(--bs-steps-padding-x));
}
.steps-horizontal.steps-end .step::after {
  right: 0;
  left: auto;
  width: var(--bs-steps-padding-x);
}
.steps-horizontal.steps-end .step:last-child::after {
  display: none;
}
.steps-horizontal.steps-end .step-number {
  margin-left: auto;
}

@media (max-width: 575.98px) {
  .steps-sm .step,
  .step {
    padding: calc(var(--bs-steps-padding-y) * 0.625) 0;
  }
  .steps-sm .step::before, .steps-sm .step::after,
  .step::before,
  .step::after {
    left: calc(var(--bs-steps-number-size) * 0.625 * 0.5);
  }
  .steps-sm .step-number,
  .step-number {
    width: calc(var(--bs-steps-number-size) * 0.625);
    height: calc(var(--bs-steps-number-size) * 0.625);
    padding-top: calc((var(--bs-steps-number-size) * 0.625 - var(--bs-steps-number-inner-size) * 0.625) * 0.5);
    padding-left: calc((var(--bs-steps-number-size) * 0.625 - var(--bs-steps-number-inner-size) * 0.625) * 0.5);
    font-size: calc(var(--bs-steps-number-font-size) * 0.625);
  }
  .steps-sm .step-number .step-number-inner,
  .step-number .step-number-inner {
    width: calc(var(--bs-steps-number-inner-size) * 0.625);
    height: calc(var(--bs-steps-number-inner-size) * 0.625);
  }
  .step-body {
    padding-left: calc(var(--bs-steps-padding-x) * 0.875);
  }
}
.feature-card {
  border: 3px solid transparent;
}

.feature-card:hover {
  border: 2px solid #D9E2EF;
  text-decoration: none;
}

.avatar2 {
  display: inline-block;
  font-size: 0.625rem;
  height: 8rem;
  position: relative;
  width: 8rem;
}