//
// list-group.scss
// Extended from Bootstrap
//

.list-group-flush {

  .list-group-item {
    padding-left: 0;
    padding-right: 0;
    
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;

    &:first-child {
      padding-top: 0 !important;
      border-top: 0;
    }

    &:last-child {
      padding-bottom: 0 !important;
      border-bottom: 0;
    }
  }

  .list-group-item h6 { 
    color: $primary;
    font-weight: $font-weight-bold;
  }
}

.dropdown-link {
  display: flex;
  flex: 1;  
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  border-radius: 8px;
}

.dropdown-link:hover {
  background-color: $gray-300;
}