// 
// root.scss
// Extended from Bootstrap
// 

:root,
[data-bs-theme="light"] {
  @each $color, $value in $theme-colors-bg-subtle {
    --#{$prefix}#{$color}-bg-subtle-rgb: #{to-rgb($value)};
  }

  @each $color, $value in $grays {
    --#{$prefix}gray-#{$color}-rgb: #{to-rgb($value)};
  }
}